<template>
  <article>
    <p class="titleP">忘记密码<span class="mailTxt">（新的密码将会发送至您的邮箱，请认真填写邮箱地址）</span></p>

  <el-form
      class="block-form"
      :model="loginForm"
      ref="pswForm"
      status-icon
      :label-width="formLabelWidth"
      size="medium"
    >
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="邮箱地址：" prop="email">
            <el-input
              type="text"
              placeholder="请输入请输入您的邮箱地址"
              v-model="loginForm.email"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="24">
           <el-form-item label="身份：" prop="levelType">
        <el-select
          v-model="loginForm.type"
          placeholder="Select"
        >
          <el-option
            v-for="item in levelOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
        </el-col>
      </el-row>
        <el-form-item class="lastFormItem" style="width: 100%">
          <div>
  <el-button   type="default" style="width: 20%"  @click="goBack" >取消</el-button>
  <el-button   type="primary" style="width: 20%"  @click="sendNewPwd('loginForm')" :loading="logining">确定</el-button>
          </div>
      
      </el-form-item>
  </el-form>
  
  </article>
</template>
<script>
import {
  sendNewPwd,
} from "../service.js";

import Cookie from "js-cookie";
export default {
  data() {
    return {
      logining: false,
      loginForm: {},
      formLabelWidth:"100px",
  levelOption: [
        {
          value: "学生",
          label: "学生",
        },
        {
          value: "教师",
          label: "教师",
        },
        // { value: "学校管理员", label: "学校管理员" },
      ],
      paginationLoading: false,
      account_id: Cookie.get("studentid"),
      dataLoading: false,
      isShowDia: false,
     
      studentId: "",
    };
  },
  created() {

  },
  mounted() {},
  methods: {
   goBack(){
     this.$router.push({ name: "login" });
   },
   sendNewPwd(){
              sendNewPwd(this.loginForm).then((res) => {
                if (res.code == 0) {
                  //登录成功
                  this.$router.push({ name: "login" });
                } else {
                  this.$message.error(res.msg);
                }
              });
   }
    },
  
};
</script>
<style lang="scss" scoped>
article {
    width: 500px;
    margin-top: 50px;
    background: #fff;
    padding: 20px 10px;
    position: absolute;
    height: 300px;
    border: 1px solid #dedede;
    padding:20px;
    border-radius: 4px;
    left: 50%;
    margin-left: -250px;
  /deep/ .el-form.el-form--inline {
    background-color: #f8f8f8;
    margin-bottom: 10px;
    padding: 8px;
    overflow: hidden;
  }
  /deep/ .el-form.el-form--inline .el-form-item.el-form-item--medium {
    margin-right: 4px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  /deep/ .el-select{
    width: 100%;
  }
}
.titleP{
    font-weight: bold;
    margin-bottom: 55px;
}
.mailTxt{
 color: #F56C6C;
    font-size: 12px;
    font-weight: 500;
}
</style>