<template>
    <div style="color: #ffb414;">
        <el-tabs>
            <el-tab-pane label="Certificate">
                <el-button type="primary" @click="exportPDF('showTotal')" size="medium">Download
                    the
                    certificate</el-button>
                <div id="showTotal">
                    <div v-for="(item, index) in awards" :key="index" class="transcriptContainer2"
                        style="margin-bottom:10px;">
                        <img src="../assets/cert.png" class="model_p" />
                        <div class="name">{{ upfirstName(studentInfo.student_givenName_pinyin) + ' ' +
                            upfirstName(studentInfo.student_lastName_pinyin) }}</div>

                        <div class="project_title">
                            <p style="width: 70%;text-align: center;margin: 0 auto;">
                                {{ projectInfo.project_title }}
                            </p>
                        </div>

                        <div class="award">{{ item.award_lable }}</div>
                        <div class="project_ename">{{ projectInfo.project_ename }}</div>

                        <div class="application_id">{{ 'Application ID: ' + id }}</div>

                        <div class="cert_date">{{ formatCertDate(projectManage.entry_date[0].cert_date) }}</div>
                    </div>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import { getStudentAwardsByApplication } from '../service'

export default {
    name: 'index',
    data() {
        return {
            id: this.$route.query.id,
            awards: [],
            projectInfo: {},
            studentInfo: {},
            accountInfo: {},
            projectManage: {},
            showNoAwards: false
        }
    },
    mounted() {
        //测试使用
        // this.id = '662da67ea4640b55288b4572'
        this.fetchData()
    },
    methods: {


        formatCertDate(publish_time) {
            let publishTime = publish_time;
            var date = new Date(publishTime);
            let year = date.getFullYear();
            let day = date.getDate();
            let month = date.getMonth() + 1;
            return this.formatMonthToEng(month) + " " + day + ", " + year;
        },
        formatMonthToEng(month) {
            switch (month) {
                case 1:
                    return "January";
                case 2:
                    return "February";
                case 3:
                    return "March";
                case 4:
                    return "April";
                case 5:
                    return "May";
                case 6:
                    return "June";
                case 7:
                    return "July";
                case 8:
                    return "August";
                case 9:
                    return "September";
                case 10:
                    return "October";
                case 11:
                    return "November";
                case 12:
                    return "December";
            }
        },
        fetchData() {

            getStudentAwardsByApplication(this.id).then(res => {
                if (res.data.code === 0) {
                    this.awards = res.data.data.awards
                    this.studentInfo = res.data.data.student_info
                    this.accountInfo = res.data.data.account_info
                    this.projectInfo = res.data.data.project_info
                    this.projectManage = res.data.data.projectManage
                }
            })
        },

        upfirstName(name) {

            if (name == 'João Pedro') {

                return name
            }
            if (name == 'AGYEN-FREMPONG') {
                return name
            }

            return name.toLowerCase().replace(/\b([\w|‘]+)\b/g, function (word) {
                //return word.slice(0, 1).toUpperCase() + word.slice(1);
                return word.replace(word.charAt(0), word.charAt(0).toUpperCase());
            });

            if (name) {
                //         const transferName = name.toLowerCase()
                //   return transferName.charAt(0).toUpperCase() + transferName.slice(1)     



                if (name.indexOf(" ") != -1) {
                    var newarr, newarr1;
                    newarr = name.toLowerCase().split(" ");
                    for (var i = 0; i < newarr.length; i++) {
                        newarr[i] = newarr[i][0].toUpperCase() + newarr[i].substring(1, newarr[i].length);
                    }
                    newarr1 = newarr.join(" ");
                    return newarr1;
                } else {
                    const transferName = name.toLowerCase();
                    return transferName.charAt(0).toUpperCase() + transferName.slice(1)
                }
            } else {
                return name
            }

        },
        exportPDF(id) {

            var style = document.createElement('style');
            style.innerHTML = "@page{size:A4 portrait;margin: auto 0mm}";
            document.getElementsByTagName("body")[0].style.zoom = 1
            // if (id == 'showTotal') {
            //     style.innerHTML = "@page{size:A4 landscape;}";
            // } else {
            //     style.innerHTML = "@page{size:A4 portrait;margin: auto 0mm}";
            //     document.getElementsByTagName("body")[0].style.zoom = 1
            // }

            window.document.head.appendChild(style)

            const printData = document.getElementById(id).innerHTML


            this.PageSetup_Null();//把页眉页脚设置为空


            window.document.body.innerHTML = printData // 把 html 里的数据 复制给 body 的 html 数据 ，相当于重置了 整个页面的 内容
            window.print() // 开始打印


            //   const printData = document.getElementById(id).innerHTML

            //   window.document.body.innerHTML = printData // 把 html 里的数据 复制给 body 的 html 数据 ，相当于重置了 整个页面的 内容
            //   window.print() // 开始打印
        },
        PageSetup_Null() {
            var HKEY_Root, HKEY_Path, HKEY_Key;

            HKEY_Root = "HKEY_CURRENT_USER";

            HKEY_Path = "\\Software\\Microsoft\\Internet Explorer\\PageSetup\\";

            try {
                var Wsh = new ActiveXObject("WScript.Shell");

                HKEY_Key = "header";

                Wsh.RegWrite(HKEY_Root + HKEY_Path + HKEY_Key, "");

                HKEY_Key = "footer";

                Wsh.RegWrite(HKEY_Root + HKEY_Path + HKEY_Key, "");

            } catch (e) { }

        }
    }
}
</script>

<style lang="scss">
.transcriptContainer2 {
    position: relative;
    color: #ffb414;

    .model_p {
        width: 210mm;
        height: 295mm;
    }

    .name {
        position: absolute;
        width: 210mm;
        top: 400px;
        font-size: 42px;
        font-weight: bold;
        text-align: center;
    }

    .project_title {
        color: #333;
        position: absolute;
        width: 210mm;
        top: 510px;
        font-size: 22px;
        font-weight: bold;
        text-align: center;
    }

    .award {
        text-align: center;
        width: 210mm;
        position: absolute;
        top: 620px;
        font-size: 30px;
        font-weight: bold;
    }

    .project_ename {
        position: absolute;
        top: 715px;
        font-size: 32px;
        font-weight: bold;
        width: 210mm;
        text-align: center;
    }

    .application_id {
        color: #666;
        position: absolute;
        top: 1020px;
        left: 40px;
        font-size: 14px;
        width: 210mm;
    }

    .cert_date {
        color: white;
        position: absolute;
        top: 1040px;
        left: 600px;
        font-size: 18px;
        font-weight: bold;
        width: 210mm;
    }
}
</style>
