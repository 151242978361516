<template>
  <article> 
     
     <el-table  v-loading="loadDialog" :data="appTable" header-cell-class-name="adorn"  size="medium" border ref="leftTable"  class="left-table">
      <el-table-column label="发送人" width="120" align="center" prop="sender_name" >
      </el-table-column>
      <el-table-column label="创建时间"  width="160" align="center" prop="create_time" >
           <template slot-scope="scope">{{
            scope.row.create_time | timeFormat
          }}</template>
      </el-table-column>
      <el-table-column label="内容" align="left" prop="message_content" >
      </el-table-column>
      <el-table-column label="回复/答疑" align="left" prop="message_reply">
      </el-table-column>
    </el-table>
  </article>
</template>
<script>
import { getStudentMessagesByAccount,getAccountMessages } from "../service.js";

import Cookie from "js-cookie";
export default {
  data() {
    return {
      loadDialog: false,
      teamForm: {
        name: "测试",
      },
      formLabelWidth: "100px",
      schoolForm: {},
      appTable: [],
      paginationLoading: false,
      loginId: Cookie.get("loginId"),
       loginType: Cookie.get("loginType"),
    };
  },
  created() {
       if(this.loginType=='教师'){
 this.getStudentMessagesByAccount(); //获取学校报名表的列表
    }else if(this.loginType=='学校管理员'){
this.getAccountMessages();
    }
   
  },
  mounted() {},
  methods: {
    getStudentMessagesByAccount() {
      this.dataLoading = true;
      getStudentMessagesByAccount({contact_id: Cookie.get("loginId")}).then((res) => {
        this.dataLoading = false;
        if (res.code == 0) {
           this.appTable = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getAccountMessages() {
      this.dataLoading = true;
      getAccountMessages({account_id: Cookie.get("loginId")}).then((res) => {
        this.dataLoading = false;
        if (res.code == 0) {
           this.appTable = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    editItem(row) {
      this.$router.push({ path: "/applicationDetail", query: { 'project_code': row.project_code } });
    },


  

  },
};
</script>
<style lang="scss" scoped>
article {
  margin-top: 50px;
  background: #fff;
  padding: 10px;
  /deep/ .el-form.el-form--inline {
    background-color: #f8f8f8;
    margin-bottom: 10px;
    padding: 8px;
    overflow: hidden;
  }
  /deep/ .el-form.el-form--inline .el-form-item.el-form-item--medium {
    margin-right: 4px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
.formP {
  margin: 0;
  padding: 0;

  padding-left: 10px;
  // border:1px solid #e9eef3;
  background: #f8f9fa;
  border-radius: 4px;
  width: 70%;
  position: relative;
}
</style>