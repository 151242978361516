import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'normalize.css'
import store from './store/index'
import router from './routers'
// import store from './store'
import VueI18n from 'vue-i18n' 
import JsonExcel from 'vue-json-excel';

import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

Vue.use(VueQuillEditor)

Vue.config.productionTip = false
Vue.use(VueI18n) // 通过插件的形式挂载
Vue.use(ElementUI)
const i18n = new VueI18n({
  locale: 'en-US',    // 语言标识
  //this.$i18n.locale // 通过切换locale的值来实现语言切换
  messages: {
    'zh-CN': require('./common/lang/zh'),   // 中文语言包
    'en-US': require('./common/lang/en')    // 英文语言包
  }
})
Vue.component('downloadExcel', JsonExcel)
function add0(m){return m<10?'0'+m:m }
Vue.filter('timeFormat', function(timestamp) {//
  var time = new Date(timestamp*1000);
  var y = time.getFullYear();
  var m = time.getMonth()+1;
  var d = time.getDate();
  var h = time.getHours();
  var mm = time.getMinutes();
  var s = time.getSeconds();
  return y+'-'+add0(m)+'-'+add0(d)+' '+add0(h)+':'+add0(mm)+':'+add0(s);
 })

new Vue({
  render: h => h(App),
  router,
  store,
  i18n,
}).$mount('#app')
