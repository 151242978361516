<template>
  <div style="text-align: left; font-size: 16px;font-family: Microsoft YaHei;" class="quizHome" v-if="quizs.length && !isDone">
    <NavigatorQuizs :nowIndx="question.now_index" :quizInfo="quizs" :onChangeIndx="onChangeIndex" />
    <span v-html="question.question_title"></span>
    <div>
      <img v-for="item in question.quiz_pics" :key="item._id.$id" :src="'//'+item.picture_address" alt="">
    </div>
    <div>
      <el-radio-group v-model="answer"  :disabled="isOneDone" text-color="#ffffff">
          <el-radio  v-for="(i, index) in question.question_options" :key="i" class="chooseHome" :label="index" >{{chooseKey[index]}}. {{i}}</el-radio>
      </el-radio-group>
    </div>
    <div v-if="question.question_test_point && isOneDone ">
      <div>
        知识点：{{question.question_test_point}}
      </div>
      <div>
        解析：{{question.question_solution}}
      </div>
    </div>
    <span class="answerHome" v-if="isOneDone">正确答案: {{chooseKey[+question.question_answer - 1]}}</span>

    <div style="margin-top:34px;display:flex">
        <div type="primary" class="deleteBtn" @click="oneDone" v-if="!isOneDone">确认</div>
    <div type="primary" class="reviewBtn" @click="clickC(question.now_index)" :disabled="isOneDone&&question.now_index === quizs.length" v-show="question.now_index != quizs.length">{{question.now_index === quizs.length ? '完成' : '下一题'}}</div>
    </div>
    
  </div>
</template>

<script>
import { uploadQuiz } from '../service.js'
import NavigatorQuizs from './NavigatorQuizs'

export default {
  name: 'Quiz',
  props: {
    studentId: String,
    quizs: Array,
    onDone: Function,
    onCI: Function
  },
  components: {
    NavigatorQuizs
  },
  mounted(){
    console.log(this.isDone,this.quizs)
  },
  watch: {
    quizs: function () {
      
      this.isOneDone = this.quizs[0].quiz_answer!==undefined,
      this.answer = this.quizs[0].quiz_answer 
      this.question = {
        ...this.quizs[0],
        now_index: 1,
      }
    },
  },
  data() {
    return {
      chooseKey: ['A', 'B', 'C', 'D', 'E', 'F'],
      isDone: false,
      isOneDone: false,
      answer: null,
      question: {
        now_index: 1,
        question_title: 'ceshi',
        question_options: ['1','2','3','4'],
        question_answer: '1',
        _id: {
          $id: '5b08289209f7789c538b45d4'
        }
      }
    }
  },
  methods: {
    goNext() {
      const old_index = this.question.now_index
      this.question = {
        ...this.quizs[old_index],
        now_index: old_index + 1,
      }
      this.isOneDone = this.quizs[old_index].quiz_answer!==undefined,
      this.answer = this.quizs[old_index].quiz_answer 
      // this.$forceUpdate()
    },
    done() {
      this.isDone = true
      this.onDone()
      this.isDone = false
      this.isOneDone = false,
      this.answer = null
    },
    clickC(now_index) {
      now_index === this.quizs.length ? this.done() : this.goNext()
    },
    oneDone() {
        if(this.answer===null){
            this.$message.error('请选择答案!')
            return
        }
      this.isOneDone = true
      this.quizs[this.question.now_index-1].quiz_answer=this.answer
      uploadQuiz({
        quiz_id: this.question._id.$id,
        student_id: this.studentId,
        quiz_answer: this.answer,
        class_id: this.quizs[this.question.now_index-1].class_id
      }).then(res=>{
        this.$emit('changeStatus',this.question.now_index)
        if(res.correct_rate!==undefined){
          this.$message(`您已完成本次测试，正确率为 ${parseInt(res.correct_rate*100)}% !`)
        }
      })
    },
    onChangeIndex(index){
      //  const old_index = this.question.now_index
      this.onCI(index)
      this.question = {
        ...this.quizs[index],
        now_index: index+1,
      }
      this.isOneDone = this.quizs[index].quiz_answer!==undefined,

      // this.isOneDone = !!this.quizs[index].quiz_answer,
      console.log('your ansert:',this.quizs[index].quiz_answer )
      this.answer = this.quizs[index].quiz_answer 
    }
    

  },
  computed:{
    name(){
      return this.$store.state.user_info.student_lastName + this.$store.state.user_info.student_givenName
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.quizHome{
  padding: 20px;
  .chooseHome{
    margin-top:30px;
    display: block;
    font-size: 16px;
    }
    .answerHome{
        margin-top: 30px;
        margin-right: 20px;
        display: inline-block;
        font-family: Microsoft YaHei;
    }
    .question-button{
    right: 20px;
    /* position: absolute; */
    margin-top: 20px;
    }
    .el-radio+.el-radio {
        margin-left: 0;
    }

    .el-radio__input.is-checked+.el-radio__label{
        color: rgb(160,14,21);
    }
    .el-radio__input.is-disabled+span.el-radio__label{
        color: #C0C4CC!important;
    }
    .el-radio__input.is-checked .el-radio__inner {
        color: rgb(160,14,21);
        background-color: rgb(160,14,21);
        border-color: rgb(160,14,21);
    }
}

</style>
