<template>
  <article>
      <p class="schoolP">
      <span>{{$t('m.personInfo.schoolName')}}</span><span>{{ basicForm.account_name }}</span>
      </p>
      <!-- <p class="noticeP">
      {{$t('m.personInfo.tips4')}}
      </p> -->
      <el-tabs
      class="elTabs"
      type="card"
      v-model="activeName"
      @tab-click="handleClick"
      >
      <el-tab-pane :label="$t('m.personInfo.personalInformation')" name="first">
          <el-form v-loading="formLoading"
          class="block-form"
          :model="basicForm"
          ref="basicForm"
          status-icon
          :label-width="formLabelWidth"
          size="medium"
          >
          <el-row :gutter="10">
              <p class="partP">{{$t('m.personInfo.base')}}</p>
          </el-row>
          <el-row :gutter="10">
              <el-col :span="12">
              <el-form-item :label="$t('m.personInfo.xing')" prop="student_lastName">
              <el-input type="text" :placeholder="$t('m.personInfo.enterPl')" v-model="basicForm.student_lastName"></el-input>
              </el-form-item>
              </el-col>
              <el-col :span="12">
              <el-form-item :label="$t('m.personInfo.ming')" prop="student_givenName">
                  <el-input type="text" :placeholder="$t('m.personInfo.enterPl')" v-model="basicForm.student_givenName"></el-input>
              </el-form-item>
              </el-col>
              <!-- <el-col :span="12">
              <el-form-item :label="$t('m.personInfo.xingpinyin')" prop="student_lastName_pinyin">
                  <el-input type="text" :placeholder="$t('m.personInfo.enterPl')" v-model="basicForm.student_lastName_pinyin"></el-input>
              
              </el-form-item>
              </el-col>
              <el-col :span="12">
              <el-form-item :label="$t('m.personInfo.mingpinyin')" prop="student_givenName_pinyin">
                  <el-input type="text" :placeholder="$t('m.personInfo.enterPl')" v-model="basicForm.student_givenName_pinyin"></el-input>
              </el-form-item>
              </el-col> -->
              <el-col :span="12">
              <el-form-item :label="$t('m.personInfo.sex')" prop="gender">
                  <el-radio-group v-model="basicForm.student_gender">
                  <el-radio label="女">{{$t('m.personInfo.girl')}}</el-radio>
                  <el-radio label="男">{{$t('m.personInfo.boy')}}</el-radio>
                  </el-radio-group>
              </el-form-item>
              </el-col>
              <el-col :span="12">
              <el-form-item :label="$t('m.personInfo.grade')" prop="student_graduation">
                  <em class="noticeTxt">{{$t('m.personInfo.tips1')}}</em>
                  <el-select
                      v-model="basicForm.student_graduation"
                      :placeholder="$t('m.pleaseChoose')"
                      >
                      <el-option
                          v-for="item in graduationOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                      >
                      </el-option>
                  </el-select>
              </el-form-item>
              </el-col>
          </el-row>

          <!-- <el-row :gutter="10">
              <p class="partP">活动进度及评价</p>
          </el-row>
          <el-row :gutter="10">
              <div style="width:96%;margin:0 auto">
                  <el-progress :percentage="rate" :text-inside="true" :stroke-width="24" :format="fomateData"></el-progress>
              </div>
              <div style="width:96%;margin:0 auto;display:flex;justify-content:flex-end;align-items:center;margin-top:10px;">
                  你当前的评价<span style="padding:6px 14px;background:#409EFF;color:white;border-radius:6px;margin-left:10px;">{{achievement}}</span>
              </div>
          </el-row> -->


          <el-row :gutter="10">
              <p class="partP">{{$t('m.personInfo.contactInformation')}}</p>
          </el-row>
          <el-row :gutter="10">
              <el-col :span="12">
              <el-form-item :label="$t('m.personInfo.tel')" prop="student_mobile">
                  <el-input type="text" :placeholder="$t('m.personInfo.enterPl')" v-model="basicForm.student_mobile"></el-input>
              </el-form-item>
              </el-col>
              <el-col :span="12">
              <el-form-item :label="$t('m.personInfo.email')" prop="student_email">
                  <el-input type="text" :placeholder="$t('m.personInfo.enterPl')" v-model="basicForm.student_email"></el-input>
              </el-form-item>
              </el-col>
              <!-- <el-col :span="12">
              <el-form-item label="QQ：" prop="student_qqnumber">
                  <el-input type="text" :placeholder="$t('m.personInfo.enterPl')" v-model="basicForm.student_qqnumber"></el-input>
              </el-form-item>
              </el-col>
              <el-col :span="12">
              <el-form-item :label="$t('m.personInfo.weChat')" prop="student_wechat">
                  <el-input type="text" :placeholder="$t('m.personInfo.enterPl')" v-model="basicForm.student_wechat"></el-input>
              </el-form-item>
              </el-col>
              <el-col :span="12">
              <el-form-item :label="$t('m.personInfo.zhengjianleixing')" prop="student_identity_id">
                 
                  <el-input type="text" :placeholder="$t('m.personInfo.enterPl')" v-model="basicForm.student_identity_type"></el-input>
              </el-form-item>
              </el-col>
              <el-col :span="12">
              <el-form-item :label="$t('m.personInfo.zhengjianhaoma')" prop="student_identity_id">
                  <el-input type="text" :placeholder="$t('m.personInfo.enterPl')" v-model="basicForm.student_identity_id"></el-input>
              </el-form-item>
              </el-col>
              <el-col :span="12">
              <el-form-item :label="$t('m.personInfo.birthDate')" prop="student_birth_date">
                  <em class="noticeTxt2">{{$t('m.personInfo.tips3')}}</em>
                  <el-input type="text" :placeholder="$t('m.personInfo.enterPl')" v-model="basicForm.student_birth_date"></el-input>
              </el-form-item>
              </el-col> -->
          </el-row>
          <el-row :gutter="10">
              <p class="partP">{{$t('m.personInfo.parentContactInformation')}}</p>
          </el-row>
          <el-row :gutter="10">
              <el-col :span="12">
              <el-form-item
              :label="$t('m.personInfo.firstGuardianTel')"
                  prop="student_guardian_first_mobile"
              >
                  <el-input type="text" :placeholder="$t('m.personInfo.enterPl')" v-model="basicForm.student_guardian_first_mobile"></el-input>
              </el-form-item>
              </el-col>
              <el-col :span="12">
              <el-form-item
                  :label="$t('m.personInfo.firstGuardianEmail')"
                  prop="student_guardian_first_mobile">
                  <el-input type="text" :placeholder="$t('m.personInfo.enterPl')" v-model="basicForm.student_guardian_first_email"></el-input>
              </el-form-item>
              </el-col>
              <el-col :span="12">
              <el-form-item :label="$t('m.personInfo.secondGuardianTel')" prop="student_guardian_second_mobile">
                  <el-input type="text" :placeholder="$t('m.personInfo.enterPl')" v-model="basicForm.student_guardian_second_mobile"></el-input>
              </el-form-item>
              </el-col>
              <el-col :span="12">
                  <el-form-item
                      :label="$t('m.personInfo.secondGuardianEmail')"
                      prop="student_guardian_second_email"
                  >
                      <el-input type="text" :placeholder="$t('m.personInfo.enterPl')" v-model="basicForm.student_guardian_second_email"></el-input>
                  </el-form-item>
              </el-col>
          </el-row>
          <el-row :gutter="10">
              <el-col :span="24">
                  <el-form-item :label="$t('m.personInfo.supplementaryNotes')" prop="student_supplement">
                      <el-input rows="4" type="textarea" :placeholder="$t('m.personInfo.enterPl')" v-model="basicForm.student_supplement"></el-input>
                  </el-form-item>
              </el-col>
          </el-row>
          <el-row :gutter="10">
              <el-col :span="24">
                  <div class="centerDiv">
                      <el-button type="primary" class="btn-link" style="width: 100%;margin-bottom:30px"  @click="changeInfo('basicForm')" :loading="formLoading">{{$t('m.personInfo.submit')}}</el-button>
                  </div>    
              </el-col>
          </el-row>
          </el-form>
      </el-tab-pane>
      <!-- <el-tab-pane label="电子证书">
          <div style="padding-left:30px;padding-right:30px">
              <el-table :data="certificates">
                  <el-table-column label="活动日期" prop="project_info.project_name"></el-table-column>
                  <el-table-column label="证书状态">
                      <template slot-scope="scope">
                          {{scope.row.certificate_status?'已发布':'未发布'}}
                      </template>
                  </el-table-column>
                  <el-table-column label="查看证书">
                      <template slot-scope="scope">
                          <el-button v-if="scope.row.certificate_status" @click="viewCertificate(scope.row)" type="primary" size="medium">查看</el-button>
                      </template>
                  </el-table-column>
              </el-table>
          </div>
      </el-tab-pane> -->
  
      </el-tabs>
      <el-dialog
          class="dialog"
          :title="dialogInfo.title"
          :visible.sync="dialogInfo.show"
          :close-on-click-modal="false"
          :show-close="false"
          width="60%"
          @open="openDialog"
          @close="closeDialog"
          >
          <el-form
              class="block-form"
              :model="dialogForm"
              ref="dialogForm"
              :rules="rules"
              status-icon
              label-width="180px"
              size="medium"
          >
              <el-row :gutter="10">
                  <el-col :span="24">
                      <el-form-item :label="$t('m.personInfo.registrationProject')" prop="project_name">
                      <span class="project_name">{{ project_name }}</span>
                      </el-form-item>
                  </el-col>
                  <el-col :span="12">
                      <el-form-item :label="$t('m.personInfo.competitionSubject')" prop="application_events">
                          <el-select
                              multiple
                              v-model="dialogForm.application_events"
                              :placeholder="$t('m.pleaseChoose')"
                          >
                              <el-option
                              v-for="item in project_events"
                              :key="item"
                              :label="item"
                              :value="item"
                              >
                              </el-option>
                          </el-select>
                      </el-form-item>
                  </el-col>
                  <el-col :span="12">
                      <el-form-item
                      :label="$t('m.personInfo.competitionSubjectExtra')"
                      prop="application_extra_events"
                      >
                          <el-select
                              multiple
                              v-model="dialogForm.application_extra_events"
                              :placeholder="$t('m.pleaseChoose')"
                          >
                              <el-option
                                  v-for="item in project_extra_events"
                                  :key="item"
                                  :label="item"
                                  :value="item"
                                  >
                              </el-option>
                          </el-select>
                      </el-form-item>
                  </el-col>
              </el-row>
          </el-form>
          <div slot="footer" class="dialog-footer">
              <el-button @click="closeDialog">取 消</el-button>
              <el-button
                  :loading="loadDialog"
                  element-loading-spinner="el-icon-loading"
                  element-loading-background="rgba(255, 255, 255, 0.2)"
                  type="primary"
                  @click="submitTeam('dialogForm')"
                  >报名</el-button>
          </div>
      </el-dialog>
      <el-dialog :visible.sync="showCertificate" width="546px">
          <div id="certificate_display">
              <img :src="require('../assets/certificate.png')" oncontextmenu="return false" onselectstart="return false" ondragstart="return false">
              <div class="certificate-title">Certificate of Appreciation</div>
              <div class="certificate-content">
                  <div style="margin-top:20px">This is to certify that</div>
                  <div style="margin-top:10px;font-size:16px;font-weight:bold">{{certificate.student_info.student_givenName_pinyin+' '+certificate.student_info.student_lastName_pinyin}}</div>
                  <div style="margin-top:4px;font-size:16px;font-weight:bold">{{certificate.account_info.account_nameEN}}</div>
                  <div style="margin-top:18px">{{'has worked as a volunteer for '+ certificate.certificate_work_time +' hours on'}}</div>
                  <div style="margin-top:14px">{{certificate.project_info.certificate_date}}</div>
                  <div style="margin-top:14px">at</div>
                  <div style="margin-top:14px">{{certificate.project_info.certificate_activity}}</div>
                  <div style="margin-top:20px">We appreciate your involvement in and contribution to environmental science and  protection. We hope you can continue to participate in scientific and environmental  protection work and become a conservation leader in the future.</div>
                  <img :src="require('../assets/icon.png')" style="width:140px;margin-top:30px" oncontextmenu="return false" onselectstart="return false" ondragstart="return false">
                  <div style="margin-top:-20px">Ashley Wong</div>
                  <div>Adviser, Ecoplore</div>
                  <!-- <img :src="require('../assets/icon2.png')" style="width:140px" oncontextmenu="return false" onselectstart="return false" ondragstart="return false">
                  <div style="margin-top:-20px">Matthew Cheung</div>
                  <div>Development Director Envirothon China</div> -->
              </div>
          </div>
          <el-button style="margin-top:-20px" @click="printCert()">Print Certificate</el-button>
      </el-dialog>
  </article>
</template>
<script>
import {
getStudentInfo,
changeEvents,
updateApplicationEvents,
getEventProjects,
updateStudentInDatabase,
getMyFinishedHours,
getCertificatesInfo
} from "../service.js";

import Cookie from "js-cookie";
export default {
data() {
  return {
      formLoading:false,
    signUpTable: [],
    submitTypeOptions: [
      {
        label: "学校",
        value: "0",
      },
    ],
    formLabelWidth: "180px",
    loadDialog: false,
    dataLoading:false,
    activeName: "first",
    basicForm: {
      student_mobile: "",
      student_email: "",
      student_qqnumber: "",
      student_wechat: "",
      student_identity_id: "",
      student_birth_date: "",
      student_guardian_first_mobile: "",
      student_guardian_first_mobile: "",
      student_guardian_second_mobile: "",
      student_guardian_second_email: "",
      student_supplement: "",
    },
    graduationOptions: [
      {
        label: "2030",
        value: "2030",
      },
      {
        label: "2029",
        value: "2029",
      },
      {
        label: "2028",
        value: "2028",
      },
      {
        label: "2027",
        value: "2027",
      },
      {
        label: "2026",
        value: "2026",
      },
      {
        label: "2025",
        value: "2025",
      },
      {
        label: "2024",
        value: "2024",
      },
      {
        label: "2023",
        value: "2023",
      },
      {
        label: "2022",
        value: "2022",
      },
      {
        label: "2021",
        value: "2021",
      },
      {
        label: "2020",
        value: "2020",
      },
      {
        label: "2019",
        value: "2019",
      },
      {
        label: "2018",
        value: "2018",
      },
    ],
    dialogForm: {},
    teachTable: [],
    paginationLoading: false,
    dataLoading: false,
    rules: {
      contact_lastName: [
        { required: true, message: "姓不能为空", trigger: "change" },
      ],
      contact_givenName: [
        { required: true, message: "名不能为空", trigger: "change" },
      ],
      contact_enName: [
        { required: true, message: "英文名不能为空", trigger: "change" },
      ],
      contact_department: [
        { required: true, message: "部门不能为空", trigger: "change" },
      ],
      contact_position: [
        { required: true, message: "职位不能为空", trigger: "change" },
      ],
      contact_prefix: [
        { required: true, message: "请选择性别", trigger: "change" },
      ],
      contact_email: [
        { required: true, message: "邮箱不能为空", trigger: "change" },
      ],
      contact_discipline: [
        { required: true, message: "学科不能为空", trigger: "change" },
      ],
      contact_work_status: [
        { required: true, message: "请选择是否在职", trigger: "change" },
      ],
    },
    isShowDia: false,
    dialogInfo: {
      title: "新增教师信息",
      show: false,
      isNew: true,
    },
    teacherId: "",
    project_events: [],
    project_name: "",
    project_extra_events: [],
    finishHours:0,
    rate:0,
    achievement:'',
    certificates:[],
    certificate:{student_info:{},account_info:{},project_info:{}},
    showCertificate:false
  };
},
created() {
  this.getStudentInfo(); //获取学生信息
  // this.getMyFinishedHours()
  // this.getCertificatesInfo()
},
mounted() {},
methods: {
      printCert(){
          // this.showPrint = true
          //延迟处理，这样可以把不可见的内容显示出来
          this.onbeforePrint();
          setTimeout( () => {
              this.startPrint()
          },1000)
      },
      startPrint(){
          let subOutputRankPrint = document.getElementById('certificate_display');
          console.log(subOutputRankPrint.innerHTML);
          let newContent =subOutputRankPrint.innerHTML;
          let oldContent = document.body.innerHTML;
          document.body.innerHTML = newContent;
          window.print();
          window.location.reload();
          document.body.innerHTML = oldContent;
      },
    onbeforePrint(){   //先获取div的高度，当高度小于800px时，需要设置距离顶部的值，大于800则已经铺满整个A4纸，不用设置
      var style = document.createElement('style');
      var mapHeight = 714; 
      var marginTop = "0mm"; 
      if(mapHeight < 800){       
          var temp = 800 - parseInt(mapHeight);   
          marginTop = (temp/8).toFixed(2)+"mm";            
      }         
      style.innerHTML = "@page{size: portrait;margin: 50mm 50mm 50mm 50mm;}";     
      window.document.head.appendChild(style); 
      // 1240×1754  210mm*297mm 
  },
    getCertificatesInfo(){
        getCertificatesInfo(this.$route.query.student_id).then(res=>{
            if(res.data.code===0){
                this.certificates = res.data.data
            }
        })
    },
    getMyFinishedHours(){
        getMyFinishedHours(this.$route.query.student_id).then(res=>{
            if(res.data.code===0){
                this.finishHours = res.data.data.hour
                this.rate = (res.data.data.hour/res.data.data.total_hour)*100
                this.achievement = res.data.data.achievement_name
            }
        })
    },
    fomateData(){
        return this.finishHours+'小时'
    },
    changeInfo(){
        this.formLoading=true;
        this.basicForm._id =this.$route.query.student_id ;
        console.log( this.basicForm)
      
    updateStudentInDatabase(this.basicForm).then((res) => {
              this.formLoading=false;
              if (res.code == 0) {
               this.$message.success(this.$t('m.xiugaichenggong'))
               
              } else {
                this.$message.error(res.msg);
              }
            });
    },
  getStudentInfo() {
    this.formLoading=true;
    let param = {
      student_id: this.$route.query.student_id,
    };
    getStudentInfo(param).then((res) => {
      this.formLoading=false;
      if (res.code == 0) {
        //登录成功
        this.basicForm =Object.assign({}, this.basicForm,res.data.student_info) ;
        this.signUpTable = res.data.app_info;
      } else {
        this.$message.error(res.msg);
      }
    });
  },
  editItem(row) {
    this.dialogForm.id = row._id.$id;
    getEventProjects({ id: row._id.$id }).then((res) => {
      if (res.code == 0) {
        console.log(res.data);
        this.project_events = res.data[0].project_events;
        this.project_name = res.data[0].project_name;
        this.project_extra_events = res.data[0].project_extra_events;
      } else {
        this.$message.error(res.msg);
      }
    });
    this.dialogInfo.title = this.$t('m.personInfo.modifySubproject');
    this.dialogInfo.show = true;
  },
  closeDialog() {
    //关闭弹框
    this.dialogInfo.show = false;
     this.getStudentInfo(); //获取学生信息
    this.$nextTick(() => {
      if (this.$refs.dialogForm) {
        this.$refs.dialogForm.clearValidate();
      }
    });
    // this.getAccountStudents();
  },
  handleClick() {},

  openDialog() {
    //打开弹框
    this.$nextTick(() => {
      if (this.$refs.dialogForm) {
        this.$refs.dialogForm.clearValidate();
      }
    });
  },
  submitTeam(dialogForm) {
    let _this = this;
    this.$refs[dialogForm].validate((valid) => {
      if (valid) {
        this.loadDialog = true;
        changeEvents(_this.dialogForm).then((res) => {
          this.loadDialog = false;
          if (res.code == 0) {
            this.$message.success(this.$t('m.xiugaichenggong'));
            this.closeDialog();
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    });
  },
  viewCertificate(row){
      this.showCertificate = true
      this.certificate = row
  }
},
};
</script>
<style lang="scss" scoped>
article {
margin-top: 50px;
background: #fff;
padding: 0 10px;
.noticeP {
  margin: 0;
  padding: 0;
  height: 30px;
  line-height: 30px;
  color: #f56c6c;
  font-size: 12px;
  text-align: center;
}
.schoolP {
  margin: 0;
  padding: 0;
  height: 40px;
  line-height: 40px;
  margin: 0 auto;
  width: 500px;
  font-weight: bold;
}
.elTabs {
  margin-top: 10px;
  // border-top:1px solid #e9eef3 ;
}
}
.formP {
margin: 0;
padding: 0;

padding-left: 10px;
// border:1px solid #e9eef3;
background: #f8f9fa;
border-radius: 4px;
width: 70%;
position: relative;
height: 36px;
line-height: 36px;
}
.formP2 {
margin: 0;
padding: 0;

padding-left: 10px;
// border:1px solid #e9eef3;
background: #f8f9fa;
border-radius: 4px;
width: 88%;
position: relative;
min-height: 36px;
}
.partP {
margin: 0;
padding: 0;
height: 40px;
line-height: 40px;
left: 21px;
font-size: 16px;
padding-left: 10px;
position: relative;
margin-bottom: 20px;
&:before {
  content: "";
  display: block;
  position: absolute;
  background: #409eff;
  height: 30px;
  width: 3px;
  left: -6px;
  top: 5px;
}
}
.noticeTxt {
color: #f56c6c;
position: absolute;
font-size: 8px;
left: -56px;
bottom: -25px;
}
.noticeTxt1 {
color: #f56c6c;
position: absolute;
font-size: 8px;
left: -140px;
bottom: -15px;
line-height: 10px;
}
.noticeTxt2 {
color: #f56c6c;
position: absolute;
font-size: 8px;
left: -148px;
bottom: -15px;
line-height: 10px;
}



.btn-link{
    background:#E0A91E;
  }
  /deep/ .el-button {
    border:none;
  }
.project_name {
font-weight: bold;
}
.centerDiv{
  width: 150px;
  margin:0 auto;
  text-align: center;
}
.certificate-title{
  position: absolute;
  top:220px;
  left:90px;
  font-size: 26px;
  font-family: 'Constantia';
  color: black;

}
.certificate-content{
  position: absolute;
  top:250px;
  left:90px;
  font-family: 'Constantia';
  color: black;
  width: 380px;
  word-break: keep-all;
  font-size: 12px;

}
.icon1{

}
</style>