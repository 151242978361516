import mutations from './mutations'
import actions from './actions'
import getters from './getters'
let save = JSON.parse(window.sessionStorage.getItem('savingData'));
const state={
    userInfo:save && save.userInfo || '未登录',
    menuRouterInfo:save && save.menuRouterInfo || [],
    languageType:save && save.languageType || [],
}
export default{
    state,
    mutations,
    actions,
    getters
}