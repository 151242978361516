<template>
  <article>
    <div class="homeContainer">
      <div class="leftSide">
        <el-menu style="background-color: #dce3f1;" :router="true" :default-active="defevent">
          <el-menu-item v-for="(track, index) in dataList" :key="index" @click="showTrackInfo(track)" :index="index"
            style="text-align: left">{{ track.track_name }}</el-menu-item>
        </el-menu>
      </div>

      <div class="right" v-if="selectTrack.showData == true">
        <el-tabs v-model="activeName">
          <el-tab-pane label="Student List" name="first">
            <div style="float: left;">
              <el-button type="primary" style="background-color:#ffb414;border: none;color: #333 ;"
                @click="addStudent()">Add a Student</el-button>
              <el-button type="primary" style="background-color:#ffb414;border: none;color: #333 ;"
                @click="showLink()">Registration Link</el-button>
            </div>
            <div style="clear: both;"></div>

            <el-table class="tabStyle" height="70vh" :data="selectTrack.students"
              header-cell-style="color:#333;background-color: #ffb414;" size="medium" border ref="leftTable"
              style="margin-top: 20px;" empty-text="No Data">
              <el-table-column label="Project" align="center">
                <template slot-scope="scope">
                  {{ scope.row.application_project }}
                </template>
              </el-table-column>

              <el-table-column label="Name" align="center">
                <template slot-scope="scope">
                  {{ scope.row.student_info.student_givenName_pinyin + ' ' +
                    scope.row.student_info.student_lastName_pinyin }}
                </template>
              </el-table-column>
              <el-table-column label="Email" align="center">
                <template slot-scope="scope">
                  {{ scope.row.student_info.student_email }}
                </template>
              </el-table-column>
              <el-table-column label="Graduation Year" width="100px" align="center">
                <template slot-scope="scope">
                  {{ scope.row.student_info.student_graduation }}
                </template>
              </el-table-column>
              <el-table-column label="Registration Status" align="center">
                <template slot-scope="scope">
                  {{ scope.row.application_status == '待缴费' ? 'Pending Payment' : 'Paid' }}
                </template>
              </el-table-column>
              <el-table-column label="Team" width="100px" align="center">
                <template slot-scope="scope">
                  <el-select :disabled="scope.row.application_status == '待缴费'" @change="changeTeamCode(scope.row)"
                    v-model="scope.row.application_team_code" placeholder="Select">
                    <el-option v-for="i in 20" :key="i" :value="i" :label="i"></el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column label="Role" width="100px" align="center">
                <template slot-scope="scope">
                  {{ scope.row.application_team_role ? (scope.row.application_team_role == '队员' ? 'Member' : 'Leader') :
                    '-' }}
                </template>
              </el-table-column>
              <el-table-column label="Operation" width="200px" align="center">
                <template slot-scope="scope">
                  <div style="width: 200px;float: left;">
                    <el-button v-if="scope.row.application_status == '待缴费'" class="btn-style"
                      @click="RemoveItem(scope.row)" type="primary" size="mini">Remove</el-button>
                    <el-button
                      v-if="scope.row.application_status !== '待缴费' && (scope.row.application_team_role == '' || scope.row.application_team_role == '队员')"
                      class="btn-style" @click="setLeader(scope.row)" type="primary" size="mini">
                      Set as Leader
                    </el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="Project List" name="second">
            <div style="float: left;">

              <el-button type="primary" :class="selectSortType == 'team' ? 'sortSelect' : 'sortNoSelect'"
                @click="sortTeam()">Sort
                by Team Number</el-button>
              <el-button type="primary" :class="selectSortType == 'update' ? 'sortSelect' : 'sortNoSelect'"
                @click="sortUpdate()">Sort by Latest Update</el-button>
            </div>

            <div style="clear: both;"></div>


            <div class="projectList">


              <div class="itemStyle" v-for="(item, index) in selectTrack.projects" :key="index">

                <div class="teamCodeStyle">
                  #{{ item.application_team_code }}
                </div>

                <div class="projectTitleStyle">
                  {{ item.event }}
                </div>

                <div class="leaderStyle">
                  Leader: {{ item.leaderName }}
                </div>

                <div class="memberStyle">
                  Members: {{ item.memberName }}
                </div>


                <div class="infoStyle">
                  Progress: {{ item.progress }} Word Count: {{ item.word_count }}
                </div>
                <el-button type="primary" class="manageStyle" @click="manageProject(item)">Manage</el-button>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>

    <el-dialog class="dialog" title="Student Registration Link" :visible.sync="showLinkDialog" width="70%">

      <div>
        Please copy and share the link with your student for registration
      </div>

      <div style="width: 100%;height: 100%;">

        <div class="eventLinkStyle" v-for="(item, index) in registration_links" :key="index">


          <el-row :gutter="10">
            <el-col :span="6">
              <div class="eventName">
                {{ item.event }}
              </div>
            </el-col>
            <el-col :span="15">
              <div class="linkStyle">
                {{ item.link }}
              </div>
            </el-col>
            <el-col :span="3">
              <!-- <div class="shareBtn">
                Copy
              </div> -->

              <p class="shareBtn" @click="doShare" :data-clipboard-text="item.link">
                Copy
              </p>


            </el-col>
          </el-row>

          <!-- <div class="eventName">
          </div>

          <div class="">
            
          </div> -->
        </div>
      </div>



    </el-dialog>



    <el-dialog class="dialog" :title="dialogInfo.title" :visible.sync="dialogInfo.show" :close-on-click-modal="false"
      :show-close="false" width="80%" @open="openDialog" @close="closeDialog">
      <el-form class="block-form" :model="dialogForm" ref="dialogForm" :rules="rules" status-icon v-loading="loadDialog"
        :label-width="formLabelWidth" size="medium">
        <el-row :gutter="10">

          <el-col :span="12">
            <el-form-item label="First Name in English" prop="givenName_pinyin">
              <el-input placeholder="First Name in English" type="text"
                v-model="dialogForm.givenName_pinyin"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="Last Name in English" prop="lastName_pinyin">
              <el-input placeholder="Last Name in English" type="text" v-model="dialogForm.lastName_pinyin"></el-input>
            </el-form-item>
          </el-col>

        </el-row>

        <el-row :gutter="10">

          <el-col :span="12">
            <el-form-item label="Email Address" prop="email">
              <el-input :placeholder="$t('m.studentInfo.studentEmailPlaceholder')" type="text"
                v-model="dialogForm.email"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Phone Number" prop="mobile">
              <el-input :placeholder="$t('m.studentInfo.phoneNumberPlaceholder')" type="text"
                v-model="dialogForm.mobile"></el-input>
            </el-form-item>
          </el-col>

        </el-row>

        <el-row :gutter="10">

          <el-col :span="12">
            <el-form-item label="Gender" prop="gender">
              <el-select class="w140" v-model="dialogForm.gender" placeholder="Please select gender" clearable>
                <el-option v-for="item in genderption" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="Year of High School Graduation" prop="graduation">
              <el-select class="w140" v-model="dialogForm.graduation"
                :placeholder="$t('m.studentInfo.yohsgPlaceholder')" clearable>
                <el-option v-for="item in graduationOption" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

        </el-row>

        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="Project" prop="project_code">
              <el-select class="w140" v-model="dialogForm.project_code" :placeholder="$t('m.pleaseChoose')" clearable>
                <el-option v-for="(project, index) in currentProjects" :key="index" :label="project.project_name"
                  :value="project.project_code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">{{ $t('m.cancel') }}</el-button>
        <el-button :loading="loadDialog" element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(255, 255, 255, 0.2)" type="primary"
          @click="confirmAddNewApplication('dialogForm')">
          {{ $t('m.confirm') }}</el-button>
      </div>
    </el-dialog>



  </article>
</template>
<script>
import {
  addApplicationByProject,
  getCurrentProjects,
  getCurrentProjectProjectTracksByAdvisor,
  removeNimApplication,
  setNimApplicationLeader,
  updateNimApplicationTeamCode,
  setNimApplicationMember

} from "../service.js";

import Cookie from "js-cookie";

import Clipboard from "clipboard";

export default {
  data() {
    return {
      formLabelWidth: "250px",
      formLabelWidth1: "150px",
      dialogInfo: {
        title: '',
        show: false,
      },
      dialogForm: {},
      rules: {
        project_code: [{
          required: true,
          message: "Please Select Project",
          trigger: "blur"
        }],
        account_id: [{
          required: true,
          message: "Please Select",
          trigger: "blur"
        }],
        lastName: [{
          required: true,
          message: "Please Input Last Name",
          trigger: "blur"
        }],
        givenName: [{
          required: true,
          message: "Please Input First Name",
          trigger: "blur"
        }],
        lastName_pinyin: [{
          required: true,
          message: "Please enter student's last name",
          trigger: "blur"
        }],
        givenName_pinyin: [{
          required: true,
          message: "Please enter student's first name",
          trigger: "blur"
        }],
        graduation: [{
          required: true,
          message: "Year of High School Graduation",
          trigger: "blur",
        }],
        gender: [{
          required: true,
          message: "Please enter select sex",
          trigger: "blur"
        }],
        email: [{
          required: true,
          message: "Please enter student email",
          trigger: "blur"
        }],
        guardian_mobile: [{
          required: true,
          message: "请输入学生监护人手机",
          trigger: "blur"
        }],
        guardian_email: [{
          required: true,
          message: "请输入学生监护人邮箱",
          trigger: "blur"
        }],
      },
      genderption: [{
        value: this.$t('m.studentInfo.boy'),
        label: this.$t('m.studentInfo.boy')
      }, {
        value: this.$t('m.studentInfo.girl'),
        label: this.$t('m.studentInfo.girl')
      }],
      graduationOption: [
        "2024",
        "2025",
        "2026",
        "2027"
      ],

      defevent: 0,
      loginId: Cookie.get("loginId"),
      registration_links: [],
      loginType: Cookie.get("loginType"),
      activeName: 'first',
      defevent: 0,
      showLinkDialog: false,
      selectSortType: 'team', // team update
      currentProjects: [],
      dataList: [],
      selectTrack: {
        showData: false
      }
    };
  },
  mounted() {
    this.requestCurrentProjects()
    this.fetchData()
  },
  methods: {

    confirmAddNewApplication(dialogForm) {
      this.$refs[dialogForm].validate((valid) => {
        if (valid) {
          this.dialogForm.contact_id = this.loginId;
          addApplicationByProject(this.dialogForm).then(res => {
            this.loadDialog = false;
            this.dialogInfo.show = false
            this.dialogForm = {}
            if (res.data.code == 0) {
              this.fetchData()
            } else {
              this.$message.error(res.msg);
            }
          })
        } else {
          return false;
        }
      });
    },

    requestCurrentProjects() {
      let contact_id = this.loginId
      getCurrentProjects('24-25').then((res) => {
        let result = res.data
        this.currentProjects = res.data
        for (let i = 0; i < result.length; i++) {
          let project = result[i]
          this.registration_links.push({
            event: project.project_product,
            link: 'https://my.eae.online/#/form/nim?project_code=' + project.project_code + '&contact_id=' + contact_id
          })
        }
      })
    },

    fetchData() {

      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      getCurrentProjectProjectTracksByAdvisor(this.loginId, '24-25').then((res) => {
        let result = res.data
        let allProjects = []
        let allStudents = []
        result.forEach(element => {
          allProjects = allProjects.concat(element.projects)
          allStudents = allStudents.concat(element.students)
        });
        this.dataList = res.data
        this.dataList.unshift({
          track_name: "All Projects",
          projects: allProjects,
          students: allStudents
        })
        loading.close()
      })
    },
    showTrackInfo(track) {
      this.selectTrack = track
      this.selectTrack.showData = true
    },

    addStudent() {
      //新增报名信息
      this.dialogInfo.show = true;
      this.dialogInfo.title = this.$t('m.studentInfo.newRegistrationInformation')
    },

    openDialog() {
      this.$nextTick(() => {
        if (this.$refs.dialogForm) {
          this.$refs.dialogForm.clearValidate();
        }
      });
    },
    closeDialog() {
      this.dialogInfo.show = false;
    },

    RemoveItem(item) {
      if (item.application_status == '已缴费' || item.application_status == '无需缴费') {
        return
      }
      var infoStr = 'Are you sure you want to remove ' + item.student_info.student_givenName_pinyin + ' ' + item.student_info.student_lastName_pinyin + ' from ' + item.application_event
      this.$confirm(infoStr, 'Confirm Student Removal', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        removeNimApplication(item._id.$id).then(res => {
          if (res.data.code === 0) {
            this.$message({
              message: 'Student Removed Successfully',
              type: 'success'
            })
            this.fetchData()

          }
        })
      })

    },

    setLeader(item) {
      if (item.application_team_role == '领队') {
        this.$confirm('Do you confirm set member?', 'Tip', {
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          setNimApplicationMember(item._id.$id).then(res => {
            if (res.data.code === 0) {
              this.$message({
                message: 'Role Modification Successful',
                type: 'success'
              })

              this.fetchData()

            } else {
              this.$message({
                message: res.data.msg,
                type: 'error'
              })
            }
          })
        })
        return
      }

      this.$confirm('Do you confirm to change the role of the student to member/leader？', 'Modify Student Role', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        setNimApplicationLeader(item._id.$id).then(res => {
          if (res.data.code === 0) {
            this.$message({
              message: 'Role Modification Successful',
              type: 'success'
            })
            this.fetchData()
          } else {
            this.$message({
              message: res.data.msg,
              type: 'error'
            })
          }
        })
      })
    },
    sortUpdate() {
      this.selectSortType = 'update'
      this.selectTrack.projects = this.selectTrack.projects.sort(
        (a, b) => a.update_time - b.update_time
      )
    },

    sortTeam() {
      this.selectSortType = 'team'
      this.selectTrack.projects = this.selectTrack.projects.sort(
        (a, b) => parseInt(a.application_team_code) - parseInt(b.application_team_code)
      )
    },

    showLink() {
      this.showLinkDialog = true
    },

    manageProject(item) {
      const routeData = this.$router.resolve({ path: 'pghome', query: { template_id: item.template_id, project_id: item.project_id, application_team_code: item.application_team_code, accountid: item.account_id } })
      window.open(routeData.href, '_blank')
    },
    doShare() {
      const clipboard = new Clipboard(".shareBtn");
      clipboard.on("success", () => {
        this.showShareDialog = false;
        this.$message.success("URL copied");
        clipboard.destroy();
      });
      clipboard.on("error", () => {
        this.$message.error("Copy failed");
        clipboard.destroy();
      });
    },

    changeTeamCode(row) {
      updateNimApplicationTeamCode(row._id.$id, row.application_team_code).then(res => {
        if (res.code === 0) {
          this.$message({
            message: 'Change Successful',
            type: 'success'
          })
          this.fetchData()
        }
      })
    }
  },
};
</script>
<style lang="scss" scoped>
article {

  background-color: rebeccapurple;
  // margin-left: 100px;
  // margin-top: 50px;
  background: #fff;
  // padding: 0 10px;


  .infoContent {
    padding: 20px 0;
    height: 100%;
    width: 50%;
  }

  .homeContainer {
    height: calc(100vh - 58px);
    width: 100%;
    display: flex;
    overflow: hidden;
    background: #fff;
    font-family: "DDINAlternateRegular";

    .leftSide {
      height: 100%;
      width: 260px;
      flex-shrink: 0;
      background: #fff;
      box-shadow: 2px 0px 12px 1px rgba(96, 91, 255, 0.07);

      .el-menu {
        border: none;
        background: none;
      }

      .el-menu-item {
        color: #204386 !important;
      }

      .el-menu-item.is-active {
        background: #ffb414;
        color: #204386 !important;
      }

      .el-menu-item:focus,
      .el-menu-item:hover {
        background: #ffb414;
        color: #204386 !important;
      }

      .icon {
        width: 32px;
        height: 32px;
        margin-right: 10px;
      }
    }

    .right {
      margin-left: 40px;
      flex: 1;
      height: 100%;
      background: #fff;
      overflow: hidden;
      position: relative;
    }

    .tabStyle {
      height: 70vh;
      width: 96%;
      // background-color: #204386;
      overflow: hidden;
      overflow-y: auto;

    }
  }

  .adorn {
    background-color: #ffb414;
    text-align: center;
    color: #333;
    font-weight: normal;
    // border-bottom: 2px solid #ebeef5!important;
  }

  .btn-style {
    border: none;
    padding: 0px;
    background-color: white;
    background: white;
    color: #333;
    // background-color: #ffb414;
    float: left;
  }

  .sortSelect {
    background-color: #ffb414;
    border-color: #ffb414;
    color: white;
  }

  .sortNoSelect {
    background-color: white;
    border-color: #333;
    color: #333;
  }

  .projectList {
    margin-top: 20px;
    // background-color: red;
    height: 70vh;
    width: 90%;
    overflow-y: auto;
  }

  .itemStyle {
    // margin-left: 20px;
    // margin-right: 20px;
    margin-top: 15px;
    position: relative;
    height: 150px;
    background-color: oldlace;
    border-radius: 5px;
  }

  .teamCodeStyle {
    padding-top: 10px;
    margin-left: 10px;
    color: #333;
    font-size: 15px;
  }

  .projectTitleStyle {
    margin-left: 10px;
    margin-top: 5px;
    color: #333;
    font-size: 25px;
  }

  .leaderStyle {
    margin-left: 10px;
    margin-top: 25px;
    color: #333;
    font-size: 18px;
  }

  .memberStyle {
    margin-left: 10px;
    margin-top: 5px;
    color: #333;
    font-size: 18px;
  }

  .infoStyle {
    position: absolute;
    right: 10px;
    top: 15px;
    width: 80%;
    text-align: right;
  }

  .manageStyle {
    position: absolute;
    bottom: 15px;
    right: 10px;
    background-color: #ffb414;
    border: none;
    color: #333;
  }

  .eventLinkStyle {
    margin-left: 10px;
    margin-right: 10px;
    height: 40px;
    margin-top: 10px;
    width: calc(100% - 20px);
  }

  .eventName {
    color: #333;
    padding-right: 10px;
    // width: 230px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    // font-size: 18px;
    background-color: oldlace;
    // float: left;

  }

  .linkStyle {
    height: 30px;
    padding-left: 10px;
    padding-right: 10px;
    line-height: 30px;
    background-color: #f5f5f5;
    overflow: hidden;
    /* 隐藏超出内容的区域 */
    white-space: nowrap;
    /* 不允许文本折行 */
    text-overflow: ellipsis;
    /* 显示省略号代替超出部分的文本 */
  }

  .shareBtn {
    line-height: 30px;
    width: 80px;
    height: 30px;
    text-align: center;
    background-color: #ffb414;
    cursor: pointer;

  }

}
</style>