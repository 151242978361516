<template>
    <article>

        <el-table :data="projectTable" v-loading="loading" header-cell-class-name="adorn" size="medium" border
            ref="leftTable" class="left-table">
            <el-table-column label="项目" align="center" prop="application_project">
            </el-table-column>
            <el-table-column label="提交渠道" align="center" prop="application_channel">
            </el-table-column>
            <el-table-column label="报名子项目" align="center" prop="application_project_BU">
            </el-table-column>
            <el-table-column label="状态" align="center" prop="application_status">
            </el-table-column>
            <el-table-column width="240" label="操作" align="center">
                <template slot-scope="scope">
                    <el-button class="btn-link" @click="lookNext(scope.row)" type="primary" size="small">查看下一阶段的项目
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog class="dialog" :title="dialogInfo.title" :visible.sync="dialogInfo.show"
            :close-on-click-modal="false" width="60%">
            <el-table v-loading="loadDialog" :data="dialogTable" header-cell-class-name="adorn" size="medium" border
                ref="leftTable" class="left-table">
                <el-table-column label="项目名称" align="center" prop="project_name"></el-table-column>
                <el-table-column label="项目平台" align="center" prop="project_platform"></el-table-column>
                <el-table-column label="项目类型" align="center" prop="project_type">
                </el-table-column>
                <el-table-column label="项目年份" align="center" prop="project_year">
                </el-table-column>
                <el-table-column label="操作" align="center" width="120">
                    <template slot-scope="scope">
                        <el-button class="btn-link" @click="signUp(scope.row)" type="primary" size="mini">报名
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>

    </article>
</template>
<script>
    import {
        getStudentApplications,
        getNextProject,
        addNewProjectApplication
    } from "../service.js";

    import Cookie from "js-cookie";
    export default {
        data() {
            return {

                dialogInfo: {
                    title: "报名",
                    show: false,
                },
                dialogTable: [],
                projectTable: [],
                loading: false,
                loadDialog:false,
                account_id: Cookie.get("studentid"),


            };
        },
        created() {
            this.getStudentApplications();
            this.getNextProject(); //获取学校列表
        },
        mounted() {},
        methods: {
            //报名
            signUp(info){
                addNewProjectApplication({
                    project_code: info.project_code,
                    student_id: Cookie.get("loginId"),
                    events:info.project_events
                }).then((res) => {
                   
                    if (res.code == 0) {
                        this.$message.success('该项目已报名成功！')
                        this.dialogInfo.show = false
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            },
          
            //该学生已参加的项目列表
            getStudentApplications() {
                this.loading = true;
                getStudentApplications({
                    student_id: Cookie.get("loginId")
                }).then((res) => {
                    this.loading = false;
                    if (res.code == 0) {
                        this.projectTable = res.data;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            },
            //获取下一项目
            lookNext(info) {
                this.dialogInfo.show = true;
                this.loadDialog =true;
                getNextProject({
                    project_code: info.application_project,
                }).then((res) => {
                    this.loadDialog =false;
                    if (res.code == 0) {
                        this.dialogTable = res.data;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            },
            
        },
    };
</script>
<style lang="scss" scoped>
    article {
        margin-top: 50px;
        background: #fff;
        padding: 10px;

        /deep/ .el-form.el-form--inline {
            background-color: #f8f8f8;
            margin-bottom: 10px;
            padding: 8px;
            overflow: hidden;
        }

        /deep/ .el-form.el-form--inline .el-form-item.el-form-item--medium {
            margin-right: 4px;
            margin-top: 5px;
            margin-bottom: 5px;
        }
    }

    .formP {
        margin: 0;
        padding: 0;

        padding-left: 10px;
        // border:1px solid #e9eef3;
        background: #f8f9fa;
        border-radius: 4px;
        width: 70%;
        position: relative;
    }
</style>