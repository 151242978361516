<template>
    <article>
        <h3>剩余可使用次数:{{rest_count}}</h3>
        <el-table height="600" v-loading="loading" :data="projects" header-cell-class-name="adorn" size="medium" border ref="leftTable" class="left-table" style="margin-top:10px">
            <el-table-column label="项目名称" align="center" prop="project_name"></el-table-column>
            <el-table-column label="项目代码" align="center" prop="project_code"></el-table-column>
            <el-table-column label="额度" align="center" prop="remain_count" width="100"></el-table-column>
            <el-table-column label="是否参加" align="center">
                <template slot-scope="scope">
                    {{scope.row.application?'已报名':'尚未参加'}}
                </template>
            </el-table-column>
            <el-table-column width="200" label="操作" align="center">
                <template slot-scope="scope">
                    <el-button class="btn-link" @click="apply(scope.row)" type="primary" size="small" v-if="!scope.row.application">参加该项目</el-button>
                    <el-button class="btn-link" @click="cancel(scope.row)" type="primary" size="small" v-if="scope.row.application">取消参加</el-button>
                    <el-button @click="initUpload(scope.row)" type="warning" size="small">承诺书</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog :visible.sync="showUpload">
            <div v-for="item in attaches" :key="item._id.$id" style="margin-bottom:20px">
                <el-link :href="item.url" type="primary" target="_blank">{{item.name}}</el-link>
             
            </div>
        </el-dialog>
    </article>
</template>

<script>
import {getProjectsByPackageApplication,initApplicationInPackage,cancelApplicationInPackage,getStudentCommitAttaches} from '../service'
import Cookie from "js-cookie";
export default {
    data(){
        return{
            loading:false,
            projects:[],
            application_id:this.$route.query.application_id,
            rest_count:0,
            project_id:'',
            showUpload:false,
            attaches:[],
            student_id:''
        }
    },
    mounted(){
        this.fetchData()
    },
    methods:{
        fetchData(){
            getProjectsByPackageApplication(this.application_id).then(res=>{
                if(res.data.code==0){
                    this.rest_count = res.data.data.rest_count
                    this.projects = res.data.data.data
                    this.student_id = res.data.data.student_id
                }
            })
        },
        apply(row){
            this.$confirm('是否确认报名?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(()=>{
                initApplicationInPackage(this.application_id,row._id.$id).then(res=>{
                    if(res.data.code===0){
                        this.$message.success('报名成功')
                    }else{
                        this.$message.warning(res.data.msg)
                    }
                    this.fetchData()
                })
            })
            
        },
        cancel(row){
            this.$confirm('是否确认取消?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(()=>{
                cancelApplicationInPackage(row.application._id.$id).then(res=>{
                    if(res.data.code===0){
                        this.$message.success('取消成功')
                    }
                    this.fetchData()
                })
            })
            
        },
        initUpload(item){
            this.project_id = item._id.$id
            this.getStudentCommitAttaches()
            this.showUpload = true
            
        },
        getStudentCommitAttaches(){
            getStudentCommitAttaches(this.student_id,this.project_id).then(res=>{
                if(res.data.code===0){
                    this.attaches = res.data.data
                }else{
                    this.attaches = []
                }
            })
        },
    }
}
</script>

<style>

</style>