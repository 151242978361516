import Vue from 'vue'
import Vuex from 'vuex'
import savingData from './dataHome/';
const sessionStoragePlugin =store=>{
  store.subscribe((mutation,state)=>{
          Object.keys(state).forEach((item,index)=>{
            window.sessionStorage.setItem(item,JSON.stringify(state[item]))
          })
  })
}
Vue.use(Vuex);

export default new Vuex.Store({
  plugins:[sessionStoragePlugin],
  modules:{
    savingData
  }
})


