<template>
    <article>
        <div class="mainView1">

            <div class="itemStyle1" v-for="item in canSelectEvents">

                <div class="eventStyle">
                    {{ item }}
                </div>
            </div>
        </div>
    </article>
</template>

<script>

import {

    getNIMProjectEvents,  

  } from "../service.js";
import Cookie from "js-cookie";

export default {
    data(){
        return{
            activeName:'first',
            canSelectEvents:[],
            project_code: Cookie.get("loginProject"),

        }
    },
    mounted(){
        this.getNIMProjectEvents()

    },
    methods:{
        getNIMProjectEvents() {
          //获取其他项目
          let param = {
            project_code: this.project_code,
          };

          this.canSelectEvents = []
   
          getNIMProjectEvents(param).then((res) => {

            if (res.code == 0) {
              var project_events_default = res.data.project_events_default

              for(let i = 0; i < project_events_default.length; i++){
                this.canSelectEvents.push(project_events_default[i])
              }

            } else {
                this.$message.error(res.msg);
            }
          });
      },
    }
}
</script>

<style>

.mainView1{
    width: 80%;
    margin-left: 10%;
    margin-top: 20px;
    height: 80vh;
    /* background-color: aqua; */
}
.itemStyle1{
    float: left;
    width: 50%;
    height: 50px;
    margin-top: 10px;
    /* background-color: bisque; */
}
.eventStyle{
    margin-left: 30px;
    margin-right: 30px;
    height: 50px;
    background-color: #ffb414;
    color: #204386;
    border:1px solid #204386;
    border-radius: 4px;
    text-align: center;
    line-height: 50px;
    font-size: 20px;
    font-weight: 500;
}
</style>