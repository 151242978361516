<template>
  <article>
    <el-table
      :data="messageTable"
      header-cell-class-name="adorn"
      size="medium"
      border
      ref="leftTable"
      class="left-table"
      empty-text="No Messages"
    >
      <el-table-column label="Date" align="center" prop="create_time">
          <template slot-scope="scope">{{
            scope.row.create_time | timeFormat
          }}</template>
      </el-table-column>
      <el-table-column label="Content" align="center" prop="message_content">
      </el-table-column>
      <!-- <el-table-column label="项目" align="center" prop="message_project"> -->
      <!-- </el-table-column> -->
      <el-table-column label="Sender" align="center" prop="sender_name">
      </el-table-column>
    </el-table>
  
  </article>
</template>
<script>
import {
  getMyMessages,
} from "../service.js";

import Cookie from "js-cookie";
export default {
  data() {
    return {
      loadDialog: false,
      templateType: [],
      messageTable: [],
      paginationLoading: false,
      userId:Cookie.get("loginId")
    };
  },
  created() {
this.getMyMessages();
  },
  mounted() {},
  methods: {
   getMyMessages(){
     let param={
       id:this.userId,
       project:Cookie.get("loginProject")
     };
      getMyMessages(param).then((res) => {
                if (res.code == 0) {
                // this.messageTable =res.data
                } else {
                  this.$message.error(res.msg);
                }
              });
   }
    },
  
};
</script>
<style lang="scss" scoped>
article {
   margin-top: 50px;
  background: #fff;
  padding:20px 10px;
  /deep/ .el-form.el-form--inline {
    background-color: #f8f8f8;
    margin-bottom: 10px;
    padding: 8px;
    overflow: hidden;
  }
  /deep/ .el-form.el-form--inline .el-form-item.el-form-item--medium {
    margin-right: 4px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

</style>