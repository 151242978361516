import * as mutationType from './mutation-types';
export default{
    [mutationType.USER_INFO]({commit},params){
        commit('USER_INFO',params)
    },
    [mutationType.MENU_ROUTER_INFO]({commit},params){
        commit('MENU_ROUTER_INFO',params)
    },
    [mutationType.LANGUAGE_TYPE]({commit},params){
        commit('LANGUAGE_TYPE',params)
    },
   
}