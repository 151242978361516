<template>
    <div class="Home">
        <div class="topLine">
            <div class="contentBtn" @click="openDrawer">目录</div>
            <div class="contentBtn" style="margin-left:10px" @click="lastContent">上一章</div>
            <div class="contentBtn" style="margin-left:10px" @click="nextContent">下一章</div>
        </div>
        <el-container style="height: 100%;margin-top:50px;">
            <el-backtop></el-backtop>
            <el-drawer
                custom-class="color"
                :visible.sync="drawer"
                direction="ltr"
                style="width:1100px;overflow:hidden"
                >
                <div class="navTree">
                    <el-tree :data="menuItems" :props="defaultProps" @node-click="handleNodeClick" :render-content="renderContent"></el-tree>
                </div>
            </el-drawer>
            
            <el-container class="main-container">
                
                <el-main style="font-family: 'Amiri',serif;overflow: unset;padding:0">
                <section class="titleH">
                    <div>
                        <span>{{content.content_info.content_name || '请选择课程~'}}</span>
                    <span class="title-description" v-if="content_quiz.length!==0">（本章有{{content_quiz.length}}道练习）</span>
                    </div>
                    <div style="height:100%;margin-left:6px;display:flex;align-items:center">
                        <div class="topBtn" @click="beginQuiz" v-if="content_quiz.length!==0  " v-show="!onDoing" :disabled="content_quiz.length==0">去做题</div>
                        <!-- <el-button size="mini" type="primary" class="topBtn" @click="beginQuiz" v-if="content_quiz.length!==0  " v-show="!onDoing" :disabled="content_quiz.length==0">Do Exercises</el-button> -->
                        <div class="reviewBtn" @click="endQuiz" v-if="content_quiz.length!==0  " v-show="onDoing">返回课程</div>
                        <div class="deleteBtn" @click="resetQuiz" v-if="content_quiz.length!==0  " v-show="onDoing">删除错误答案</div>
                    </div>
                </section>
                <Quiz :studentId="studentId" :quizs="quizs" :onDone="onQuizDone" :onCI="onChangeDone" @changeStatus="onChangeStatus" />
                
                
                <div v-if="content.content_info.content_instruction" class="content_instruction">{{content.content_info.content_instruction || ''}}</div>

                <!-- <div v-if="content.blocks">
                    <div v-for="item in content.blocks" :key="item._id.$id" style="margin-top:50px">
                        <div class="titleH" :id="item._id.$id">{{item.block_info.block_name}}</div>
                        <div v-html="item.block_info.html_text" class="htmlTxt"></div>
                    </div>
                </div> -->
            
                <!-- <div v-if="content.content_type === 'AUDIO'" style="height:calc(100% - 60px)">
                    <audio :src="content.audio_url" controls controlsList="nodownload" style="margin-top: 10px"></audio>
                    <div v-if="content.html_text" v-html="content.html_text"></div>
                </div> -->

                <div v-if="content.content_info.content_type === 'HTML'">
                    <div v-if="content.content_info.html_text" v-html="content.content_info.html_text" class="htmlTxt"></div>
                    <div style="width: 100%;height:100%;" v-if="!content.content_info.html_text && content.content_info.pdf_url">
                        <pdf :src="content.content_info.pdf_url" width="100%" height="100%" 
                            v-for="i in numPages"
                            :key="i"
                            :page="i"
                            style="width: 100%;"
                            @page-loaded="pageloaded"
                        />
                    </div>
                    <div v-if="!content.content_info.html_text && !content.content_info.pdf_url" style="margin-top:20px">Coming soon.</div>
                </div>
                <el-collapse v-model="activeNames" style="border-radius:10px">
                    <el-collapse-item title="附件：" name="1" v-show="content_attach.length !== 0">
                        <div v-for="item in content_attach" :key="item.attach_key"><a class="attach" target="_blank" :href="item.attach_address" :download="item.attach_title">{{item.attach_title}}</a></div>
                    </el-collapse-item>
                </el-collapse>

                <!-- <div v-if="content.content_type === 'VIDEO'">
                    <div>
                        <video :src="content.video_url" id="videoPlay" controls="controls" class="video" oncontextmenu="return false;" v-show="!isBeginQuiz&&!onDoing" @timeupdate="timeupdate"  controlslist="nodownload" @ended="videoEnd">您的浏览器不支持 video 视屏播放。</video>
                    </div>
                </div> -->

                </el-main>
            </el-container>
        
        </el-container>
        <div class="contentMessage">
            <h3>问题反馈</h3>
            <el-input style="margin-top:40px" type="textarea" :rows="10" v-model="messageContent" placeholder="请写下你的问题"></el-input>
            <div class="deleteBtn" style="margin:20px 0" @click="updateContentMessage">提交</div>
            <el-table :data="messages" style="margin:30px 0">
                <el-table-column prop="content" label="问题" align="center"></el-table-column>
                <el-table-column prop="reply_content" label="回复" align="center"></el-table-column>
            </el-table>
        </div>
        
    </div>
    
 
</template>


<script>
import { getContents, getContent, netService,resetQuiz,getContentMessages,updateContentMessage } from '../service.js'
import Cookie from 'js-cookie'
import Quiz from './Quiz'
import pdf from 'vue-pdf'
export default {
    name: 'Home',
    props: {
        msg: String
    },
    components: {
        Quiz,
        pdf
    },
    data() {
        return {
            drawer: true,
            now_index: '' ,
            educator_comment: '',
            comments: [],
            now_content_id: '',
            videoDone: false,
            videoElement: null,
            session_id: this.$route.query.session_id,
            menuItems:[],
            defaultProps: {
                children: 'children',
                label: 'label',
                content_info: 'content_info'
            },
            content: {
                content_info:{}
            },
            studentId: '',
            quizs: [],
            isBeginQuiz: false,
            quizsByTimes: {},
            content_quiz: [],
            onDoing: false,
            activeNames: ['1'],
            content_attach: [],
            quizIndex: 0,
            numPages: 0,
            loading:'',
            messages:[],
            messageContent:'',
            pageIndex:0,
            totalIndex:0
        }
    },
    mounted(){
        this.studentId = Cookie.get('loginId')
        this.loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });
        getContents({session_id: this.session_id,
                student_id: this.studentId
            }).then((data) => {
                console.log('获取到的数据',data)
                this.menuItems = this.handleList(data)
                this.totalIndex = this.menuItems.length
                console.log('导航栏',this.menuItems)
                this.class_id = this.menuItems[0].content_info.class_id
                this.handleNodeClick(
                    this.menuItems[0]
                ) 
        })
    },
    methods: {
        resetQuiz(){
            this.$confirm('是否删除错误答案?', '提醒', {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    let param={
                        user_id:this.studentId,
                        class_id:this.class_id,
                        session_id:this.session_id
                    }
                    resetQuiz(param).then(res=>{

                        if(res.code===0){
                            this.$message({
                                message:'删除成功',
                                type:'success'
                            })
                        }
                        location.reload();
                    })
                })
        },
        pageloaded(){
            this.loading.close();
        },
        renderContent(h, { node, data }) {
            return (
            <span class="titleTxt">
                {node.label}
            </span>);
        },
        handleNodeClick(data) {
             let index = this.menuItems.findIndex(item=>item.id===data.id)
             this.pageIndex = index
       
            this.onDoing = false
            if(data.parent_id && this.now_content_id===data.parent_id){
                document.getElementById(data.id).scrollIntoView();
            }else{
                this.now_content_id = data.parent_id?data.parent_id:data.id;
                this.fetchData(data)
                // this.getSB()
                this.getContentMessages()
            }
    
        },
        fetchData(data){
            if( data.content_info.content_type === 'VIDEO'){
                getContent({content_id: this.now_content_id,
                    student_id: this.studentId
                }).then((data) => {

                    this.quizsByTimes = {}
                    this.isBeginQuiz = false
                    this.quizs = []
                    this.videoDone = false        
                    const quizsByTimes = this.quizsByTimes
                    let content_quiz = data.content_quiz
                    let reg = new RegExp( '\r\n' , "g" )
                    for(let item of content_quiz){
                    item.question_title = item.question_title.replace(reg,'</br>')
                    }
                    this.content_quiz = content_quiz

                    data.content_quiz.map((v) => {
                    if(quizsByTimes[+v.quiz_position]) {
                        quizsByTimes[v.quiz_position].push(v)
                    } else {
                        quizsByTimes[v.quiz_position] = [v]
                    }
                    })
  
                    this.question = res.data.question_title.replace(reg,'</br>');

                    this.content_attach = data.content_attach
                    this.loading.close()
              
                })
            }else{
                this.loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                getContent({content_id: this.now_content_id,
                    student_id: this.studentId
                }).then(res => {
                    this.content = res
                    this.quizsByTimes = {}
                    this.isBeginQuiz = false
                    this.quizs = []
                    this.videoDone = false        
                    const quizsByTimes = this.quizsByTimes
                    
                    let content_quiz = res.content_quiz
                    let reg = new RegExp( '\r\n' , "g" )
                    for(let item of content_quiz){
                        item.question_title = item.question_title.replace(reg,'</br>')
                    }
                    this.content_quiz = content_quiz
                    res.content_quiz.map((v) => {
                        if(quizsByTimes[+v.quiz_position]) {
                            quizsByTimes[v.quiz_position].push(v)
                        } else {
                            quizsByTimes[v.quiz_position] = [v]
                        }
                    })
                    this.content_attach = res.content_attach
                    if(!this.content.html_text && this.content.pdf_url){
                      
                        const loadingTask = pdf.createLoadingTask(this.content.pdf_url);
                        this.loading = this.$loading({
                            lock: true,
                            text: 'Loading',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.7)'
                        });
                        
                        loadingTask.then(pdf => {
                            this.numPages = pdf.numPages;
                        });
                    }
                    this.$nextTick(()=>{
                        ///定位到某一部分
                        if(data.parent_id){
                            document.getElementById(data.id).scrollIntoView();
                        }else{
                            window.scroll(0, 0);
                        }
                    })
                    this.loading.close()

                })
            }
        },
        handleList(list) {
            let result = []
            for(let item of list){
                let cursor = {
                    label:item.content_info.content_name,
                    content_info:item.content_info,
                    children:[],
                    id:item._id.$id
                }
                if(item.blocks){
                    for(let x of item.blocks){
                        let xCursor={
                            label:x.block_info.block_name,
                            block_info:x.block_info,
                            children:[],
                            id:x._id.$id,
                            parent_id:item._id.$id,
                            content_info:item.content_info
                        }
                        cursor.children.push(xCursor)
                    }
                }
                result.push(cursor)
            }
            return result;
        },
    
        onQuizDone() {
            this.isBeginQuiz = false
            this.quizs = []
            this.onDoing = false
            

            if(this.videoDone){
                setTimeout(() => {
                    this.videoElement.play()
                    this.videoDone = false
                }, 300);
            }
    
        },
        onChangeDone(index){
            console.log(index)
            this.quizIndex = index
        },
        timeupdate(event) {
            const currentTime = event.target.currentTime;//获取当前播放时间
            this.videoElement = event.target
            // console.log(currentTime,this.quizsByTimes[~~currentTime],this.quizsByTimes);//在调试器中打印
            if(this.quizsByTimes[~~currentTime]){
            this.quizs = this.quizsByTimes[~~currentTime]
            this.onDoing = true
            setTimeout(() => {
                this.videoElement.pause()
            }, 1000);
            this.isBeginQuiz = true
            
            }
        },
        videoEnd() {
            if(this.quizsByTimes[-1]){
            this.quizs = this.quizsByTimes[-1]
            
            this.isBeginQuiz = true
            this.videoDone = true
            this.onDoing = true
            }
        },
        submitComment(){
            if(this.educator_comment == '' ){
            this.$message({
                    message: '内容不能为空',
                    type: 'error',
                    duration: 1500
                });
            return
            }
            if( this.now_index == ''){
                this.$message({
                    message: '内容归属为空',
                    type: 'error',
                    duration: 1500
                });
                return
            }
            netService({
                functionName: 'sendLFMessage',
                student_id: this.studentId,
                question_id: this.now_content_id,
                text: this.educator_comment,
                index: this.now_index,
                }).then((data => {
                if(data == 1){
                    this.educator_comment = ''
                    this.$message({
                        message: '添加成功~',
                        type: 'success',
                        duration: 1500
                    });
                    this.getSB()
                }
            }))
        },
        handleClose(closeDrawer){
            closeDrawer()
        },

        beginQuiz(){
            this.onDoing = true
            let test = []
            for(const index in this.quizsByTimes){
            test.push(...this.quizsByTimes[index])
            }
            this.quizs = test
            this.isBeginQuiz = !this.isBeginQuiz
        },
        endQuiz(){
            this.onDoing = false
            let test = []
            
            this.quizs = test
            this.isBeginQuiz = !this.isBeginQuiz
        },
        onChangeStatus(e){

            this.quizs[e-1].complete_status = 'yes'
        },
        openDrawer(){
            this.drawer = true
        },
        getContentMessages(){
            getContentMessages(this.studentId,this.now_content_id).then(res=>{
                if(res.data.code===0){
                    this.messages = res.data.data
                }
            })
        },
        updateContentMessage(){
            updateContentMessage(this.studentId,this.now_content_id,this.messageContent).then(res=>{
                if(res.data.code===0){
                    this.$message({
                        message:'更新成功',
                        type:'success'
                    })
                    this.getContentMessages()
                }
            })
        },
        lastContent(){
            if(this.pageIndex!==0){
                this.pageIndex--;
                this.handleNodeClick(this.menuItems[this.pageIndex]);
            }else{
                this.$message.error('已经是第一章')
            }
        },
        nextContent(){
            if(this.pageIndex!==this.totalIndex-1){
                this.pageIndex++;
                this.handleNodeClick(this.menuItems[this.pageIndex]);
            }else{
                this.$message.error('已经是最后一章')
            }
        }
    }
}
</script>

<style lang="scss">
.Home{
    .topLine{
        width: 100%;
        height: 100px;
        display: flex;
        align-items: center;
    }
    .bottomImg{
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 16%;
        z-index: 0;
    }
    .contentBtn{
        width: 90px;
        height: 36px;
        line-height: 36px;
        font-size: 16px;
        font-weight: bold;
        color: white;
        background: #409EFF;
        cursor: pointer;
        font-family: Microsoft YaHei;
        margin-left: 50px;
        border-radius: 6px;
        text-align: center;
    }
    .main-container{
        max-width: 1028px;
        // width: 100%;
        margin: 0 auto;
        line-height: 200%;
        font-size:19px;
    }

    .attach{
        color:#409EFF;
        display: block;
        text-decoration: wavy;
    }
    .comments{
        margin-top: 30px;
        padding-bottom: 100px;
    }
    .Home {
        width: 100%;
    }
    .el-header {
        background-color: #B3C0D1;
        color: #333;
        line-height: 60px;
    }


    .console{
        position: absolute;
        bottom: 0;
        height: 300px;
        width: calc(100% - 201px);
        border-top: 1px solid #ccc;
    }
    .el-tree-node{
        padding: 15px 20px;
    }
    .el-tree-node__content {
        background: rgb(238, 241, 246);
        &:hover {
            color: #409EFF;
            background: rgb(238, 241, 246);
        }
    }
    .el-tree-node:focus>.el-tree-node__content{
        color: #409EFF;
        background: rgb(238, 241, 246);
    }
    .titleH{
        width: 100%;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #222222;
        display: flex;
        align-items: center;
    }
    #videoPlay{
        width: 100%;
    }
    .title-description{
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #222222;
        // margin-left: 6px;
    }

    .content_instruction{
        display: block;
        font-size: 15px;
        line-height: 30px;
        line-height: 150%;
        margin-top:10px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #909399;

    }
    .option{
        width: 80%;
    }

    .el-popper{
        width: 95%!important;
    }
    .color{
        background: #eef1f6;
        // overflow: scroll;
    }
    .green{
        color: green
    }
    .el-tree-node{
        white-space: normal!important;
        background: rgb(238, 241, 246);
    }

    .titleTxt{
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: 600;
        display: flex;
        align-items: center;
        border: none;
    }


    video::-internal-media-controls-download-button { 
        display:none; 
    }
    video::-webkit-media-controls-enclosure { 
        overflow:hidden; 
    }
    video::-webkit-media-controls-panel { 
        width: calc(100% + 30px); 
    }
    .addCommets .el-textarea__inner{
        height: 100px;
    }
    .topBtn{
        width: 104px;
        margin-left: 10px;
        font-size: 13px;
        cursor: pointer;
        height: 38px;
        color: white;
        font-family: Microsoft YaHei;
        font-weight: 600;
        line-height: 38px;
        text-align: center;
        border-radius: 6px;
        background: #409EFF;
    }
    .reviewBtn{
        width: 150px;
        margin-left: 10px;
        font-size: 13px;
        cursor: pointer;
        height: 36px;
        color: #409EFF;
        border: 1px solid #409EFF;
        font-family: Microsoft YaHei;
        font-weight: 600;
        line-height: 36px;
        text-align: center;
        border-radius: 6px;
        background: white;
    }
    .deleteBtn{
        width: 150px;
        margin-left: 10px;
        font-size: 13px;
        cursor: pointer;
        height: 38px;
        color: white;
        font-family: Microsoft YaHei;
        font-weight: 600;
        line-height: 38px;
        text-align: center;
        border-radius: 6px;
        background: #409EFF;
    }
    .htmlTxt{
        margin-top: 10px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;

        color: #444444!important;
        strong{
            color: #444444!important;
        }
    
        img{
            width: 80%;
            margin: 0 auto!important;
        }
        .ql-align-center{
            display: flex;
            justify-content: center;
            width: 100%!important;
        }
    }

    .el-icon-caret-top{
        color: #409EFF;
    }
    .navTree{
        height: 500px;
        overflow-y: scroll;
        height: 80%;
    }
    .contentMessage{
        width: 1028px;
        margin: 30px auto;
    }

    .el-textarea__inner:focus{
        border: 1px solid #409EFF;
    }
}
</style>
