<template>
  <article>
    <div class="homeContainer">


      <div class="mainView">
        <el-tabs v-model="activeName" style="font-size: 25px;">
          <el-tab-pane v-if="showOngoing" label="Pending Payment" name="first">

            <div class="projectList">


              <div class="itemStyle" v-for="item in pendingList">



                <div style="display: table"></div>

                <!-- <div :class="item.application_team_code == '' ? 'teamCodeStyle1' : 'teamCodeStyle'">
                    {{ item.application_team_code == '' ? 'Awaiting Team Confirmation' : ('#' + item.application_team_code)}}
                  </div> -->

                <div class="projectTitleStylePending">
                  {{ item.event }}
                </div>

                <div class="leaderStyle">
                  Please complete the payment to complete your registration.
                </div>

                <!-- <div class="memberStyle">
                    Members:{{ item.memberName }}
                  </div> -->


                <!-- <div class="infoStyle">
                   Progress: {{item.progress}}  Word Count: {{item.word_count}}
                  </div> -->

                <el-button type="primary" class="removeStylePending" @click="RemoveItem(item)">Cancel</el-button>
                <el-button type="primary" class="manageStylePending" @click="gotoPay(item)">Pay</el-button>

              </div>

            </div>


          </el-tab-pane>
          <el-tab-pane :label="'Ongoing Projects (' + projectLists.length + ')'" name="second">


            <div class="projectList">


              <div v-for="(item, index) in projectLists" :key="index"
                :class="item.application_team_code == '' ? 'newItem' : 'newItem newItem1'">
                <div v-if="createNimImgResource(item.application_project) != ''" style="margin-right: 15px;">
                  <img :src="createNimImgResource(item.application_project)"
                    style="width: 100px;height: auto;object-fit: cover;">
                </div>
                <div style="flex: 1;box-sizing: border-box;height: 100%;display: flex;flex-direction: column;">
                  <div style="display: flex;align-items: center;">
                    <div style="flex: 1;">
                      <div :class="item.application_team_code == '' ? 'teamCodeStyle1' : 'teamCodeStyle'">
                        {{ item.application_team_code == '' ? 'Awaiting Team Confirmation' : ('#' +
                          item.application_team_code) }}
                      </div>
                    </div>
                    <div class="infoStyle">
                      Progress: {{ item.progress }} Word Count: {{ item.word_count }}
                    </div>
                  </div>
                  <div class="projectTitleStyle" style="flex: 1;">
                    {{ item.event }}
                  </div>
                  <div style="display: flex;align-items: flex-end;height: 56px;">
                    <div style="flex: 1;">
                      <div class="leaderStyle">
                        Leader:{{ item.leaderName }}
                      </div>
                      <div class="memberStyle">
                        Members:{{ item.memberName }}
                      </div>
                    </div>
                    <div>
                      <div v-if="item.application_team_code != ''">
                        <div style="display: flex;">
                          <el-button type="primary" v-if="item.submit_status == 'show'" @click="doSubmitProject(item)"
                            class="manageStyle1">Submit</el-button>
                          <div v-if="item.submit_status == 'disabled'"
                            style="display: flex;gap: 5px;margin-right: 10px;">
                            <p style="color: #333;font-size: 14px;"
                              v-html="'Results expected on  <br>' + formatPublishDate(item)"></p>
                            <el-button type="primary" class="manageStyle1"
                              style="background-color: gray;">Submitted</el-button>
                          </div>

                          <el-button
                            v-if="item.entry_submission_deadline == '' || item.entry_submission_deadline >= new Date().getTime()"
                            type="primary" class="manageStyle1" @click="manageProject(item)">Manage</el-button>
                          <el-button
                            v-if="item.entry_submission_deadline != '' && item.entry_submission_deadline < new Date().getTime()"
                            type="primary" class="manageStyle1" @click="getPdf(item)">View</el-button>
                        </div>
                      </div>
                      <div v-if="item.application_team_code == ''">
                        <!-- <el-button v-if="item.entry_submission_deadline == '' || item.entry_submission_deadline >= new Date().getTime()" type="primary" class="manageStyle" @click="createTeam(item)">Create A Team</el-button> -->
                        <el-button
                          v-if="item.entry_submission_deadline == '' || item.entry_submission_deadline >= new Date().getTime()"
                          type="primary" class="manageStyle" @click="changeTeam(item, 'Join My Team')">Join My
                          Team</el-button>
                      </div>
                    </div>
                  </div>
                </div>


                <!-- <div style="display: table"></div> -->

                <!-- <div :class="item.application_team_code == '' ? 'teamCodeStyle1' : 'teamCodeStyle'">
                  {{ item.application_team_code == '' ? 'Awaiting Team Confirmation' : ('#' +
                    item.application_team_code) }}
                </div> -->

                <!-- <div class="projectTitleStyle">
                  {{ item.event }}
                </div> -->

                <!-- <div class="leaderStyle">
                  Leader:{{ item.leaderName }}
                </div>

                <div class="memberStyle">
                  Members:{{ item.memberName }}
                </div> -->


                <!-- <div class="infoStyle">
                  Progress: {{ item.progress }} Word Count: {{ item.word_count }}
                </div> -->


                <!-- <div v-if="item.application_team_code != ''">
                  <el-button
                    v-if="item.entry_submission_deadline == '' || item.entry_submission_deadline >= new Date().getTime()"
                    type="primary" class="manageStyle" @click="changeTeam(item, 'Switch Team')">Switch Team</el-button>
                  <el-button
                    v-if="item.entry_submission_deadline == '' || item.entry_submission_deadline >= new Date().getTime()"
                    type="primary" class="manageStyle" @click="manageProject(item)">Manage</el-button>
                  <el-button
                    v-if="item.entry_submission_deadline != '' && item.entry_submission_deadline < new Date().getTime()"
                    type="primary" class="manageStyle" @click="getPdf(item)">View</el-button>
                </div>
                <div v-if="item.application_team_code == ''">
                  <el-button v-if="item.entry_submission_deadline == '' || item.entry_submission_deadline >= new Date().getTime()" type="primary" class="manageStyle" @click="createTeam(item)">Create A Team</el-button> -->
                <!-- <el-button
                  v-if="item.entry_submission_deadline == '' || item.entry_submission_deadline >= new Date().getTime()"
                  type="primary" class="manageStyle" @click="changeTeam(item, 'Join My Team')">Join My
                  Team</el-button> -->
              </div>
            </div>

          </el-tab-pane>


          <el-tab-pane :label="'History Projects (' + historyList.length + ')'" name="third">

            <div class="projectList">

              <div v-for="(item, index) in historyList" :key="index"
                :class="item.application_team_code == '' ? 'itemStyle2' : 'itemStyle'">

                <div style="display: table"></div>

                <div :class="item.application_team_code == '' ? 'teamCodeStyle1' : 'teamCodeStyle'">
                  {{ item.application_team_code == '' ? 'Awaiting Team Confirmation' : ('#' +
                    item.application_team_code) }}
                </div>

                <div class="projectTitleStyle">
                  {{ item.event }}
                </div>

                <div class="leaderStyle">
                  Leader: {{ item.leaderName }}
                </div>

                <div class="memberStyle">
                  Members: {{ item.memberName }}
                </div>


                <!-- <div class="infoStyle">
                  Progress: {{item.progress}}  Word Count: {{item.word_count}}
                  </div> -->

                <div v-if="item.application_team_code != ''">

                  <div v-if="item.application_project == '2024NIM'">
                    <el-button
                      v-if="item.entry_submission_deadline == '' || item.entry_submission_deadline >= new Date().getTime()"
                      type="primary" class="manageStyle" @click="manageProject(item)">Manage</el-button>
                    <div v-if="checkShowCertView(item)">
                      <el-button type="primary" v-if="item.award" class="manageStyle2"
                        @click="gotoAwardView(item)">Certificate</el-button>
                      <el-button type="primary" v-else class="manageStyle3">Not Awarded</el-button>
                    </div>
                  </div>
                  <div
                    v-if="item.entry_submission_deadline != '' && item.entry_submission_deadline < new Date().getTime()">
                    <el-button type="primary" class="manageStyle" @click="getPdf(item)">View</el-button>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>





    </div>

    <el-dialog class="dialog" :title="teamChange.title" :visible.sync="teamChange.show" :close-on-click-modal="false"
      :show-close="false" width="80%" @open="openDialog" @close="closeDialog">


      <el-input placeholder="Please search by the email of the target team's leader" v-model="teamChange.teamSearchText"
        clearable>
        <template slot="append">
          <el-button icon="el-icon-search" @click="handleTeamSearch">Search</el-button>
        </template>
      </el-input>

      <el-table :data="teamChange.team_apps" style="width: 100%" empty-text="No Data">
        <el-table-column prop="student_info.student_name" label="Name" width="180"></el-table-column>
        <el-table-column prop="student_info.student_email" label="Email" width="180"></el-table-column>
        <el-table-column prop="application_team_role" label="Team Role">
          <template slot-scope="scope">
            {{ scope.row.application_team_role == '领队' ? 'Leader' : 'Member' }}
          </template>
        </el-table-column>
      </el-table>

      <el-label v-if="teamChange.teamSearchText != '' && teamChange.team_apps.length > 0">Please confirm if this is the
        team
        you want to join. If so, click "Join" to join the team.</el-label>
      <el-label v-if="teamChange.teamSearchText != '' && teamChange.team_apps.length == 0">No registration found for
        this
        email. Please check the email for any errors.</el-label>

      <div slot="footer" class="dialog-footer">
        <el-button @click="closeTeamChange">Cancel</el-button>
        <el-button  element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(255, 255, 255, 0.2)" type="primary" @click="submitTeamChange()">
          Confirm to Join</el-button>
      </div>
    </el-dialog>
  </article>
</template>
<script>
import {
  submitProject,
  updateNimApplicationTeamCode,
  getStudentNIMApplication,
  removeNimApplication,
  createNewTeamCode,
  searchTeamByLeaderEmail

} from "../service.js";

import Cookie from "js-cookie";

import Clipboard from "clipboard";

export default {
  data() {
    return {
      loginType: Cookie.get("loginType"),
      loginId: Cookie.get("loginId"),
      // project_code: Cookie.get("loginProject"),
      activeName: 'first',
      defevent: 0,

      projectLists: [],
      pendingList: [],
      historyList: [],
      showOngoing: false,
      teamChange: {
        show: false,
        title: 'Join A Team',
        app: {},
        teamSearchText: '',
        team_apps: []
      },

    };
  },
  created() {


  },
  mounted() {

    this.getAppByEvent()

  },
  methods: {

    gotoAwardView(application) {
      this.$router.push({
        path: '/cert',
        query: {
          id: application._id.$id
        }
      })
    },

    checkShowCertView(application) {
      let entry_date = application.projectManage.entry_date
      let announce_date = 0
      for (let i = 0; i < entry_date.length; i++) {
        let item = entry_date[i]
        announce_date = item.announce_date
      }
      let current_time = new Date().getTime()
      if (current_time >= announce_date) {
        return true
      }
      return false
    },

    formatPublishDate(application) {
      let submission_date = application.submission_date * 1000
      let entry_date = application.projectManage.entry_date
      let publish_date = 0
      let one_day = 24 * 60 * 60 * 1000 - 1
      for (let i = 0; i < entry_date.length; i++) {
        let item = entry_date[i]
        let start_date = item.start_date
        let ddl_date = item.ddl_date + one_day
        if (submission_date >= start_date && submission_date <= ddl_date) {
          publish_date = item.announce_date
        }
      }
      return this.convertTimeToEng(publish_date)
    },

    checkSubmitBtnStatus(application) {

      let entry_date = application.projectManage.entry_date
      let one_day = 24 * 60 * 60 * 1000 - 1
      let current_time = new Date().getTime()


      if (application.submission_date && application.submission_date != '') {//已经提交过了
        //查看是否是到了证书发布的时间
        let publish_date = 0
        let submission_date = application.submission_date * 1000
        for (let i = 0; i < entry_date.length; i++) {
          let item = entry_date[i]
          let start_date = item.start_date
          let ddl_date = item.ddl_date + one_day
          if (submission_date >= start_date && submission_date <= ddl_date) {
            publish_date = item.announce_date
          }
        }
        if (current_time >= publish_date) {
          return 'cert'
        }
        return 'disabled'
      }
      //没有提交，check时间
      for (let i = 0; i < entry_date.length; i++) {
        let item = entry_date[i]
        let start_date = item.start_date
        let ddl_date = item.ddl_date + one_day
        if (current_time >= start_date && current_time <= ddl_date) {
          return 'show'
        }
      }
      return 'no-show'
    },

    doSubmitProject(item) {
      this.$confirm("Are you sure you want to submit your team's project? Once submitted, no further edits or changes can be made. The content you've entered and the project video you've uploaded currently will be used for evaluation. If you're ready to submit, please click \"Confirm.\"", 'Submission Confirmation', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        showCancelButton: true,
        callback: action => {
          if (action == 'confirm') {
            submitProject(item._id.$id).then((res) => {
              this.$message.success(' Submission Successful')
              this.getAppByEvent()
            })
          }
        }
      })
    },


    createNimImgResource(project_code) {
      if (project_code == '2025NSI') {
        return require('../assets/nsi_logo.png')
      }
      if (project_code == '2025NRI') {
        return require('../assets/nri_logo.png')
      }
      if (project_code == '2025NIR') {
        return require('../assets/nir_logo.png')
      }
      if (project_code == '2025NIP') {
        return require('../assets/nip_logo.png')
      }
      if (project_code == '2025NIA') {
        return require('../assets/nia_logo.png')
      }
      if (project_code == '2025NIE') {
        return require('../assets/nie_logo.png')
      }
      if (project_code == '2025NII') {
        return require('../assets/nii_logo.png')
      }
      if (project_code == '2025NIV') {
        return require('../assets/niv_logo.png')
      }
      return ''
    },

    getAppByEvent() {

      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });


      getStudentNIMApplication(this.loginId, '', 'NIM').then((res) => {

        loading.close()

        if (res.data.code == 0) {

          this.pendingList = []
          this.projectLists = []
          this.historyList = []

          var allData = res.data.data

          for (let i = 0; i < allData.length; i++) {
            var app = allData[i]

            if (app.show_type == 'pending') {
              this.pendingList.push(app)
            }

            if (app.show_type == 'Ongoing') {
              console.log('111')
              if (app.application_team_code != '') {
                let submit_status = this.checkSubmitBtnStatus(app)
                app.submit_status = submit_status
              }
              this.projectLists.push(app)
            }

            if (app.show_type == 'History') {
              this.historyList.push(app)
            }


          }

          if (this.pendingList.length <= 0) {
            this.showOngoing = false
            this.activeName = 'second'

          } else {
            this.showOngoing = true
            this.activeName = 'first'
          }



        } else {
          this.$message.error(res.msg);
        }

      });






    },




    RemoveItem(item) {



      var info = 'Are you sure you want to withdraw your registration for ' + item.event + '?'

      this.$confirm(info, 'Cancel Registration', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Close',
        type: 'warning'
      }).then(() => {

        removeNimApplication(item._id.$id).then(res => {


          if (res.data.code === 0) {
            this.$message({
              message: 'Registration Cancelled Successfully.',
              type: 'success'
            })
            this.getAppByEvent()

          }
        })
      })

    },


    gotoPay(item) {
      this.$router.push({
        path: '/pay',
        query: {
          id: item._id.$id
        }
      })
    },



    manageProject(item) {

      if (item.application_status !== '已缴费' && item.application_status !== '无需缴费') {
        this.$message.error("You haven't paid yet")
        return
      }


      Cookie.set("projectTeamRole", item.application_team_role);

      if (item.application_events && item.application_events.length > 0) {
        Cookie.set("projectTeamEvent", item.application_events[0]);
      } else {
        Cookie.set("projectTeamEvent", item.application_project);
      }


      // mr 模版id  631169b9a4640b60318b4602
      // poe 模版id  63116846a4640b19318b4567
      // pie 模版id  63116951a4640b60318b4567

      // let pathTemplate_id = '631169b9a4640b60318b4602'

      // if (this.application.program==='PIE'){
      //   pathTemplate_id = '63116846a4640b19318b4567'

      // }else if (this.application.program==='POE'){
      //   pathTemplate_id = '63116951a4640b60318b4567'

      // }

      // if (this.from == 'student') {
      if (item.coordinator_id && item.coordinator_id != '') {
        const routeData = this.$router.resolve({ path: 'pghome', query: { template_id: item.template_id, project_id: item.project_id, application_team_code: item.application_team_code, accountid: item.coordinator_id } })
        window.open(routeData.href, '_blank')
      } else {
        const routeData = this.$router.resolve({ path: 'pghome', query: { template_id: item.template_id, project_id: item.project_id, application_team_code: item.application_team_code, accountid: item.account_id } })
        window.open(routeData.href, '_blank')

      }

    },

    getPdf(item) {

      this.$router.push({
        path: 'toPrint',
        query: {
          project_id: item.project_id,
          account_id: item.account_id,
          team_code: item.application_team_code,
          project_product: 'NIM',
          block_id: '',
          template_id: item.template_id
        }
      })
    },
    changeTeam(row, title) {
      this.teamChange.title = title;
      this.teamChange.app = row;
      this.teamChange.show = true;
      this.teamSearchText = '';
      this.team_apps = [];

    },
    closeTeamChange() {
      this.teamChange.show = false;
    },
    createTeam(row) {

      createNewTeamCode(row._id.$id).then((res) => {

        if (res.data.code == 0) {

          this.getAppByEvent();

        } else {
          this.$message.error(res.msg);
        }

      });

    },

    handleTeamSearch() {
      searchTeamByLeaderEmail(this.teamChange.teamSearchText, this.teamChange.app.application_project).then((res) => {

        if (res.data.code == 0) {

          this.teamChange.team_apps = res.data.data;

        } else {
          this.$message.error(res.msg);
        }

      });
    },
    submitTeamChange() {

      updateNimApplicationTeamCode(this.teamChange.app._id.$id, this.teamChange.team_apps[0].application_team_code).then(res => {
        if (res.data.code === 0) {
          this.$message({
            message: 'Joined Successfully.',
            type: 'success'
          })
          this.closeTeamChange();
          this.getAppByEvent();

        }
      })
    },


    convertTimeToEng(time) {
      var date = new Date(time);
      let year = date.getFullYear();
      let day = date.getDate();
      let month = date.getMonth() + 1;
      return this.formatMonthToEng(month) + " " + day + ", " + year;
    },

    formatMonthToEng(month) {
      switch (month) {
        case 1:
          return "January";
        case 2:
          return "February";
        case 3:
          return "March";
        case 4:
          return "April";
        case 5:
          return "May";
        case 6:
          return "June";
        case 7:
          return "July";
        case 8:
          return "August";
        case 9:
          return "September";
        case 10:
          return "October";
        case 11:
          return "November";
        case 12:
          return "December";
      }
    },

    closeDialog(){

    },
    openDialog(){

    }
  },
};
</script>
<style lang="scss" scoped>
::-webkit-scrollbar {
  display: none;
}

.newItem {
  margin-top: 15px;
  display: flex;
  align-items: center;
  padding: 5px 15px;
  box-sizing: border-box;
  width: 100%;
  position: relative;
  height: 160px;
  background-color: white;
  border: 1px solid #204386;
  border-radius: 5px;

  .manageStyle1 {
    text-align: center;
    background-color: #ffb414;
    border: none;
    color: #333;
  }

  .leaderStyle {
    color: #333;
    font-size: 18px;
  }

  .memberStyle {
    margin-top: 5px;
    color: #333;
    font-size: 18px;
  }

  .projectTitleStyle {
    flex: 1;
    padding-top: 5px;
    box-sizing: border-box;
    box-sizing: border-box;
    color: #333;
    font-size: 25px;
  }

  .infoStyle {
    font-size: 15px;
  }

  .teamCodeStyle {
    color: #333;
    font-size: 15px;
  }

  .teamCodeStyle1 {
    background-color: #204386;
    width: 185px;
    color: white;
    font-size: 15px;
  }
}

.newItem1 {
  background-color: white;
  border: 1px solid #ffb414;
}

article {

  background-color: rebeccapurple;
  // margin-left: 100px;
  // margin-top: 50px;
  background: #fff;
  // padding: 0 10px;

  .el-tabs_item.is-active {
    font-size: 14px;
    font-weight: 500;
    color: #204386;
  }

  .el-tabs_active-bar {
    background-color: #ffb414;
  }

  .el-tabs_item {
    font-size: 20px;
    font-weight: 500;
    color: aquamarine;
  }


  .homeContainer {
    height: calc(100vh - 58px);
    width: 100%;
    display: flex;
    overflow: hidden;
    background: #fff;
    font-family: "GravityBookItalic-zpra";

    .mainView {
      margin-left: 10%;
      margin-right: 10%;
      flex: 1;
      // width: 60%;
      height: 100%;
      background: white;
      overflow: hidden;
      position: relative;
    }

    .projectList {
      margin-top: 20px;
      // background-color: red;
      height: 70vh;
      width: 100%;
      overflow-y: auto;
      position: relative;

    }

    .itemStyle {
      // margin-left: 20px;
      // margin-right: 20px;
      margin-top: 15px;
      width: 100%;
      position: relative;
      height: 150px;
      background-color: oldlace;
      border-radius: 5px;
    }

    .itemStyle2 {
      // margin-left: 20px;
      // margin-right: 20px;
      margin-top: 15px;
      width: 100%;
      position: relative;
      height: 150px;
      background-color: #c3cce9;
      border-radius: 5px;
    }

  }



  .teamCodeStyle {
    padding-top: 10px;
    margin-left: 10px;
    color: #333;
    font-size: 15px;
  }

  .teamCodeStyle1 {
    background-color: #204386;
    width: 185px;
    // padding-top: 10px;
    position: relative;
    margin-top: 10px;
    margin-left: 10px;
    color: white;
    font-size: 15px;
  }


  .projectTitleStyle {
    margin-left: 10px;
    margin-top: 5px;
    color: #333;
    font-size: 25px;
  }

  .projectTitleStylePending {
    margin-left: 10px;
    margin-top: 38px;
    color: #333;
    font-size: 25px;
  }

  .leaderStyle {
    margin-left: 10px;
    margin-top: 25px;
    color: #333;
    font-size: 18px;
  }

  .memberStyle {
    margin-left: 10px;
    margin-top: 5px;
    color: #333;
    font-size: 18px;
  }

  .infoStyle {
    position: absolute;
    right: 10px;
    top: 15px;
    width: 80%;
    text-align: right;
    font-size: 15px;
  }

  .manageStyle2 {
    position: absolute;
    float: right;
    bottom: 15px;
    right: 120px;
    margin-right: 20px;
    // width: 80px;
    text-align: center;
    background-color: #ffb414;
    border: none;
    color: #333;
  }

  .manageStyle3 {
    position: absolute;
    float: right;
    bottom: 15px;
    right: 120px;
    margin-right: 20px;
    text-align: center;
    background-color: #F2F2F2;
    border: none;
    color: #666;
  }

  .manageStyle {
    position: absolute;
    float: right;
    bottom: 15px;
    right: 20px;
    margin-right: 20px;
    // width: 80px;
    text-align: center;
    background-color: #ffb414;
    border: none;
    color: #333;
  }

  .manageStylePending {
    position: absolute;
    bottom: 15px;
    right: 10px;
    width: 80px;
    text-align: center;
    background-color: #204386;
    border: none;
    color: white;
  }

  .removeStyle {
    position: absolute;
    bottom: 15px;
    right: 100px;
    width: 80px;
    text-align: center;
    background-color: #ffb414;
    border: none;
    color: #333;
  }

  .removeStylePending {
    position: absolute;
    bottom: 15px;
    right: 100px;
    width: 80px;
    text-align: center;
    background-color: #204386;
    border: none;
    color: white;
  }

  .eventLinkStyle {
    margin-left: 10px;
    margin-right: 10px;
    height: 40px;
    margin-top: 10px;
    width: calc(100% - 20px);
  }

  .eventName {
    color: #333;
    padding-right: 10px;
    // width: 230px;
    height: 30px;
    text-align: right;
    line-height: 30px;
    // font-size: 18px;
    background-color: oldlace;
    // float: left;

  }

  .linkStyle {
    height: 30px;
    padding-left: 10px;
    padding-right: 10px;
    line-height: 30px;
    background-color: #f5f5f5;

    overflow: hidden;
    /* 隐藏超出内容的区域 */
    white-space: nowrap;
    /* 不允许文本折行 */
    text-overflow: ellipsis;
    /* 显示省略号代替超出部分的文本 */
  }

  .shareBtn {
    line-height: 30px;
    width: 80px;
    height: 30px;
    text-align: center;
    background-color: #ffb414;

  }

}
</style>