<template>
    <article>

        <el-table v-loading="loadDialog" ref="table" :data="appTable" header-cell-class-name="adorn" size="medium"
            border class="left-table" :max-height="tableHeight">
            <el-table-column type="index" label='序号' align="center" width="50"> </el-table-column>
            <el-table-column label="项目名称" align="center" prop="project_name" width="420"></el-table-column>
            <el-table-column label="项目年份" align="center" prop="project_year"></el-table-column>

            <el-table-column label="项目平台" align="center" prop="project_platform"></el-table-column>
            <el-table-column label="项目周期" align="center" prop="project_period"></el-table-column>
            <el-table-column label="项目产品" align="center" prop="project_product"></el-table-column>
            <el-table-column label="项目类型" align="center" prop="project_type"></el-table-column>
            <el-table-column label="是否报名" align="center">
                <template slot-scope="scope">
                    <template v-if="scope.row.account_status">
                        已报名
                    </template>
                    <template v-if="!scope.row.account_status">
                        未报名 
                    </template>
                </template>

            </el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <template v-if="scope.row.account_status">
                        <el-button type="text" size="small" @click="chooseTeacher(scope.row)">选择老师</el-button>
                    </template>
                    <template v-if="!scope.row.account_status">
                        <el-button @click="signUpBtn(scope.row)" type="text" size="small">报名</el-button>
                    </template>
                </template>

            </el-table-column>

        </el-table>
        <el-dialog title="选择老师" :visible.sync="dialogVisible" width="40%">
            <el-checkbox-group v-model="checkList" @change="handleCheckedDatesChange">
                <el-checkbox v-for="(item,index) in teacherData" :label="item._id.$id" :key="index">
                    {{item.contact_lastName}}</el-checkbox>
            </el-checkbox-group>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="sureBtn">确 定</el-button>
            </span>
        </el-dialog>
    </article>
</template>
<script>
    import {
        getProjects,
        getContactsByAccount,
        updateAccountApplication,
        updateContactByAccount,
    } from "../service.js";

    import Cookie from "js-cookie";

    export default {
        data() {
            return {
                dialogVisible: false,
                checkList: [],
                teacherData: [],

                tableHeight: '10',

                loadDialog: false,
                teamForm: {
                    name: "测试",
                },
                formLabelWidth: "100px",

                appTable: [],

                loginId: Cookie.get("loginId"),
                loginType: Cookie.get("loginType"),
            };
        },
        created() {
            // if (this.loginType == '教师') {
            //     this.getMaterialList(); //获取学校报名表的列表
            // } else if (this.loginType == '学校管理员') {
            //     this.getAccountMaterialList();
            // }

        },
        mounted() {
            this.getProjects();
            this.getContactsByAccount();
            this.$nextTick(() => {
                this.tableHeight =
                    window.innerHeight - this.$refs.table.$el.offsetTop - 40;
            });

        },
        methods: {

            handleCheckedDatesChange() {
                console.log(this.checkList)
            },
            chooseTeacher(info) {
                this.dialogVisible = true;
                this.checkList = [];
            },
            sureBtn() {
                console.log(this.checkList)
                if (this.checkList.length ==0 ) {
                    this.$message.warning('请选择老师！')
                } else {
                    let param = {
                        contact_id: this.checkList
                    };
                    updateContactByAccount(param).then((res) => {
                        if (res.code == 0) {
                            this.$message.success('选择老师成功');
                            this.dialogVisible = false;
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
                };
              

            },

            //获取教师
            getContactsByAccount() {
                let param = {
                    account_id: Cookie.get("loginId"),
                };
                getContactsByAccount(param).then((res) => {

                    if (res.code == 0) {
                        this.teacherData = res.data;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            },
            //报名
            //$id = $paras['id']; //学校报名表id
            //$project_id = $paras['project_id']; //项目id
            //$account_id = $paras['account_id'];	//学校id
            //$mail_receiver = $paras['mail_receiver'];
            //$mail_receiver_phone = $paras['mail_receiver_phone'];
            //$status = $paras['status'];
            //$events_array = $paras['events_array'];
            //$contacts_array = $paras['contacts_array'];
            //$test_date = $paras['test_date'];
            //$test_time = $paras['test_time'];
            //$test_superviser = $paras['test_superviser'];
            //$isSendFundEmail = $paras['isSendFundEmail'];
            //$custom_price_status = $paras['custom_price_status'];
            //$custom_price = $paras['custom_price'];
            signUpBtn(info) {
                // this.dialogVisible = true;
                let param = {
                    id: "",
                    project_id: info._id.$id,
                    account_id: Cookie.get("loginId"),
                    mail_receiver: '',
                    mail_receiver_phone: '',
                    status: '',
                    events_array: [],
                    contacts_array: [],
                    test_date: '',
                    test_time: '',
                    test_superviser: '',
                    isSendFundEmail: '',
                    custom_price_status: '',
                    custom_price: '',
                };
                const loading = this.$loading({
                    lock: true,
                    text: '正在报名请稍后......',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                updateAccountApplication(param).then((res) => {
                    loading.close();

                    if (res.code == 0) {
                        this.getProjects();
                        this.$message.success('报名成功');
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            },

            //获取项目列表
            getProjects() {
                let param = {
                    account_id: Cookie.get("loginId"),
                };
                this.loadDialog = true;
                getProjects(param).then((res) => {
                    this.loadDialog = false;
                    if (res.code == 0) {
                        this.appTable = res.data;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            },






        },
    };
</script>
<style lang="scss" scoped>
    article {
        margin-top: 50px;
        background: #fff;
        padding: 10px;
        height: 90%;

        /deep/ .el-form.el-form--inline {
            background-color: #f8f8f8;
            margin-bottom: 10px;
            padding: 8px;
            overflow: hidden;
        }

        /deep/ .el-form.el-form--inline .el-form-item.el-form-item--medium {
            margin-right: 4px;
            margin-top: 5px;
            margin-bottom: 5px;
        }
    }

    .formP {
        margin: 0;
        padding: 0;

        padding-left: 10px;
        // border:1px solid #e9eef3;
        background: #f8f9fa;
        border-radius: 4px;
        width: 70%;
        position: relative;
    }
</style>