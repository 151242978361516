<template>
  <article>
    <el-table :data="applications"  header-cell-class-name="adorn" size="medium" border ref="leftTable" class="left-table">
            <el-table-column label="项目" prop="project_name" align="center"></el-table-column>
            <!-- <el-table-column label="状态" prop="application_status" align="center"></el-table-column> -->
            <!-- <el-table-column label="时间" align="center">
                <template slot-scope="scope">
                    {{formateDate(scope.row.create_time)}}
                </template>
            </el-table-column> -->
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-button class="btn-link" @click="viewItem(scope.row)" type="primary" size="mini">查看</el-button>
                </template>
            </el-table-column>
      </el-table>
  </article>
</template>
<script>
import { changePassword ,getStudentAppList} from "../service.js";

import Cookie from "js-cookie";
export default {
  data() {
    return {
      loadDialog: false,
      pswForm: {},
      formLabelWidth: "140px",
      studentid: Cookie.get("loginId"),
      
      applications:[]
      
    };
  },
  created() {
    this.getStudentApplications()
  },
  mounted() {},
  methods: {
    getStudentApplications(){
      getStudentAppList(Cookie.get("loginId")).then(res=>{
        console.log(123,res)
        this.applications = res.data
            
      })
    },
    viewItem(row){
          this.$router.push({
              path:'/thinkingQueList',
              query:{
                  accountCode:row.account_code,
                  station_id:row.station_id,
                  projectCode:row.application_project
              }
          })
      },
    changePassword(pswForm) {
      let _this = this;
      this.$refs[pswForm].validate((valid) => {
        if (valid) {
          _this.loadDialog = true;
          if (_this.pswForm.new_password != _this.pswForm.new_password_again) {
            this.$message.warning("两次密码输入不一致");
          } else {
            let param = {
              student_id: _this.studentid,
              initial_password: _this.pswForm.initial_password,
              new_password: _this.pswForm.new_password,
              new_password_again: _this.pswForm.new_password_again,
            };
            changePassword(param).then((res) => {
              this.loadDialog = false;
              if (res.code == 0) {
                _this.$message.success("密码更改成功，请重新登录");
                  Cookie.set("loginId",'');
                _this.$router.push({ name: "login" });
              } else {
                this.$message.error(res.msg);
              }
            });
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
article {
  margin-top: 50px;
  background: #fff;
  padding: 20px;
  // height: calc(100vh - 170px);
  box-sizing: border-box;
  /deep/ .el-form.el-form--inline {
    background-color: #f8f8f8;
    margin-bottom: 10px;
    padding: 8px;
    overflow: hidden;
  }
  /deep/ .el-form.el-form--inline .el-form-item.el-form-item--medium {
    margin-right: 4px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
.formP {
  margin: 0;
  padding: 0;

  padding-left: 10px;
  // border:1px solid #e9eef3;
  background: #f8f9fa;
  border-radius: 4px;
  width: 100%;
  position: relative;
}
.updateBtn {
  width: 150px;
  margin-left: 100px;
}
.partP {
  margin: 0;
  padding: 0;
  height: 40px;
  line-height: 40px;
  left: 21px;
  font-size: 16px;
  padding-left: 10px;
  position: relative;
  margin-bottom: 20px;
}
</style>