<template>
    <article>

        <el-table v-loading="dataLoading" ref="table" :data="appTable" header-cell-class-name="adorn" size="medium"
        border class="left-table" :max-height="tableHeight">
        <el-table-column type="index" label='序号' align="center" width="50"> </el-table-column>
        <el-table-column label="学生姓" align="center" prop="student_lastName" width="120"></el-table-column>
        <el-table-column label="学生名" align="center" prop="student_givenName" width="120"></el-table-column>
        <el-table-column label="性别" align="center" prop="student_gender" width="80"></el-table-column>
        <el-table-column label="学生第一监护人邮箱" align="center" prop="student_guardian_first_email"></el-table-column>
        <el-table-column label="学生第一监护人电话" align="center" prop="student_guardian_first_mobile"></el-table-column>
        <el-table-column label="学生电话" align="center" prop="student_mobile"></el-table-column>
        <el-table-column label="学生邮箱地址" align="center" prop="student_email"></el-table-column>
        <el-table-column label="毕业时间" align="center" prop="student_graduation"></el-table-column>
        
       
        <el-table-column label="操作" align="center" >
            <template slot-scope="scope">
                <el-button @click="lookBtn(scope.row)" type="text" size="small">查看参加项目</el-button>
            </template>
        </el-table-column>

    </el-table>
    <el-dialog title="查看项目信息" :visible.sync="dialogVisible" width="70%">
        <el-table v-loading="loadDialog" ref="projectTable" :data="projectTable" header-cell-class-name="adorn" size="medium"
        border class="left-table" >
        <el-table-column type="index" label='序号' width="50"> </el-table-column>
      
        <el-table-column label="报名项目名" align="center" prop="application_project" ></el-table-column>
        <el-table-column label="状态" align="center" prop="application_status" ></el-table-column>
        <el-table-column label="申请签证状态" align="center" prop="application_visa_status" ></el-table-column>
        <el-table-column label="创建时间" align="center" prop="application_status" >
            <template slot-scope="scope">{{
                scope.row.create_time | timeFormat
              }}</template>
        </el-table-column>
        
     

    </el-table>
    </el-dialog>
    </article>
</template>
<script>
    import {
        getStudentApplications,
        getStudentByAccount
    } from "../service.js";

    import Cookie from "js-cookie";
    export default {
        data() {
            return {
                loadDialog: false,
                dataLoading:false,
                teamForm: {
                    name: "测试",
                },
                projectTable:[],
                dialogVisible:false,
                tableHeight: '10',
                formLabelWidth: "100px",
                schoolForm: {},
                appTable: [],
                paginationLoading: false,
                loginId: Cookie.get("loginId"),
                loginType: Cookie.get("loginType"),
            };
        },
        created() {
            // if (this.loginType == '教师') {
            //     this.getMaterialList(); //获取学校报名表的列表
            // } else if (this.loginType == '学校管理员') {
            //     this.getAccountMaterialList();
            // }
        },
        mounted() {
            this.getStudentByAccount();
            this.$nextTick(() => {
                this.tableHeight =
                    window.innerHeight - this.$refs.table.$el.offsetTop - 40;
            });
        },
        methods: {
            lookBtn(info){
                this.dialogVisible = true;
                this.loadDialog = true;
                getStudentApplications({
                    student_id: info._id.$id
                }).then((res) => {
                    this.loadDialog = false;
                
                    if (res.code == 0) {
                        this.projectTable = res.data;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            },
           
            //获取学生列表
            getStudentByAccount() {
                this.dataLoading = true;
                getStudentByAccount({
                    account_id: Cookie.get("loginId")
                }).then((res) => {
                    this.dataLoading = false;
                    if (res.code == 0) {
                        this.appTable = res.data;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            },
            




        },
    };
</script>
<style lang="scss" scoped>
    article {
        margin-top: 50px;
        background: #fff;
        padding: 10px;

        /deep/ .el-form.el-form--inline {
            background-color: #f8f8f8;
            margin-bottom: 10px;
            padding: 8px;
            overflow: hidden;
        }

        /deep/ .el-form.el-form--inline .el-form-item.el-form-item--medium {
            margin-right: 4px;
            margin-top: 5px;
            margin-bottom: 5px;
        }
    }

    .formP {
        margin: 0;
        padding: 0;

        padding-left: 10px;
        // border:1px solid #e9eef3;
        background: #f8f9fa;
        border-radius: 4px;
        width: 70%;
        position: relative;
    }
</style>