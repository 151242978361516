<template>
    <article>
        <el-tabs
            class="elTabs"
            type="card"
            v-model="activeName">
            <el-tab-pane label="个人信息" name="first"></el-tab-pane>
            
        </el-tabs>
    </article>
</template>

<script>
export default {
    data(){
        return{
            activeName:'first',

        }
    },
    mounted(){

    },
    methods:{
        
    }
}
</script>

<style>

</style>