<template>
    <article>
        <el-table :data="projects" v-loading="dataLoading" header-cell-class-name="adorn" size="medium" border ref="leftTable" class="left-table">
            <el-table-column label="项目名称" align="center" prop="project_name"></el-table-column>
            <el-table-column label="项目代码" align="center" prop="project_code"></el-table-column>
            <el-table-column label="项目价格" align="center" prop="project_price"></el-table-column>
            <el-table-column label="监测点" align="center">
                <template slot-scope="scope">
                    {{scope.row.station.district+scope.row.point.name}}
                </template>
            </el-table-column>
            <el-table-column label="开始时间" align="center">
                <template slot-scope="scope">
                    {{formateDate(scope.row.start_time)}}
                </template>
            </el-table-column>
            <el-table-column label="结束时间" align="center">
                <template slot-scope="scope">
                    {{formateDate(scope.row.end_time)}}
                </template>
            </el-table-column>
            <el-table-column label="额度" align="center" prop="remain_count" width="100"></el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-button @click="toApply(scope.row)" type="primary" size="medium">去报名</el-button>
                </template>
            </el-table-column>
        </el-table>
    </article>
</template>

<script>
import {getProjects,applyEcoplore} from '../service'
import Cookie from "js-cookie";
export default {
    data(){
        return{
            projects:[],
            dataLoading:false
        }
    },
    mounted(){
        this.fetchData()
    },
    methods:{
        fetchData(){
            getProjects().then(res=>{
                if(res.data.code===0){
                    this.projects = res.data.data
                }
            })
        },
        formateDate(date){
            let time = new Date(date*1000)
            return `${time.getFullYear()}-${time.getMonth()+1}-${time.getDate()} ${time.getHours()}:${time.getMinutes()>9?time.getMinutes():'0'+time.getMinutes()}`;
        },
        toApply(row){
            this.$confirm('是否确认报名该项目?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(()=>{
                    applyEcoplore(row._id.$id,Cookie.get("loginId")).then(res=>{
                        if(res.data.code===0){
                            this.$message({
                                message:'报名成功',
                                type:'success'
                            })
                        }else{
                            this.$message.warning(res.data.msg)
                        }
                    })
                })
        }
    }
}
</script>

<style lang="scss" scoped>
  article {
    margin-top: 50px;
    background: #fff;
    padding: 10px;

    /deep/ .el-form.el-form--inline {
      background-color: #f8f8f8;
      margin-bottom: 10px;
      padding: 8px;
      overflow: hidden;
    }

    /deep/ .el-form.el-form--inline .el-form-item.el-form-item--medium {
      margin-right: 4px;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

  .formP {
    margin: 0;
    padding: 0;

    padding-left: 10px;
    // border:1px solid #e9eef3;
    background: #f8f9fa;
    border-radius: 4px;
    width: 70%;
    position: relative;
  }
</style>