<template>
    <div class="l-c">
        <div class="login-container">
            <div style="width:100%;height: 100%;position: absolute;z-index: -1;">
                <img src="../assets/loginBg.png" style="margin-left: -65%;height: 100%;"/>
            </div>
            <!-- <div class="tipStyle">At NIM, we celebrate the ingenuity and <br>
                achievements of student projects worldwide, <br>
                nurturing the leaders of tomorrow. <br><br>
                Log in to start your NIM journey with us.</div> -->


            <div style="width: 50%;height: 100%;position: absolute;left: 50%;background-color: #E0A91E;z-index: -1"></div>

            <div style="margin-top:150px;align-items: center;margin-left: 50%;">
                <div style="margin-left:calc((100% - 450px) /2);">
                    <img src="../assets/loginLogin.png" alt="" style="width:70px;" />
                </div>
                <el-form
                    :model="loginForm"
                    :rules="rules2"
                    ref="loginForm"
                    label-position="left"
                    label-width="160px"
                    status-icon
                    style="width:450px;margin:20px auto"
                    >
                    
                    <el-form-item prop="account" label="Username">
                        <el-input
                        type="text"
                        v-model="loginForm.account"
                        placeholder="Please enter your username"
                        ></el-input>
                    </el-form-item>

                    <el-form-item label="Login Type" prop="levelType">
                        <el-select
                        v-model="loginForm.levelType"
                        :placeholder="$t('m.pleaseChoose')"
                        >
                        <el-option
                            v-for="item in levelOption"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item prop="pass" v-if="loginForm.levelType !='1'" :label="$t('m.password')">
                        <el-input
                        v-model="loginForm.pass"
                        placeholder="Please enter your password"
                        type="password"
                        ></el-input>
                    </el-form-item>


                    <el-form-item prop="opt_code" v-if="loginForm.levelType =='1'" label="OTP Code">

                        <el-input v-model="loginForm.opt_code" auto-complete="off" placeholder="Please enter the code"
                            type="text" >
                            <el-button slot="suffix" class="send_code" @click="doSendLoginOpt">Send Code</el-button>
                        </el-input>
                    </el-form-item>

                    <el-form-item :label="$t('m.loginProject')" prop="levelProject" v-if="loginForm.levelType !='1' && loginForm.levelType !='2'">
                        <el-select
                        v-model="loginForm.levelProject"
                        :placeholder="$t('m.pleaseChoose')"
                        @change="changeLevel(value)"
                        no-data-text="No Data"
                        >
                        <el-option
                            v-for="item in projects"
                            :key="item.project_code"
                            :label="item.project_ename"
                            :value="item.project_code"
                        >
                        </el-option>
                        </el-select>
                    </el-form-item>

                    <span v-if="!!error_text" style="color: red" class="error_text">{{
                        error_text
                    }}</span>
                    <div style="margin-top:70px">
                        <el-button
                        style="width: 100%"
                        @click="submitLogin('loginForm')"
                        :loading="logining"
                        class="lastFormItem"
                        >{{ $t("m.login") }}</el-button
                        >
                    </div>
                    <!-- <p class="forgetPsw" @click="forgetPsw">{{ $t("m.forgetPsw") }}</p> -->
                </el-form>
            </div>
        </div>
        
    </div>
</template>

<script>
import {
  loginByStudent,
  loginByContact,
  loginByAdmin,
  getProjects,
  createLoginOPT
} from "../service.js";
import Cookie from "js-cookie";


export default {
    name: "Login",
    data() {
        return {
            langueOption: [
                {
                    value: "1",
                    label: "中文",
                },
                {
                    value: "2",
                    label: "英文",
                },
            ],
            flag: false,
            isChangeShow: true,
            langval: "1",
            error_text: "",
            logining: false,
            admin: false,
            loginForm: {
                account: "",
                pass: "",
                levelProject:"",
                opt_code: '',
                levelType:'1'
            },
            langoptions: [
                {
                    value: "1",
                    label: "中文",
                },
                {
                    value: "2",
                    label: "英文",
                },
            ],
            rules2: {
                opt_code: [{ required: true, message: 'Please enter your code', trigger: "blur" }],
                account: [{ required: true, message: 'Please enter your username', trigger: "blur" }],
                pass: [{ required: true, message: 'Please enter your password', trigger: "blur" }],
                levelType: [{ required: true, message: this.$t("m.loginTip2"), trigger: "blur" }],
                levelProject:[{ required: true, message: this.$t("m.loginTip3"), trigger: "blur" }],
            },
            levelOption: [
                {
                    value: "1",
                    label: "Student",
                },
                {
                    value: "2",
                    label: "Adviser",
                },
                {
                    value:"3",
                    label:"Coordinator"
                },
                {
                    value: "4",
                    label: "Admin",
                },
            ],
            projects : [],
            value: "",
        };
    },
    created() {
        console.log('测试自动部署')
        this.getProjects(); 
    },
    methods: {

        doSendLoginOpt(){
            if(this.loginForm.account ==''){
                this.$message.warning('Please input your username')
                return
            }
            createLoginOPT(this.loginForm.account).then((res)=>{
                let result = res.data
                if(result.code === 0){
                    this.$message.success('Sent Successfully');
                }else{
                    this.$message.error('Verification code sent failed. Please try again.')
                }
            })
        },

        forgetPsw() {
            //忘记密码
            this.$router.push({
                name: "forgetPsw",
            });
        },
        getProjects(){
            
            getProjects().then((res) => {
                this.projects = res.data.data;
                
            });

        },
        /**
         * 切换语言
         */
        changeLangs(val) {
            this.isChangeShow == true
                ? (this.isChangeShow = false)
                : (this.isChangeShow = true);

            this.$i18n.locale == "zh-CN"
                ? (this.$i18n.locale = "en-US")
                : (this.$i18n.locale = "zh-CN");

            // if (val == "2") {
            //   this.$i18n.locale = "en-US"; //英文
            // } else {
            //   this.$i18n.locale = "zh-CN"; //中文
            // }
        },
        change(value) {
            if (value === "管理员") {
                this.admin = true;
                // this.loginForm.account = 'admin'
                // this.loginForm.pass = 'yiquanTodo'
            } else {
                this.admin = false;
            }
        },
        changeLevel(val) {},
        submitLogin(loginForm) {
              this.loginForm.levelProject = '2024NIM'
            let _this = this;
            this.$refs[loginForm].validate((valid) => {
            let param = {};
            if (valid) {
                this.logining = true;
                Cookie.set("levelType", _this.loginForm.levelType);
                Cookie.set("loginProject", _this.loginForm.levelProject);

                switch (_this.loginForm.levelType) {
                    case "1": //学生
                    param.student_email = _this.loginForm.account;
                    param.student_pwd = _this.loginForm.opt_code;
                    param.project_code = ''//_this.loginForm.levelProject;
                    param.login_type = 'opt'
                    loginByStudent(param).then((res) => {
                        this.logining = false;
                        if (res.code == 0) {
                            console.log(res);
                            //登录成功
                            Cookie.set("loginType", "学生");
                            Cookie.set("loginId", res.data._id.$id);
                            Cookie.set("loginAccountId", res.data.account_id);

                            _this.$router.push({
                                name: "studentProjects",
                            });
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
                    break;
                    case "2": //教师
                  
                        param.contact_email = _this.loginForm.account;
                        param.contact_pwd = _this.loginForm.pass;
                        // param.project_code = _this.loginForm.levelProject
                        
                        loginByContact(param).then((res) => {
                            this.logining = false;
                            if (res.code == 0) {
                            //登录成功
                            Cookie.set("loginId", res.data._id.$id);
                            Cookie.set("loginAccountId", res.data.account_id);
                            Cookie.set("contact_type", "adviser");
                            Cookie.set("loginType", "教师");
                            _this.$router.push({
                                name: "currentProjects",
                            });
                            } else {
                            this.$message.error(res.msg);
                            }
                        });
                        break;
                    case "4": //学校管理员
                      param.account_email = _this.loginForm.account;
                      param.account_pwd = _this.loginForm.pass;
                      param.project_code = _this.loginForm.levelProject
                      console.log(param);

                        this.logining = false;

                    //   if(param.account_email =='admin' && 
                    //   param.account_pwd == 'yiquanTodo'){
                            
                    //     }else {
                    //         this.error_text = '用户或者密码错误~'
                    //     }

                      loginByAdmin(param).then((res) => {
                        this.logining = false;
                        if (res.code == 0) {
                          //登录成功
                          Cookie.set('user_name', '管理员')
                          Cookie.set('studentid', 'admin')
                          Cookie.set("loginId", 'admin');

                          Cookie.set("loginType", "admin");
                            Cookie.set('type', 'admin')
                            Cookie.set('project_id', res.data._id.$id)

                            _this.$router.push({
                                name: "teamCodeList",
                            });
                            // this.$router.push({ path: 'teamCodeList', query: { from : 'admin' }})

                        } else {
                          this.$message.error(res.msg);
                        }
                      });
                      break;
                    case "3": //指导员

                        param.contact_email = _this.loginForm.account;
                        param.contact_pwd = _this.loginForm.pass;
                        param.project_code = _this.loginForm.levelProject
                        
                        loginByContact(param).then((res) => {
                            this.logining = false;
                            if (res.code == 0) {
                            //登录成功
                            Cookie.set("loginId", res.data._id.$id);
                            Cookie.set("loginAccountId", res.data.account_id);
                            Cookie.set("contact_type", "adviser");
                            Cookie.set("loginType", "教师");
                            _this.$router.push({
                                name: "currentProjects",
                            });
                            } else {
                            this.$message.error(res.msg);
                            }
                        });
                        break;
                        // param.educator_email = _this.loginForm.account;
                        // param.educator_pwd = _this.loginForm.pass;
                        // console.log(param);
                        // loginByEducator(param).then((res) => {
                        //     this.logining = false;
                        //     if (res.code == 0) {
                        //         //登录成功
                        //         Cookie.set("loginType", "指导员");
                        //         Cookie.set("loginId", res.data._id.$id);
                        //         _this.$router.push({
                        //             name: "menuList",
                        //         });
                        //     } else {
                        //         this.$message.error(res.msg);
                        //     }
                        // });
                        // break;
                }
            } else {
                return false;
            }
        });
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.l-c {
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    display: flex;

  .login-container {
    width: 100%;
    
  }

}

.error_text {
  color: white;
  // margin-bottom: 10px;
}

/deep/ .el-form-item__error {
  color: white;
}


/deep/ .el-select {
  width: 100%;
}

.lastFormItem {
  position: relative;
  background:#204386;
  height: 46px;
  font-size: 18px;
  font-weight: bold;
  color: white;
}

.forgetPsw {
  /* position: absolute;
      bottom: -41px;
      right: 0; */
  text-align: right;
  font-size: 10px;
  color: rgb(47,173,55);
  margin-top: 8px;
  cursor: pointer;
}
/deep/ .el-button {
    border:none;
}
.el-form-item {
    background: #f8f9fd;
    padding-left: 18px;
    
    border-radius: 6px;
    margin-bottom: 30px !important;
}
/deep/.el-form-item__label {
  color: #654900;
  font-size: 16px;
  font-weight: bold;
  font-family: 'Microsoft YaHei';
}
/deep/.el-input .el-input__inner {
  background: #f8f9fd !important;
  border: none;
}
.footer {
  position: absolute;
  bottom: 24px;
  left: 37px;
  width: 60%;
  font-size: 10px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #204386;
  p {
    margin-top: 10px;
  }
}
.collegeName {
  margin-left: 20px;
}
.special {
  color: #da3514;
}
.logoTxt{
    color: #204386;
    font-weight: bold;
    font-size: 28px;
    text-align: center;
    font-family: Microsoft Yahei;
}
.el-select-dropdown__item.selected {
    color: #E0A91E;
    font-weight: 700;
}
.tipStyle{
    position: absolute;
    padding: 10px;
    width: 550px;
    height: 160px;
    // line-height: 170px;
    text-align: center;
    bottom: 120px;
    left: 5%;
    font-size: 25px;
    color: white;
    background-color: rgba($color: #204386, $alpha: 0.7);
    word-wrap: break-word;
    white-space:normal; 
    
    // word-break:break-all;
}
</style>