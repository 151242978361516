<template>
  <article>
      <el-table :data="packageApplications" v-loading="dataLoading" header-cell-class-name="adorn" size="medium" border ref="leftTable" class="left-table"
      empty-text="No Data">
            <el-table-column label="Project" prop="application_project" align="center"></el-table-column>
            <el-table-column label="Price" align="center">
              <template slot-scope="scope">
                {{   scope.row.project_info.project_price_usd <= 0 ? 'Free' : (scope.row.project_info.project_price_usd +'('+ scope.row.project_info.project_price_currency + ')')}}
              </template>
            </el-table-column>
            <el-table-column label="Status" prop="application_status_show" align="center"></el-table-column>
            <el-table-column label="Time" align="center">
                <template slot-scope="scope">
                    {{formateDate(scope.row.create_time)}}
                </template>
            </el-table-column>
            <el-table-column label="Action" align="center">
                <template slot-scope="scope">
                    <el-button class="btn-link" @click="viewItem(scope.row)" type="primary" size="mini">View details</el-button>
                </template>
            </el-table-column>
      </el-table>
    <!-- <el-table :data="teamTable" v-loading="dataLoading" header-cell-class-name="adorn" size="medium" border ref="leftTable" class="left-table" style="margin-top:20px">
      <el-table-column label="项目" align="center" prop="application_project">
      </el-table-column>
      <el-table-column label="提交渠道" align="center" prop="application_channel">
      </el-table-column>
      <el-table-column label="测试点" align="center">
          <template slot-scope="scope">
              {{scope.row.station.di_city+scope.row.station.name}}
          </template>
      </el-table-column>
      <el-table-column label="开始时间" align="center">
        <template slot-scope="scope">
            {{formateDate(scope.row.project.start_time)}}
        </template>
      </el-table-column>
      <el-table-column label="结束时间" align="center">
        <template slot-scope="scope">
            {{formateDate(scope.row.project.end_time)}}
        </template>
      </el-table-column>
      <el-table-column label="状态" align="center" prop="application_status">
      </el-table-column>
      <el-table-column width="300" label="操作" align="center">
        <template slot-scope="scope">
          <el-button class="btn-link" @click="editItem(scope.row)" type="primary" size="mini">详情</el-button>
        </template>
      </el-table-column>
    </el-table> -->
    <el-dialog class="dialog" :title="dialogInfo.title" :visible.sync="dialogInfo.show" :close-on-click-modal="false"
      width="60%">
      <el-table v-loading="loadDialog" :data="dialogTable" header-cell-class-name="adorn" size="medium" border
        ref="leftTable" class="left-table">
        <el-table-column label="项目名称" align="center" prop="project_name"></el-table-column>
        <el-table-column label="项目平台" align="center" prop="project_platform"></el-table-column>
        <el-table-column label="项目类型" align="center" prop="project_type">
        </el-table-column>
        <el-table-column label="项目年份" align="center" prop="project_year">
        </el-table-column>
        <el-table-column label="操作" align="center" width="120">
          <template slot-scope="scope">
            <el-button class="btn-link" @click="signUp(scope.row)" type="primary" size="mini">报名
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

  </article>
</template>
<script>
  import {
    getStudentApplications, 
    getNextProject,
    addNewProjectApplication,
    getStudentPackages
  } from "../service.js";

  import Cookie from "js-cookie";
  export default {
    data() {
      return {
        loadDialog: false,
        dialogInfo: {
          title: "报名",
          show: false,
        },
        dialogTable: [],
        teamTable: [],
        account_id: Cookie.get("studentid"),
        dataLoading: false,
        packageApplications:[]
      };
    },
    created() {
      // this.getStudentApplications()
      this.getStudentPackages()
    },
    mounted() {},
    methods: {
      signUp(info) {
        addNewProjectApplication({
          project_code: info.project_code,
          student_id: Cookie.get("loginId"),
          events: info.project_events
        }).then((res) => {

          if (res.code == 0) {
            this.$message.success('该项目已报名成功！')
            this.dialogInfo.show = false
          } else {
            this.$message.error(res.msg);
          }
        });
      },
       //获取下一项目
       lookNext(info) {
                this.dialogInfo.show = true;
                this.loadDialog =true;
                getNextProject({
                    project_code: info.application_project,
                }).then((res) => {
                    this.loadDialog =false;
                    if (res.code == 0) {
                        this.dialogTable = res.data;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            },
      editItem(row) {
        this.$router.push({
          path: "/teamDetail",
          query: {
            'project_code': row.application_project
          }
        });
      },
      viewItem(row){
          this.$router.push({
              path:'/packageInfo',
              query:{
                  package_application_id:row._id.$id
              }
          })
      },
      searchHandle() {},
      getStudentApplications() {
        this.dataLoading = true;
        getStudentApplications({
          student_id: Cookie.get("loginId")
        }).then((res) => {
          this.dataLoading = false;
          
          if (res.code == 0) {
            this.teamTable = res.data;
          } else {
            this.$message.error(res.data.msg);
          }
        });
      },
      getStudentPackages(){
          getStudentPackages(Cookie.get("loginId") , Cookie.get("loginProject")).then(res=>{
              if(res.data.code===0){
                  this.packageApplications = res.data.data
              }
          })
      },
      formateDate(date){
            let time = new Date(date*1000)
            return `${time.getFullYear()}-${time.getMonth()+1}-${time.getDate()} ${time.getHours()}:${time.getMinutes()>9?time.getMinutes():'0'+time.getMinutes()}`;
        }
    },
  };
</script>
<style lang="scss" scoped>
  article {
    margin-top: 50px;
    background: #fff;
    padding: 10px;

    /deep/ .el-form.el-form--inline {
      background-color: #f8f8f8;
      margin-bottom: 10px;
      padding: 8px;
      overflow: hidden;
    }

    /deep/ .el-form.el-form--inline .el-form-item.el-form-item--medium {
      margin-right: 4px;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }


  .btn-link{
    background:#E0A91E;
  }
  /deep/ .el-button {
    border:none;
  }
  .formP {
    margin: 0;
    padding: 0;

    padding-left: 10px;
    // border:1px solid #e9eef3;
    background: #f8f9fa;
    border-radius: 4px;
    width: 70%;
    position: relative;
  }
</style>