import * as mutationType from './mutation-types';
export default{
    [mutationType.USER_INFO](state,value){
        state.userInfo = value
        Cookie.set('user_name', (value.student_lastName || value.contact_lastName || value.educator_last_name) + (value.student_givenName || value.contact_givenName || value.educator_first_name)) // 根据类型来设置姓名
    },
   
    [mutationType.MENU_ROUTER_INFO](state,data){
        state.menuRouterInfo  = data
    },
    [mutationType.LANGUAGE_TYPE](state,data){
        state.languageType  = data
    },
}
