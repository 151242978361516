<template>
  <div class="quizContainer">
      <div v-for="(i, index) in quizInfo" :key="i.content_name" style="margin-right: 10px;" :class="(index+1) === nowIndx ? 'choosed': ''">
        <el-button  :class="['Btn',i.complete_status == 'yes'? 'redBtn':'']" circle  @click="changeIndex($event,index)" >{{index+1}}</el-button>
    
      </div>
   
  </div>
</template>

<script>

export default {
  name: 'NavigatorQuizs',
  props: {
    nowIndx: Number,
    quizInfo: Array,
    onChangeIndx: Function
  },
  mounted(){
    console.log(this.nowIndx,this.quizInfo)
  },
  watch: {
 
  },
  data() {
    return {
 
    }
  },
  methods: {
    changeIndex(e,index){
      this.onChangeIndx(index)
      this.clickHandler(e)
    },
    clickHandler(evt) {
        let target = evt.target;
        if(target.nodeName == "SPAN"){
            target = evt.target.parentNode;
        }
        target.blur();
    }

   
  },
  computed:{
    name(){
      return this.$store.state.user_info.student_lastName + this.$store.state.user_info.student_givenName
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.quizContainer{
    .choosed{
        margin-right: 10px;
        padding-bottom: 4px;

    }
    .quiz{
        padding: 20px;
    }
    .choose{
        margin-top:30px;
        display: block;
    }
    .answer{
        margin-top: 30px;
        display: inline-block;
    }
    .question-button{
        right: 20px;
        margin-top: 20px;
    }
    .el-radio+.el-radio {
        margin-left: 0;
    }
    .redBtn{
        background: rgb(160,14,21);
        color: white!important;
    }
    .Btn{
        color: rgb(160,14,21);
        border: 1px solid rgb(160,14,21);
    }
    .Btn:hover{
        background: rgb(160,14,21);
        color: white!important;
    }
    .el-button.is-circle {
        border-radius: 50%;
        height: 20px;
        width: 20px;
        padding: 00px;
        font-size: 13px;
        line-height: 1px;
    }
}
</style>

