<template>
    <article>
      <el-collapse v-model="activeNames" @change="handleChange">
    
        <el-collapse-item title="英国方向" name="2">
          <ul class="minorList">
  
            <li><a href="http://mainbucket.reachable-edu.com/nana/20-21/%E7%94%9F%E7%89%A9%20%E8%8B%B1%E5%9B%BD.pdf"
                target="_blank">Biology - Pro. Guy Woodward - Imperial College London</a></li>
            <li><a href="http://mainbucket.reachable-edu.com/nana/20-21/%E6%95%B0%E5%AD%A6%20%E8%8B%B1%E5%9B%BD.pdf"
                target="_blank">Mathematics - Pro. P. McMullen - University College London</a></li>
            <li><a href="http://mainbucket.reachable-edu.com/nana/20-21/%E5%8C%96%E5%AD%A6%20%E8%8B%B1%E5%9B%BD.pdf"
                target="_blank">Chemistry - Pro. P. P. Edwards - University of Oxford</a></li>
            <li><a href="http://mainbucket.reachable-edu.com/nana/20-21/%E7%89%A9%E7%90%86%20%E8%8B%B1%E5%9B%BD.pdf"
                target="_blank">Physics - Pro. Neil Mathur - University of Cambridge</a></li>
            <li><a
                href="http://mainbucket.reachable-edu.com/nana/20-21/%E6%9C%BA%E6%A2%B0%E5%B7%A5%E7%A8%8B%20%E8%8B%B1%E5%9B%BD.pdf"
                target="_blank">Engineering - Pro. Gary R. Hunt - University of Cambridge</a></li>
            <li><a
                href="http://mainbucket.reachable-edu.com/nana/20-21/%E8%AE%A1%E7%AE%97%E6%9C%BA%20%E8%8B%B1%E5%9B%BD.pdf"
                target="_blank">Computer Science - Pro. Marta E. Cecchinato - University College London </a></li>
            <li><a href="http://mainbucket.reachable-edu.com/nana/20-21/%E5%BB%BA%E7%AD%91%20%E8%8B%B1%E5%9B%BD.pdf"
                target="_blank">Architecture - Pro. James O’Leary, Kristen Kreider - University College London</a></li>
            <li><a href="http://mainbucket.reachable-edu.com/nana/20-21/%E6%96%87%E5%AD%A6%20%E8%8B%B1%E5%9B%BD.pdf"
                target="_blank">Literature - Pro. Jonathan Bate - University of Oxford</a></li>
            <li><a href="http://mainbucket.reachable-edu.com/nana/20-21/%E4%BC%A0%E5%AA%92%20%E8%8B%B1%E5%9B%BD.pdf"
                target="_blank">Media - Pro. Lee Grieveson - University College London </a></li>
            <li><a href="http://mainbucket.reachable-edu.com/nana/20-21/%E5%93%B2%E5%AD%A6%20%E8%8B%B1%E5%9B%BD.pdf"
                target="_blank">Philosophy - Pro. Ursula Coope - University of Oxford</a></li>
            <li><a href="http://mainbucket.reachable-edu.com/nana/20-21/%E5%8E%86%E5%8F%B2%20%E8%8B%B1%E5%9B%BD.pdf"
                target="_blank">History - Pro. Eugenio F. Biagini - University of Cambridge</a></li>
            <li><a href="http://mainbucket.reachable-edu.com/nana/20-21/%E5%BF%83%E7%90%86%20%E8%8B%B1%E5%9B%BD.pdf"
                target="_blank">Psychology - Pro. Miles Hewstone - University of Oxford</a></li>
            <li><a
                href="http://mainbucket.reachable-edu.com/nana/20-21/%E6%95%99%E8%82%B2%E5%AD%A6%20%E8%8B%B1%E5%9B%BD.pdf"
                target="_blank">Education - Pro. Lars-Erik Malmberg - University of Oxford</a></li>
            <li><a href="http://mainbucket.reachable-edu.com/nana/20-21/%E7%BB%8F%E6%B5%8E%20%E8%8B%B1%E5%9B%BD.pdf"
                target="_blank">Economics - Pro. Camille Landais - London School of Economics and Political Science</a>
            </li>
            <li><a href="http://mainbucket.reachable-edu.com/nana/20-21/%E9%87%91%E8%9E%8D%20%E8%8B%B1%E5%9B%BD.pdf"
                target="_blank">Finance - Pro. Alan D. Morrison - University of Oxford</a></li>
          </ul>
        </el-collapse-item>
      </el-collapse>
    </article>
  </template>
  
  
  
  <script>
    import {
      provinceAndCityData,
      CodeToText
    } from "element-china-area-data";
    import Cookie from "js-cookie";
    export default {
      data() {
        return {
          activeNames: ["1", "2"],
          dataLoading: false,
          formLabelWidth: "120px",
          isShowLogOut: false,
        };
      },
  
      computed: {
  
      },
      created() {
  
  
      },
      methods: {
        handleChange() {
  
        }
  
      },
    };
  </script>
  <style lang="scss" scoped>
    article {
      padding-top: 50px;
    }
  
    .el-cascader {
      width: 100%;
    }
  
    .el-select {
      width: 100%;
    }
  
    .btnWidth {
      width: 200px;
    }
  
    .minorList {
      li {
        text-align: left;
      }
    }
  
    /deep/ .el-collapse-item__header {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      height: 48px;
      line-height: 48px;
      background-color: #409EFF;
      color: #303133;
      cursor: pointer;
      border-bottom: 1px solid #EBEEF5;
      font-size: 13px;
      font-weight: 500;
      -webkit-transition: border-bottom-color .3s;
      transition: border-bottom-color .3s;
      outline: 0;
      padding-left: 20px;
      color: #fff;
      font-size: 15px;
    }
  </style>