<template>
  <article>
    <el-container>
      <el-header>
        <div class="topBgStyle">
          <div class="newLogoStyle">

            <img
            class="img"
            src="../assets/newLogo.png"
            alt="" />

          </div>
          
        </div>
        <!-- <div class="logoImg headerList">
          <div class="logoImg1">
            <img
            src="../assets/newLogo.png"
            alt="" />
          </div>
          
        </div> -->
        <div class="headerList" :class="item.value == activeType ? 'activeColor' : ''"
          v-for="(item, index) in headerList" :key="index">
          <p @click="goManageView(item)">{{ item.name }}</p>
          
        </div>


        <div v-if="loginType == '学生'" class="goMessage" @click="goMessage()">
          Messages
          <!-- <img class="outImg" src="../assets/out.png" alt="" srcset="" /> -->
        </div>

        <div class="goBack" @click="goBack()">
          {{$t("m.logOut")}}
          <!-- <img class="outImg" src="../assets/out.png" alt="" srcset="" /> -->
        </div>
      </el-header>
<!-- 
      <el-header class="menuColor" v-show="menuRouterInfo.length > 0">
        <el-menu style="width: 10%;height: 50px;" v-model="def_item" :default-active="activeIndex" active-text-color="#ffd04b" mode="vertical">


          <el-menu-item style="background-color: aqua;width: 100%;" v-for="(sub, num) in menuRouterInfo" :key="num" @click="goView(sub.name,sub.query)" :index="num">
              <span class="subTitle">{{ sub.title }}</span>
              <span class="SubengTitle">{{ sub.engTitle }}</span>
            </el-menu-item>

       
        </el-menu>  
      </el-header> -->


      <!-- <el-container> -->
        
        <!-- 主页内容 -->
        <el-container>
          <el-main style="padding: 0;">
                        
            <router-view ></router-view>
          </el-main>
        </el-container>
      <!-- </el-container> -->

      <!-- <el-footer>Footer</el-footer> -->

    </el-container>
  </article>
</template>

<script>
  import Cookie from "js-cookie";
  import {
    // getNotice,
    getProjectDetail
  } from "../service.js";
  import {
    mapGetters,
    mapActions,
    mapState
  } from "vuex";
  export default {
    name: "landingPag",
    data() {
      return {
        activeType: "0",
        title: "",
        levelType: "",
        firstIn: "",
        isJump: true,
        isclickLanguage: false,
        headerList: [{
          name: "",
          value: "0",
        }, ],
        loginId: Cookie.get("loginId"),
        loginType: Cookie.get("loginType"),
        project_conversion_open:'NO'
      };
    },
    computed: {
      ...mapGetters(["menuRouterInfo"]),
      matchedArr() {
        let temp = [],
          temps = [];
        console.log(this.$i18n.locale)
        let menuName =
          this.menuRouterInfo.length > 0 ? this.menuRouterInfo[0].title : "";

        this.$route.matched.filter((item, index, self) => {
          if (item.meta.title) {
            if (this.$i18n.locale == "zh-CN") {
              const title = item.meta.title;
              temp.push(title);
              this.menuRouterInfo.forEach((f) => {
                if (f.title == item.name) {
                  temps.push(f.title);
                }
              });

            } else if (this.$i18n.locale == "en-US") {
              const title = item.meta.engTitle;
              temp.push(title);
              this.menuRouterInfo.forEach((f) => {
                if (f.title == item.name) {
                  temps.push(f.title);
                }
              });
            }

          }
        });

        temp.filter((item, index, self) => {
          if (!temps.includes(item)) {
            temps.push(item);
          }
        });

        return temps;

      },
    },
    mounted() {},
    created() {
      this.initMenuTitle();
      // this.getNotice();

      // this.getProjectDetail();
    },
    methods: {
      ...mapActions(["MENU_ROUTER_INFO"]),
      ...mapActions(["LANGUAGE_TYPE"]),
     
      // getNotice() {

      //   getNotice({
      //     BU: 'WEC'
      //   }).then((res) => {
         
      //     if(res.code ==0){
      //       this.firstIn = res.data;
      //     }
       

      //   });
      // },

      getProjectDetail(){

        getProjectDetail(Cookie.get("loginProject") ,Cookie.get("loginId") ).then((res) => {
         

          this.project_conversion_open = res.data.data.project_conversion_open_jinji

          console.log(1231,res)
       

        });

        
      },

      initMenuTitle() {
        this.levelType = Cookie.get("levelType");
        switch (this.levelType) {
          case "1": //Student

            this.headerList = [{
                name: 'My Projects',
                value: "0",
              },
              // {
              //   name: this.$t('m.resourceCenter'),
              //   value: "1",
              // },
              {
                name: 'Project Plaza',
                value: "1",
              },
              //   {
              //   name: this.$t('m.projectRegistration'),
              //   value: "5",
              // },
              {
                name: 'Account Info',
                value: "2",
              },

            //   {
            //     name: this.$t('m.languageInfo'),
            //     value: "4",
            //   },
            ];
            break;
          case "2": //adviser
           
            this.headerList = [{
                name: 'Current Projects',
                value: "0",
                path: ''
              },
              {
                name: 'History Projects',
                value: "1",
              },
              {
                name: 'Account Info',
                value: "2",
              },
              // {
              //   name: this.$t('m.registrationChengji'),
              //   value: "9",
              // },
            //   {
            //     name: this.$t('m.teacherTraining'),
            //     value: "5",
            //   },
            //   {
            //     name: this.$t('m.resourceCenter'),
            //     value: "2",
            //   },
            //   {
            //     name: this.$t('m.messages'),
            //     value: "3",
            //   },
            //   {
            //     name: this.$t('m.languageInfo'),
            //     value: "4",
            //   },
            ];
            break;
          case "3": //管理员
           
            this.headerList = [{

              name: this.$t('m.schoolResourceCenter'),
                value: "0",
              },
              {
                name: this.$t('m.registrationInfo'),
                value: "1",
              },
              // {
              //   name: this.$t('m.registrationChengji'),
              //   value: "9",
              // },
              //   name: this.$t('m.schoolResourceCenter'),
              //   value: "0",
              // },
              // {
              //   name: this.$t('m.registrationInfo'),
              //   value: "1",
              // }
            ];
            break;
        }
      },
      /**
       * 切换语言
       */
      changeLangs(item, val) {
        this.isclickLanguage = false;
        if (val == "2") {
          item.name = '英文'
          this.$i18n.locale = "en-US"; //英文
        } else {
          item.name = '中文'
          this.$i18n.locale = "zh-CN"; //中文
        }
        this.LANGUAGE_TYPE(val);
        this.initMenuTitle();
      },
      goView(viewName,query) {
        this.isJump = true;
        this.$router.push({
          name: viewName,
          query:query
        });
      },
      goManageView(type) {
        console.log(123,type);
        this.isJump = false;
        this.activeType = type.value;

        if (this.levelType == 1) {

          if (type.value == 0){

            this.$router.push({
              name: "studentProjects"
            });

          }

          if (type.value == 1){

            this.$router.push({
              name: "projectPlaza"
            });

          }
          if (type.value == 2){

            this.$router.push({
              name: "accountInfo"
            });

          }

          

        } else if (this.levelType == 2 || this.levelType == 3) {

          if (type.value == 0){

            this.$router.push({
              name: "currentProjects"
            });
          }


          if (type.value == 1){
            this.$router.push({
              name: "historyPeojects"
            });
          }

          if (type.value == 2){

            this.$router.push({
              name: "accountInfo"
            });

          }

        }


        // this.MENU_ROUTER_INFO(this.menuListInfo);
        // console.log(type.value);
        // this.$router.push({
        //   name: "menuList"
        // });

        return

        if (type.value == 4) { //切换语言
          this.isclickLanguage = true;
        } else {
          this.isJump = false;
          //进入不同模块
          let viewInfo = {};
          this.activeType = type.value;
          console.log(this.activeType);
          if (this.levelType == 1) {
            //学生
            switch (type.value) {
              case "0":
                this.menuListInfo = [];
                break;
              case "1": //学习资料
                this.menuListInfo = [{
                    name: "learningMaterialsUSA",
                    path: "/learningMaterialsUSA",
                    redirect: "learningMaterialsUSA",
                    title: "Reference material",
                    engTitle: "",
                  },
                  // {
                  //   name: "learningMaterialsUK",
                  //   path: "/learningMaterialsUK",
                  //   redirect: "learningMaterialsUK",
                  //   title: "英国方向",
                  //   engTitle: "Learning Materials UK",
                  // },
                ];
                break;
              case "2": //注册信息
                this.menuListInfo = [{
                    name: "personalInfoEdit",
                    path: "/personalInfoEdit",
                    redirect: "personalInfoEdit",
                    title: "Personal Info",
                    engTitle: "",
                    query:{
                        student_id:this.loginId
                    }
                  },
                //   {
                //     name: "projects",
                //     path: "/projects",
                //     redirect: "projects",
                //     title: "可报项目",
                //     engTitle: "Projects",
                //   },
                  {
                    name: "teamInfo",
                    path: "/teamInfo",
                    redirect: "teamInfo",
                    title: "Team Info",
                    engTitle: "",
                  },
                  {
                    name: "viewAward",
                    path: "/viewAward",
                    redirect: "viewAward",
                    title: "View Results",
                    engTitle: "",
                  },
                  {
                    name: "changePassword",
                    path: "/changePassword",
                    redirect: "changePassword",
                    title: "Change Password",
                    engTitle: "",
                  },

                ];
                break;
              case "3": //消息
                this.menuListInfo = [{
                    name: "checkMessage",
                    path: "/checkMessage",
                    redirect: "checkMessage",
                    title: "View Messages",
                    engTitle: "",
                  },
                  {
                    name: "messageContent",
                    path: "/messageContent",
                    redirect: "messageContent",
                    title: "New Messages",
                    engTitle: "",
                  },
                ];
                break;
                // case "5": //项目报名
                // this.menuListInfo = [{
                //     name: "projectRegistration",
                //     path: "/projectRegistration",
                //     redirect: "projectRegistration",
                //     title: "项目报名",
                //     engTitle: "Project Registration",
                //   },

                // ];
                // break;

            }


            if (type.value == '2' && this.project_conversion_open == 'YES'){

              this.menuListInfo.push({
                    name: "appConversion",
                    path: "/appConversion",
                    redirect: "appConversion",
                    title: "Registration for the next round",
                    engTitle: "",
              })

            }

          } else if (this.levelType == 2 || this.levelType == 3) {
            //老师
            switch (type.value) {
              case "0":
                this.menuListInfo = [];
                break;
              case "1": //报名信息
                this.menuListInfo = [{
                    name: "schoolInfo",
                    path: "/schoolInfo",
                    redirect: "schoolInfo",
                    title: "School Info",
                    engTitle: "",
                  },
                  {
                    name: "applicationInfo",
                    path: "/applicationInfo",
                    redirect: "applicationInfo",
                    title: "Application Info",
                    engTitle: "",
                  },
                ];
                if (Cookie.get("contact_type") == "adviser") {
                  let newArr = [
                    //   {
                    //   name: "studentQestion",
                    //   path: "/studentQestion",
                    //   redirect: "studentQestion",
                    //   title: "学生答疑",
                    //   engTitle: "Student Questions",
                    // },
                    // {
                    //   name: "sourceList",
                    //   path: "/sourceList",
                    //   redirect: "sourceList",
                    //   title: "物料列表",
                    //   engTitle: "Package Delivery",
                    // },
                  ];
                  this.menuListInfo = [...this.menuListInfo, ...newArr];
                }
                break;
              case "2": //参考资料
                this.menuListInfo = [{
                    name: "learningMaterialsUSA",
                    path: "/learningMaterialsUSA",
                    redirect: "learningMaterialsUSA",
                    title: "Reference material",
                    engTitle: "",
                  },
                  // {
                  //   name: "learningMaterialsUK",
                  //   path: "/learningMaterialsUK",
                  //   redirect: "learningMaterialsUK",
                  //   title: "英国方向",
                  //   engTitle: "Learning Materials UK",
                  // },
                ];
                break;
              case "3": //消息
                this.menuListInfo = [{
                    name: "checkMessage",
                    path: "/checkMessage",
                    redirect: "checkMessage",
                    title: "View Messages",
                    engTitle: "",
                  },
                  {
                    name: "messageContent",
                    path: "/messageContent",
                    redirect: "messageContent",
                    title: "New Messages",
                    engTitle: "",
                  },
                ];
                break;
              case "5": //教师培训
                this.menuListInfo = [
                  // {
                  //   name: "teacherTraining",
                  //   path: "/teacherTraining",
                  //   redirect: "teacherTraining",
                  //   title: "教师培训",
                  //   engTitle: "Teacher Training",
                  // },

                ];
                break;
              case "9": 
                this.menuListInfo = [
                  {
                    name: "scores",
                    path: "/scores",
                    redirect: "scores",
                    title: "Score viewing",
                    engTitle: "",
                  },

                ];
                break;
            }
          } 
          // else if (this.levelType == 3) {
          //   //管理员
          //   switch (type.value) {
          //     case "0":
          //       this.menuListInfo = [];
          //       break;
          //     case "1": //报名信息
          //       this.menuListInfo = [{
          //           name: "EducatorInfo",
          //           path: "/educatorinfo",
          //           redirect: "EducatorInfo",
          //           title: "个人信息",
          //           engTitle: "Educator Info",
          //         },{
          //           name: "educatorProjects",
          //           path: "/educatorProjects",
          //           redirect: "educatorProjects",
          //           title: "负责项目",
          //           engTitle: "Educator Projects",
          //         },
          //         {
          //           name: "educatorCanProjects",
          //           path: "/educatorCanProjects",
          //           redirect: "educatorCanProjects",
          //           title: "可报项目",
          //           engTitle: "Educator Projects",
          //         }
          //       ];
          //       break;
          //     case "2": //参考资料
          //       this.menuListInfo = [{
          //         name: "learningMaterials",
          //         path: "/learningMaterials",
          //         redirect: "learningMaterials",
          //         title: "学习资料",
          //         engTitle: "Learning Materials",
          //       }];
          //       break;
          //     case "3": //消息
          //       this.menuListInfo = [{
          //           name: "checkMessage",
          //           path: "/checkMessage",
          //           redirect: "checkMessage",
          //           title: "查看消息",
          //           engTitle: "View Messages",
          //         },
          //         {
          //           name: "messageContent",
          //           path: "/messageContent",
          //           redirect: "messageContent",
          //           title: "新的消息",
          //           engTitle: "New Messages",
          //         },
          //       ];
          //       break;
          //   }
          // }
          this.MENU_ROUTER_INFO(this.menuListInfo);
          console.log(type.value);
          this.$router.push({
            name: "menuList"
          });
        }

      },
      goBack() {
        this.MENU_ROUTER_INFO([]);
        Cookie.remove('loginId')
        Cookie.remove('loginProject')
        Cookie.remove('loginType')

        this.$router.push({
          path: "/login",
        });
      },

      goMessage() {
        this.$router.push({
          path: "/messages",
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .breadcrumbI {
    font-size: 15px;
  }

  a {
    text-decoration: none;
  }

  /deep/ .el-header {
    line-height: 58px;
    padding-left: 95px;
    color: #333;
    background: #fff;
    position: relative;
    box-sizing: border-box;
    border-bottom: 1px solid #fff;
  }

  /deep/.el-aside {
    background: #122138;
    color: #fff !important;
  }

  .el-main {
    background-color: #e9eef3;
    color: #333;
  }

  .headerList {
    display: inline-block;
    margin: 0;
    padding: 0;
    padding: 0 10px;
    cursor: pointer;

    p {
      margin: 0;
      padding: 0;
    }

    &:hover {
      font-size: 18px;
    }
    color: white;
  }

  .topBgStyle{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #204386;

    // background-image: url(../assets/topBg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    left: 0px;
  }
  .activeColor {
    color: #ffb414;
    /* color: #409eff !important; */
    line-height: 58px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 60px;
    position: relative;
    // top: -2px;
  }

  .headerList {
    left: 100px;
    // margin-left: 100px;
    position: relative;
  }

  .headerList:nth-child(1) {
    font-size: 20px;
  }

  .menuColor {

    margin-top: -1px;
    background-color: #CD131C;
    width: 20%;
    height: 100%;
    /deep/ .el-menu {
      background: #CD131C !important;
      color: #fff !important;
      text-align: left;
    }

    /deep/ .el-submenu__title:hover {
      background: #CD131C !important;
    }

    /deep/ .el-menu-item-group__title,
    /deep/ .el-menu-item {
      color: #fff !important;
      font-size: 16px;
      line-height: 58px;
    }

    /deep/ .el-submenu__title {
      color: #fff !important;

      i {
        color: #fff !important;
      }
    }

    /deep/ .el-menu-item.is-active {
      background: #CD131C !important;
      color: #fff !important;
    }

    /deep/ .el-menu-item:focus,
    .el-menu-item:hover {
      background: #CD131C !important;
      color: #fff !important;
    }
  }

  .subTitle {}

  .SubengTitle {
    margin: 0;
    padding: 0;
    display: block;
    /* height: 10px; */
    line-height: 10px;
    position: relative;
    top: -8px;
    font-size: 14px;
  }

  .firstIn {
    margin:-20px;

    // margin-top: -21px;
    // margin-bottom: -25px;
    .weclomeTxt {
      position: absolute;
      width: 100%;
      top: 40%;
      text-align: center;
      color: white;
      font-size: 50px;
    }

  }

  .newLogoStyle {
    background-color: white;
    width: 160px;
    height: 100%;
    margin-left: 0px;
    margin-right: 20px;
    img {
      width: 70%;
      // height: 50%;
      position: relative;
      top: 2px;
      left: 25px;
      // background-color: rebeccapurple;
    }
  }
  .logoImg {
    // background-color: black;
    width: 160px;
    margin-left: 0px;

    
    img {
      width: 50%;
      height: 50%;
      position: relative;
      top: 12px;
      background-color: rebeccapurple;
    }
  }

  .goBack {
    position: absolute;
    top: 0;
    right: 40px;
    margin: 0;
    padding: 0;
    cursor: pointer;

    top: 15px;
    height: 30px;
    width: 70px;
    background-color: #FFC12C;
    color: white;
    line-height: 30px;
    text-align: center;
    font-weight: 900;
    .outImg {
      width: 20px;
      margin-left: 5px;
    }
  }
  .goMessage{
    position: absolute;
    top: 0;
    right: 150px;
    margin: 0;
    padding: 0;
    cursor: pointer;

    top: 15px;
    height: 30px;
    width: 90px;
    background-color: #FFC12C;
    color: white;
    line-height: 30px;
    text-align: center;
    font-weight: 900;
    .outImg {
      width: 20px;
      margin-left: 5px;
    }
  }

  .languageDiv {
    position: absolute;
    margin: 0;
    padding: 0;
    background: #fff;
    width: 100px;
    left: 0;
    text-align: center;

    p {
      margin: 0;
      padding: 0;
      line-height: 35px;

      &:hover {
        font-size: 20px;
      }
    }
  }
</style>