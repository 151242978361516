<template>
    <article>
        <p>{{ $t('m.importTip') }}</p>
        <p>{{ 'The template should only record new data for each import; old data needs to be removed. Click' }} <a href="https://mainbucket.learningfirst.cn/NIM%20Registration%20Import%20Template.csv">Here</a> to download the import template. </p>
        <p style="color:#F56C6C">Before the official import, please perform a trial run to ensure the data is accurate. After the official import, any data errors will require manual corrections, which can be quite cumbersome.</p>
        <div style="position:relative;margin-top:10px">
            <input type="file" ref="file" class="file" @change="onChange" />
            <div class="fileDiv">
                <span style="margin-left:14px">{{fileName?fileName:$t('m.xuanzewenjian')}}</span>
            </div>
            <el-select v-model="import_type" style="margin-left:310px">
                <el-option value="试看检查" :label="$t('m.shikanjiancha')"></el-option>
                <el-option value="正式导入" :label="$t('m.zhengshidaoru')"></el-option>
            </el-select>
            <el-button @click="submit" type="primary" style="margin-left:10px">{{ $t('m.importTip3') }}</el-button>
        </div>
        <el-table :data="csvData" empty-text="No Data">
            <!-- <el-table-column prop="student_lastName" :label="$t('m.personInfo.xing1')"></el-table-column> -->
            <!-- <el-table-column prop="student_givenName" :label="$t('m.personInfo.ming1')"></el-table-column> -->
            <el-table-column prop="student_givenName_english" label="Given Name"></el-table-column>
            <el-table-column prop="student_lastName_english" label="Last Name"></el-table-column>
            <el-table-column prop="student_gender" label="Gender"></el-table-column>
            <el-table-column prop="student_graduation" label="Year of High School Graduation"></el-table-column>
            <el-table-column prop="student_mobile" label="Phone Number"></el-table-column>
            <el-table-column prop="student_email" label="Email"></el-table-column>
            <el-table-column prop="event" label="Event"></el-table-column>

            <!-- <el-table-column prop="student_guardian_first_mobile" :label="$t('m.studentInfo.studentGuardianTel')"></el-table-column> -->
            <!-- <el-table-column prop="student_guardian_first_email" :label="$t('m.studentInfo.studentGuardianEmail')"></el-table-column> -->
        </el-table>
    </article>
</template>

<script>
import { saveImportDataNew,saveNimImportData  } from '../service'
import Cookie from "js-cookie";
export default {
    data(){
        return{
            import_type:'试看检查',
            csvData:[],
            headerMap:{
                // '姓':'student_lastName',
                // '名':'student_givenName',
                '姓拼音':'student_lastName_english',
                '名拼音':'student_givenName_english',
                '性别':'student_gender',
                '毕业年份':'student_graduation',
                '学生手机':'student_mobile',
                '学生邮箱':'student_email',
                // '学生监护人手机号':'student_guardian_first_mobile',
                // '学生监护人邮箱':'student_guardian_first_email',
                '子分会':'event'
            },
            contact_id:'',
            fileName:'',
            loginType: Cookie.get("loginType"),
            loginId: Cookie.get("loginId"),
            project_code:Cookie.get("loginProject"),
            type:this.$route.query.type,
            package_id:this.$route.query.package_id,
        }
    },
    mounted(){
        // this.contact_id = Cookie.get('studentid')
    },
    methods:{
        importCsv(){
            let files = this.$refs['file'].files;
            let file = files[0]
            let _this = this
            let reader = new FileReader();
            reader.readAsText(file,'GB2312');
            reader.onload = function() {
                let data = _this.csvToObject(this.result)
                console.log(data)
                _this.csvData = data
            }
        },
        importCsvConfirm(){
            let files = this.$refs['file'].files;
            let file = files[0]
            let _this = this
            let reader = new FileReader();
            reader.readAsText(file,'GB2312');
            reader.onload = function() {
                let data = _this.csvToObject(this.result)
            
                saveNimImportData(_this.loginId,_this.project_code,data).then(res=>{
                    if(res.data.code===0){
                        _this.$message({
                            message:'Import successfully',
                            type:'success'
                        })
                    }else{
                        _this.$message.warning('Import failure')
                    }
                })
            
                
            }
        },
        csvToObject(csvString){
            let csvarray = csvString.split("\r\n");
            let datas = [];
            let headers = csvarray[0].split(",");
           
            for(let i = 1;i<csvarray.length;i++){
                let data = {};
                let temp = csvarray[i].split(",");
                for(let j = 0;j<temp.length;j++){
                    if(temp[j]!==''){
                        data[headers[j]] = temp[j];
                    }
                }
                if(JSON.stringify(data) !== '{}'){
                    datas.push(data);
                }
            }
            return datas;
        },
        submit(){
            if(this.$refs['file'].files.length===0){
                this.$message.error('Please select file');
                return;
            }
            
            if(this.import_type === '试看检查'){
                this.importCsv()
            }else{
                this.importCsvConfirm()
            }
        },
        onChange(){
            this.fileName = this.$refs['file'].files.length>0 ? this.$refs['file'].files[0].name : ''
            
        }
    }
}
</script>

<style lang="scss" scoped>
  article {
    margin-top: 50px;
    background: #fff;
    padding: 10px;
    height: 70vh;

    /deep/ .el-form.el-form--inline {
      background-color: #f8f8f8;
      margin-bottom: 10px;
      padding: 8px;
      overflow: hidden;
    }

    /deep/ .el-form.el-form--inline .el-form-item.el-form-item--medium {
      margin-right: 4px;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

  .formP {
    margin: 0;
    padding: 0;

    padding-left: 10px;
    // border:1px solid #e9eef3;
    background: #f8f9fa;
    border-radius: 4px;
    width: 70%;
    position: relative;
  }

.file{
    opacity:0;
    width: 300px;
    height: 40px;
    z-index: 200;
    position: absolute;
}

.fileDiv{
    position: absolute;
    width: 300px;
    height: 38px;
    border-radius: 5px;
    z-index: 1;
    left: 0;
    top: 0;
    border: 1px solid #DCDFE6;
    line-height: 38px;
    font-family: PingFang SC;
    color: #606266;
    font-size: 15px;
    overflow: hidden;
    background: #fff;
}
</style>