<template>
    <article>
        <div class="mainView">
        <el-tabs v-if="loginType=='学生'" v-model="activeName" style="font-size: 25px;">
            <el-tab-pane label="Information" name="first">

                <el-form
                    class="block-form"
                    :model="infoForm"
                    :rules="rules1"
                    ref="infoForm"
                    :label-width="formLabelWidth"
                    size="medium"
                    >
                    <el-row :gutter="10">
                        <el-col :span="12">
                        <el-form-item label="First Name:" prop="givenName_pinyin">
                            <el-input
                            :disabled="!showInfoSubmit"
                            v-model="infoForm.givenName_pinyin"
                            ></el-input>
                        </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-col :span="12">
                        <el-form-item label="Last Name:" prop="lastName_pinyin">
                            <el-input
                            :disabled="!showInfoSubmit"
                            v-model="infoForm.lastName_pinyin"
                            ></el-input>
                        </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-col :span="12">
                        <el-form-item label="Gender:" prop="gender">
                            <div v-if="showInfoSubmit" style="display:inline-block;width:100%;float: left;">
                                <el-radio-group v-model="infoForm.gender">
                                    <div style="width:100px;float: left;"><el-radio label="Male" /></div>
                                    <div style="width:100px;float: left;"><el-radio label="Female" /></div>
                                </el-radio-group>
                            </div>

                            <div v-if="!showInfoSubmit" style="display:inline-block;width:100%; color: #606266;">
                               {{infoForm.gender}}
                            </div>
                        </el-form-item>
                        </el-col>
                    </el-row>


                    <el-row :gutter="10">
                        <el-col :span="12">
                        <el-form-item label="Graduation Year:" prop="graduation">
                            <div style="display: inline-block;width:100%">
                                <el-select :disabled="!showInfoSubmit" class="w140" v-model="infoForm.graduation"
                                    :placeholder="$t('m.studentInfo.yohsgPlaceholder')" clearable>
                                    <el-option v-for="item in graduationOption" :key="item" :label="item" :value="item"></el-option>
                                </el-select>
                            </div>
                        </el-form-item>
                        </el-col>
                    </el-row>


                    <el-row :gutter="10">
                        <el-col :span="12">
                        <el-form-item label="School:" prop="school">
                            <el-input
                            :disabled="!showInfoSubmit"
                            v-model="infoForm.school"
                            ></el-input>
                        </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-col :span="12">
                        <el-form-item label="Email:" prop="email">
                            
                            <div style="display:inline-block;width:100%;color: #606266;">
                               {{infoForm.email}}
                            </div>
                        </el-form-item>
                        </el-col>
                    </el-row>
                    
                    <!-- <el-row :gutter="10">
                        <el-col :span="12">
                        <el-form-item label="" prop=""> -->
                            
                            <div style="display:inline-block;width:100%;color: #606266;margin-bottom:20px;font-size:15px">
                              Please confirm your name and school, the information will be used for the certificate.
                            </div>
                        <!-- </el-form-item>
                        </el-col>
                    </el-row> -->


                    <div class="updateBtn">
                        <div v-if="!showInfoSubmit" class="btn-link"  @click="editInfo()">
                            Edit
                        </div>


                        <div v-if="showInfoSubmit" class="btn-link" type="primary" @click="changeInfo('infoForm')">
                            Submit
                        </div>
                    </div>
                </el-form>
            

            </el-tab-pane>
            <el-tab-pane label="Change Password" name="second">
              
                <el-form
                    class="block-form"
                    :model="pswForm"
                    :rules="rules"
                    ref="pswForm"
                    status-icon
                    :label-width="formLabelWidth"
                    size="medium"
                    >
                    <el-row :gutter="10">
                        <el-col :span="12">
                        <el-form-item :label="$t('m.personInfo.initialPassword')" prop="initial_password">
                            <el-input
                            type="password"
                            :placeholder="$t('m.personInfo.initialPlaceholder')"
                            v-model="pswForm.initial_password"
                            ></el-input>
                        </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-col :span="12">
                        <el-form-item :label="$t('m.personInfo.newPassword')" prop="new_password">
                            <el-input
                            type="password"
                            :placeholder="$t('m.personInfo.newPlaceholder')"
                            v-model="pswForm.new_password"
                            ></el-input>
                        </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-col :span="12">
                        <el-form-item :label="$t('m.personInfo.enterAgain')" prop="new_password_again">
                            <el-input
                            type="password"
                            :placeholder="$t('m.personInfo.enterAgainPlaceholder')"
                            v-model="pswForm.new_password_again"
                            ></el-input>
                        </el-form-item>
                        </el-col>
                    </el-row>
                    <div class="updateBtn">

                       
                        <div class="btn-link" type="primary" @click="changePassword('pswForm')">
                            Submit
                        </div>
                        
                    </div>
                </el-form>

            </el-tab-pane>


          </el-tabs>


          <el-tabs v-if="loginType=='教师'" v-model="activeName" style="font-size: 25px;">
            <el-tab-pane label="Information" name="first">

                <el-form
                    class="block-form"
                    :model="adviserInfoForm"
                    :rules="rules2"
                    ref="adviserInfoForm"
                    :label-width="formLabelWidth"
                    size="medium"
                    >
                    <el-row :gutter="10">
                        <el-col :span="12">
                        <el-form-item label="Name:" prop="name">
                            <el-input
                            :disabled="!showInfoSubmit"
                            v-model="adviserInfoForm.name"
                            ></el-input>
                        </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-col :span="12">
                        <el-form-item label="Position:" prop="position">
                            <el-input
                            :disabled="!showInfoSubmit"
                            v-model="adviserInfoForm.position"
                            ></el-input>
                        </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-col :span="12">

                            
                        <el-form-item label="Origanization:" prop="origanization">
                            <!-- <el-input
                            disabled
                            v-model="adviserInfoForm.origanization"
                            ></el-input> -->

                            <div style="display:inline-block;width:100%;color: #606266;">
                               {{adviserInfoForm.origanization}}
                            </div>
                        </el-form-item>
                        </el-col>
                    </el-row>

<!-- 
                    <el-row :gutter="10">
                        <el-col :span="12">
                        <el-form-item label="Origanization Type:" prop="origanization_type">
                            <div style="display: inline-block;width:100%">
                                <el-select :disabled="!showInfoSubmit" class="w140" v-model="adviserInfoForm.graduation"
                                    placeholder="Please select Origanization Type" clearable>
                                    <el-option v-for="item in origanizationOptions" :key="item" :label="item" :value="item"></el-option>
                                </el-select>
                            </div>
                        </el-form-item>
                        </el-col>
                    </el-row> -->

                    <el-row :gutter="10">
                        <el-col :span="12">
                        <el-form-item label="Email:" prop="email">
                            
                            <div style="display:inline-block;width:100%;color: #606266;">
                               {{adviserInfoForm.email}}
                            </div>
                        </el-form-item>
                        </el-col>
                    </el-row>
                    
                    <!-- <el-row :gutter="10">
                        <el-col :span="12">
                        <el-form-item label="" prop=""> -->
                            
                            <!-- <div style="display:inline-block;width:100%;color: #606266;margin-bottom:20px;font-size:15px">
                              Please confirm your name and school,the information will be used for the certificate.
                            </div> -->
                        <!-- </el-form-item>
                        </el-col>
                    </el-row> -->


                    <div class="updateBtn">
                        <div v-if="!showInfoSubmit" class="btn-link"  @click="editInfo()">
                            Edit
                        </div>


                        <div v-if="showInfoSubmit" class="btn-link" type="primary" @click="changeAdviserInfo('adviserInfoForm')">
                            Submit
                        </div>
                    </div>
                </el-form>
            

            </el-tab-pane>
            <el-tab-pane label="Change Password" name="second">
              
                <el-form
                    class="block-form"
                    :model="pswForm"
                    :rules="rules"
                    ref="pswForm"
                    status-icon
                    :label-width="formLabelWidth"
                    size="medium"
                    >
                    <el-row :gutter="10">
                        <el-col :span="12">
                        <el-form-item :label="$t('m.personInfo.initialPassword')" prop="initial_password">
                            <el-input
                            type="password"
                            :placeholder="$t('m.personInfo.initialPlaceholder')"
                            v-model="pswForm.initial_password"
                            ></el-input>
                        </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-col :span="12">
                        <el-form-item :label="$t('m.personInfo.newPassword')" prop="new_password">
                            <el-input
                            type="password"
                            :placeholder="$t('m.personInfo.newPlaceholder')"
                            v-model="pswForm.new_password"
                            ></el-input>
                        </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-col :span="12">
                        <el-form-item :label="$t('m.personInfo.enterAgain')" prop="new_password_again">
                            <el-input
                            type="password"
                            :placeholder="$t('m.personInfo.enterAgainPlaceholder')"
                            v-model="pswForm.new_password_again"
                            ></el-input>
                        </el-form-item>
                        </el-col>
                    </el-row>
                    <div class="updateBtn">

                       
                        <div class="btn-link" type="primary" @click="changePassword('pswForm')">
                            Submit
                        </div>
                        
                    </div>
                </el-form>

            </el-tab-pane>


          </el-tabs>
      </div>
    </article>
</template>

<script>

import {

    updateStudentInfo,  
    changePassword,
    changeAdviserPassword,
    getStudentInfo,
    getAdviserInfo,
    updateAdviserInfo
  } from "../service.js";
import Cookie from "js-cookie";

export default {
    data(){
        return{
            activeName:'first',
            canSelectEvents:[],
            project_code: Cookie.get("loginProject"),
            loginType: Cookie.get("loginType"),

            pswForm: {},
            infoForm:{},
            adviserInfoForm:{},
            formLabelWidth: "140px",
            studentid: Cookie.get("loginId"),
            rules: {
                initial_password: [
                { required: true, message: this.$t('m.pwdTip'), trigger: "change" },
                ],
                new_password: [
                { required: true, message: this.$t('m.pwdTip1'), trigger: "change" },
                ],
                new_password_again: [
                { required: true, message: this.$t('m.pwdTip2'), trigger: "change" },
                ],
            },

            rules1: {
                givenName_pinyin: [
                { required: true, message: 'The first Name cannot be empty', trigger: "change" },
                ],
                lastName_pinyin: [
                { required: true, message: 'The last Name cannot be empty', trigger: "change" },
                ],
                gender: [
                { required: true, message: 'Please select gender', trigger: "change" },
                ],
                graduation: [
                { required: true, message: 'Please select graduation', trigger: "change" },
                ],
                school:[
                { required: true, message: 'The school cannot be empty', trigger: "change" },
                ],
            },
            rules2: {
                name: [
                { required: true, message: 'The name cannot be empty', trigger: "change" },
                ],
                position: [
                { required: true, message: 'The Position cannot be empty', trigger: "change" },
                ],
                origanization_type: [
                { required: true, message: 'Please select Origanization Type', trigger: "change" },
                ],
                
            },
            graduationOption: [
          
                "2024",
                "2025",
                "2026",
                "2027"

            ],

            origanizationOptions: [
                'Secondary School',
                'Study Abroad Agency',
                'Admission Consultant/Firm',
                'Academic Enrichment Center',
                'Other'
            ],

            showInfoSubmit:false,

        }
    },

    created() {

        if (this.loginType == '教师'){
            this.getAdviserInfo();

        }else{
            this.getStudentInfo();
        }
    },
    mounted(){
        // this.getStudentInfo(); //获取学生信息

    },
    methods:{
        getStudentInfo() {

            this.dataLoading=true;
            let param = {
                student_id: Cookie.get("loginId"),
            };

            this.infoForm = {}

            getStudentInfo(param).then((res) => {
                this.dataLoading=false;

                console.log(123123,res)
                if (res.code == 0) {
                    let info = res.data.student_info

                    var getInfo = {}
                    getInfo.givenName_pinyin = info.student_givenName_pinyin
                    getInfo.lastName_pinyin = info.student_lastName_pinyin

                    if (info.student_gender == 'M'|| info.student_gender == 'male'|| info.student_gender == 'Male'|| info.student_gender == '男') {
                        getInfo.gender = 'Male'; 
					}
					if (info.student_gender == 'F'|| info.student_gender == 'female' || info.student_gender == 'Female' || info.student_gender == '女') {
						getInfo.gender= 'Female'; 
					}

                    getInfo.graduation = info.student_graduation
                    getInfo.email = info.student_email
                    getInfo.school = info.student_edit_school

                    this.infoForm = getInfo
                } else {
                    this.$message.error(res.msg);
                }
            });
      },

      getAdviserInfo(){
        

        this.dataLoading=true;
            let param = {
                adviser_id: Cookie.get("loginId"),
            };

            this.infoForm = {}

            getAdviserInfo(param).then((res) => {
                this.dataLoading=false;

                console.log(123123,res)
                if (res.code == 0) {
                    let info = res.data.adviser_info

                    var getInfo = {}
                    getInfo.name = info.contact_enName            
                    getInfo.origanization = info.account_name
                    getInfo.position = info.contact_position

                    getInfo.email = info.contact_email

                    this.adviserInfoForm = getInfo
                } else {
                    this.$message.error(res.msg);
                }
            });
      },

      editInfo(){
            this.showInfoSubmit = true
      },

      changeInfo(infoForm){
        let _this = this;
        this.$refs[infoForm].validate((valid) => {
            if (valid) {
                _this.loadDialog = true;
            
                let param = {
                    student_id: _this.studentid,
                    student_gender: _this.infoForm.gender,
                    student_lastName_pinyin: _this.infoForm.lastName_pinyin,
                    student_givenName_pinyin: _this.infoForm.givenName_pinyin,
                    student_graduation: _this.infoForm.graduation,
                    student_email: _this.infoForm.email,
                    student_edit_school: _this.infoForm.school,

                };
                updateStudentInfo(param).then((res) => {
                    this.loadDialog = false;

                    if (res.code == 0) {
                        _this.$message.success('Information Updated Successfully');
                        this.showInfoSubmit = false

                    } else {
                        this.$message.error(res.msg);
                    }
                });
            
            } else {
                return false;
            }
        });
      },

      changeAdviserInfo(adviserInfoForm){
        let _this = this;
        this.$refs[adviserInfoForm].validate((valid) => {
            if (valid) {
                _this.loadDialog = true;
            
                let param = {
                    adviser_id: _this.studentid,
                    contact_enName: _this.adviserInfoForm.name,
                    contact_position: _this.adviserInfoForm.position,

                };
                updateAdviserInfo(param).then((res) => {
                    this.loadDialog = false;

                    if (res.code == 0) {
                        _this.$message.success('Information Updated Successfully');
                        this.showInfoSubmit = false

                    } else {
                        this.$message.error(res.msg);
                    }
                });
            
            } else {
                return false;
            }
        });
      },

      changePassword(pswForm) {
        let _this = this;
        this.$refs[pswForm].validate((valid) => {
            if (valid) {
            _this.loadDialog = true;
            if (_this.pswForm.new_password != _this.pswForm.new_password_again) {
                this.$message.warning(this.$t('m.pwdTip3'));
            } else {


                if (this.loginType=='教师'){

                    let param = {
                        adviser_id: _this.studentid,
                        initial_password: _this.pswForm.initial_password,
                        new_password: _this.pswForm.new_password,
                        new_password_again: _this.pswForm.new_password_again,
                        };
                    changeAdviserPassword(param).then((res) => {
                        this.loadDialog = false;
                        if (res.code == 0) {
                            _this.$message.success(this.$t('m.pwdTip4'));
                            Cookie.set("loginId",'');
                            _this.$router.push({ name: "login" });
                        } else {
                            this.$message.error(res.msg);
                        }
                    });

                }else{


                    let param = {
                        student_id: _this.studentid,
                        initial_password: _this.pswForm.initial_password,
                        new_password: _this.pswForm.new_password,
                        new_password_again: _this.pswForm.new_password_again,
                        };
                    changePassword(param).then((res) => {
                        this.loadDialog = false;
                        if (res.code == 0) {
                            _this.$message.success(this.$t('m.pwdTip4'));
                            Cookie.set("loginId",'');
                            _this.$router.push({ name: "login" });
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
                }
                
            }
            } else {
            return false;
            }
        });
      },

    }
}
</script>

<style>
::v-deep .el-tabs_item{
    color: #204386;
}

.mainView{
    width: 80%;
    margin-left: 10%;
    margin-top: 20px;
    height: 80vh;
    /* background-color: aqua; */
}
.itemStyle{
    width: 100%;
    height: 40px;
    margin-top: 10px;
    /* background-color: bisque; */
}
.titleStyle{
    width: 160px;
    text-align: right;
    color: #333;
    font-size: 18px;
    font-weight: 500;
    line-height: 40px;
    float: left;

}
.inputStyle{
    width: 300px;
    text-align: left;
    margin-left: 200px;
    height: 40px;
    /* background-color: aqua; */
}
.eventStyle{
    margin-left: 30px;
    margin-right: 30px;
    height: 50px;
    background-color: #ffb414;
    color: #204386;
    border:1px solid #204386;
    border-radius: 4px;
    text-align: center;
    line-height: 50px;
    font-size: 20px;
    font-weight: 500;
}
.btn-link{
    border: none;
    color: white;
    width: 80px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-size: 15px;
    border-radius: 4px;
    background-color: #ffb414;
    cursor: pointer;
}
</style>