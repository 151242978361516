<template>
    <article>
        <h4>{{ $t('m.loginProject') }}</h4>
        <el-table height="260" v-loading="loadPackage" :data="projects" header-cell-class-name="adorn" size="medium" border ref="leftTable" class="left-table">
            <el-table-column :label="$t('m.mingcheng')" align="center" prop="project_name"></el-table-column>
            <!-- <el-table-column :label="$t('m.jiage')" prop="project_price_usd" align="center"></el-table-column> -->

            <el-table-column :label="$t('m.jiage')" align="center">
            
                <template slot-scope="scope">
                    {{   scope.row.project_price_usd <= 0 ? 'Free' : (scope.row.project_price_usd +'('+ scope.row.project_price_currency + ')')}}
                </template>
            </el-table-column>
            <el-table-column width="200" :label="$t('m.caozuo')" align="center">
                <template slot-scope="scope">
                <el-button class="btn-link" @click="toPackage(scope.row)" type="primary" size="small">View details</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- <h4>项目列表</h4>
        <el-table height="400" v-loading="loadDialog" :data="projects" header-cell-class-name="adorn" size="medium" border ref="leftTable" class="left-table">
            <el-table-column label="名称" align="center">
                <template slot-scope="scope">
                    {{scope.row.type==='package'?scope.row.name:scope.row.project_name}}
                </template>
            </el-table-column>
            <el-table-column label="项目代码" align="center">
                <template slot-scope="scope">
                    <div v-if="scope.row.type==='package'">
                        <div v-for="item in scope.row.projects" :key="item._id.$id">{{item.project_code}}</div>
                    </div>
                    <div v-else>{{scope.row.project_code}}</div>
                </template>
            </el-table-column>
            <el-table-column label="价格" align="center">
                <template slot-scope="scope">
                    {{scope.row.type==='package'?scope.row.price:scope.row.project_price}}
                </template>
            </el-table-column>
            <el-table-column label="开始时间" align="center">
                <template slot-scope="scope">
                    {{formateDate(scope.row.start_time)}}
                </template>
            </el-table-column>
            <el-table-column label="结束时间" align="center">
                <template slot-scope="scope">
                    {{formateDate(scope.row.end_time)}}
                </template>
            </el-table-column>
            <el-table-column label="默认测试点" align="center">
                <template slot-scope="scope">
                    <div v-if="scope.row.station">
                        {{scope.row.station.di_city+scope.row.station.name}}
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="地址" align="center" prop="point.address" width="400">
            </el-table-column>
            <el-table-column label="额度" prop="remain_count" width="100" align="center"></el-table-column>
            <el-table-column width="100" label="操作" align="center">
                <template slot-scope="scope">
                    <el-button class="btn-link" @click="toProject(scope.row)" type="primary" size="small">详情</el-button>
                </template>
            </el-table-column>
        </el-table> -->
    </article>
</template>
<script>
  import {
    getAccountApplications,
    getAccountApplicationsByAccount,
    getLoginProject,
    getEcoplorePackages
  } from "../service.js";

  import Cookie from "js-cookie";
  export default {
    data() {
      return {
        loadDialog: false,
        loadPackage: false,
        teamForm: {
          name: "测试",
        },
        formLabelWidth: "100px",
        schoolForm: {},
        appTable: [],
        paginationLoading: false,
        loginType: Cookie.get("loginType"),
        loginId: Cookie.get("loginId"),
        projects:[],
        packages:[]
      };
    },
    created() {
      if (this.loginType == '教师') {

        this.getLoginProject()
        // this.getEcoplorePackages()
      } else if (this.loginType == '学校管理员') {
        // this.getAccountApplicationsByAccount();
      }

    },
    mounted() {},
    methods: {
        getAccountApplications() {
            this.loadDialog = true;
            getAccountApplications({
                contact_id: Cookie.get("loginId")
                }).then((res) => {
                this.loadDialog = false;
                if (res.code == 0) {
                    this.appTable = res.data;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        getAccountApplicationsByAccount() {
            this.loadDialog = true;
            getAccountApplicationsByAccount({
                account_id: Cookie.get("loginId")
                }).then((res) => {
                    this.loadDialog = false;
                    if (res.code == 0) {
                        this.appTable = res.data;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        toProject(row) {
            this.$router.push({
                path: "/applicationDetail",
                query: {
                    type:'project',
                    project_code: row.project_code
                }
            });
        },
        toPackage(row) {
            this.$router.push({
                path: "/applicationDetail",
                query: {
                    type:'package',
                    package_id: row._id.$id
                }
            });
        },
        getLoginProject(){
            this.loadDialog = true
            getLoginProject(Cookie.get("loginProject"),Cookie.get("loginId")).then(res=>{
                this.loadDialog = false
                if(res.data.code===0){
                    this.projects = res.data.data
                }
            })
        },
       
        formateDate(date){
            let time = new Date(date*1000)
            return `${time.getFullYear()}-${time.getMonth()+1}-${time.getDate()}`;
        }
    },
  };
</script>
<style lang="scss" scoped>
  article {
    margin-top: 50px;
    background: #fff;
    padding: 10px;

    /deep/ .el-form.el-form--inline {
      background-color: #f8f8f8;
      margin-bottom: 10px;
      padding: 8px;
      overflow: hidden;
    }

    /deep/ .el-form.el-form--inline .el-form-item.el-form-item--medium {
      margin-right: 4px;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

  .formP {
    margin: 0;
    padding: 0;

    padding-left: 10px;
    // border:1px solid #e9eef3;
    background: #f8f9fa;
    border-radius: 4px;
    width: 70%;
    position: relative;
  }
</style>