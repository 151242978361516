export const m = {

  ch: "中文",
  en: "英文",
  cancel:"取 消",
  confirm:'确 定',
  adminText: '管理员资源中心',
  languageTile: '语言：',
  loginType: '身份',
  forgetPsw: '忘记密码',
  accountName: '用户名',
  password: '密码',
  loginType: '身份',
  loginProject: '项目',

  mingcheng: '名称',
  jiage: '价格',
  caozuo: '操作',
  xiangqing:"详情",
  suozaisheng:"学校所在省份:",
  suozaichengshi:"学校所在城市:",
  xuexiaoaddress:"学校地址:",
  xuexiaoyoubian:"学校所在地邮编:",

  importTip:"导入文件：报名表导入文件需要提供CSV格式的数据文件，并符合规定格式的模板。模板里第一行为表头，不能动。不能改变列的顺序和位置。数据从第二行开始。模板里只需记录每次要导入的新数据，老数据需要删除。",
  importTip1:"报名表必须使用导入模板，文件下载请点击",
  importTip2:"正式导入前请先试看，数据无误后再正式导入；正式导入以后数据有误则需要手动修改，非常麻烦。",
  importTip3:"确认",


  registrationSuccess: "报名成功",




  shikanjiancha:"试看检查",
  zhengshidaoru:"正式导入",
  xuanzewenjian:"请选择文件",

  loginTip:"请输入账号",
  loginTip1:"请输入密码",
  loginTip2:"请选择身份",
  loginTip3:"请选择项目",

  pleaseChoose: "请选择",
  login: '登录',
  teacherTraining:"教师培训",
  accountNamePlaceholder: "请输入用户名",
  passwordPlaceholde: "请输入密码",
  registrationInfo: "报名信息",
  registrationChengji: "成绩查看",

  chengji: "成绩",
  kemu: "科目",
  jinji:"晋级",
  jiangxiang:"奖项",

  messages: "消息",
  resourceCenter: "参考资料",
  studentResourceCenter: "学生资源中心",
  schoolResourceCenter: '校方资源中心',
  registerInfo: "注册信息",
  projectRegistration:"项目报名",
  languageInfo: "选择语言",
  logOut: "退出",
  tips1: "*请使用Chrome、Safari或IE进行系统访问，使用其他浏览器（特别是安全浏览器，如360浏览器、IE安全浏览器、绿色浏览器等)将会出现视图不兼容的问题，影响内容的查看。",
  tips2: "*用户名为你的注册邮箱，字母应全部小写。非小写字母将被视为账号错误。",
  addApplicationForm: "新增",
  importApplicationForm: "导入",
  exportApplicationForm: "导出报名表",
  teamInfo: "参赛信息",

  xuhao: "序号",
  duiwu:"队伍",
  chengyuanleixing:"成员类型",
  jiaofeixinxi:"缴费信息",
  jiaofeixudao:"缴费渠道：",
  jiaofeifangshi:"缴费方式：",
  jiaofeiriqi:"缴费日期：",
  jiaofeijine:"缴费金额：",
  jiaofeitongzhi:"勾选缴费须知：",
  dengluyouxiang:"登录邮箱：",
  tongduichengyuan:"同队成员",

  qianwangjiaofei:"前往缴费",
  payShow:"请使用本人16周岁以上的支付宝账户扫码，一旦扫码后不可使用其他支付宝账户进行付款；支付宝后台确认支付成功并修改报名状态会有延迟，支付完请间隔一段时间后再查看报名状态是否有更新",


  pwdTip:"初始密码不能为空",
  pwdTip1:"新密码不能为空",
  pwdTip2:"请再次输入新密码",
  pwdTip3:"两次密码输入不一致",
  pwdTip4:"密码更改成功，请重新登录",

  xiugaichenggong:"修改成功",
  // 学生注册信息
  // 个人信息
  personInfo: {
    schoolName: "学校名称：",
    name: "姓名:",
    name1: "姓名",
    personalInformation: "个人信息",
    lingdui:"领队&组队",
    registrationInformation: "报名信息",
    base: "基本信息：",
    xing: "姓：",
    ming: "名：",

    xing1: "姓",
    ming1: "名",
    xingpinyin: "姓拼音：",
    mingpinyin: "名拼音：",
    xingpinyin1: "姓拼音",
    mingpinyin1: "名拼音",
    sex: "性别：",
    sex1: "性别",
    girl: "女",
    boy: "男",
    zhengjianleixing:"证件类型",
    zhengjianhaoma:"证件号码",
    tips1: "高中毕业",
    contactInformation: "联系方式",
    tel: "手机：",
    email: "邮箱：",
    email1: "邮箱",
    weChat: "微信：",
    identityID: "身份证：",
    birthDate: "出生日期：",
    tips2: "身份证或护照，仅做保险购买用",
    tips3: 'YYYY/MM/DD，仅做保险购买用',
    tips4: '目前个人信息不允许自行修改，若发现个人信息有误，请联系我们告知具体修改请求信息。',
    tips5: "发送系统内消息，是联系我们最方便、最易于管理的方式。我们将在收到你的消息后，尽快给你回复，并进行邮件提醒。",
    parentContactInformation: "父母联系方式",
    firstGuardianTel: "第一监护人-手机：",
    firstGuardianEmail: "第一监护人-邮箱：",
    secondGuardianTel: "第二监护人-手机：",
    secondGuardianEmail: "第二监护人-邮箱：",
    supplementaryNotes: "补充说明：",
    initialPassword: '初始密码：',
    newPassword: '新密码：',
    enterAgain: '再次输入：',
    enterPl:"请输入",
    initialPlaceholder: '请输入初始密码',
    newPlaceholder: '请输入新密码',
    enterAgainPlaceholder: "请再次输入新密码",
    submit: "提交",
    chooseProject: "消息类型：",
    messageContent: "消息内容：",
    messageContentPlaceholder: '请输入消息内容',
    modifySubproject: '修改子项目',
    competitionSubject: '比赛科目【可选】：',
    competitionSubjectExtra: '比赛科目【额外可选】：',
    registrationProject: "报名项目",
    registrationProject1: "报名项目：",
    registrationStatus: "报名状态：",
    registrationStatus1: "报名状态",

  },
  
  //学生报名信息
  studentInfo:{
    newRegistrationInformation:"新增报名信息",
    province:"省份",
    school:"学校",
    student:"学生",
    studentSurname:"学生的姓",
    studentName:"学生的名",
    studentSurnamePlaceholder:"请输入学生的姓",
    studentNamePlaceholder:"请输入学生的名",
    studentSurnamePinyin:"学生的姓拼音",
    studentNamePinyin:"学生的名拼音",
    studentSurnamePinyinPlaceholder:"请输入学生的姓拼音",
    studentNamePinyinPlaceholder:"请输入学生的名拼音",
    yohsg:"高中毕业年份",
    yohsgPlaceholder:"请选择预计高中毕业年份",
    sex:'性别',
    sexPlaceholder:"请选择性别",
    girl:'女',
    boy:'男',
    phoneNumber:"学生手机号",
    phoneNumberPlaceholder:"请输入学生手机号",
    studentEmail:"学生邮箱",
    studentEmailPlaceholder:"请输入学生邮箱",
    studentGuardianTel:"学生监护人手机",
    studentGuardianTelPlaceholder:"请输入学生监护人手机",
    studentGuardianEmail:"学生监护人邮箱",
    studentGuardianEmailPlaceholder:"请输入学生监护人邮箱",
    specificProjects:"具体项目（若有）",
    uploadApplication:"上传报名表",
    downloadTemplate:"下载模板",
    click:"请点击这里",
    importType:"导入类型",
    import:"导入",
    province:"省份",
    province:"省份",
    province:"省份",
    province:"省份",
    province:"省份",
  },
  //管理员
  administrators: {

  }


}