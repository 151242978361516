import axios from 'axios'

export function saveImportData(paramInfo) {

    return axios.post('./index.php', paramInfo)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function getAccountApplicationsProjectCode(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getAccountApplicationsProjectCode' })
    return axios.post('./index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function getStudentScoreApplications(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getStudentScoreApplications' })
    return axios.post('./index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function getEventProjects(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getEventProjects' })
    return axios.post('./index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function getCertificate(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getCertificate' })
    return axios.post('./index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function getTestResults(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getTestResults' })
    return axios.post('./index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function getAccountMessages(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getAccountMessages' })
    return axios.post('./index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function updateStudentInDatabase(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'updateStudentInDatabase' })
    return axios.post('./index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function changeEvents(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'changeEvents' })
    return axios.post('./index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function getAccountMaterialList(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getAccountMaterialList' })
    return axios.post('./index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function getApplicationsInfoByAccount(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getApplicationsInfoByAccount' })
    return axios.post('./index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function getAccountApplicationsByAccount(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getAccountApplicationsByAccount' })
    return axios.post('./index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function getSchoolInfoByAccount(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getSchoolInfoByAccount' })
    return axios.post('./index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function getStudentMessagesByAccount(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getStudentMessagesByAccount' })
    return axios.post('./index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function getApplicationsProjectCodeByAccount(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getApplicationsProjectCodeByAccount' })
    return axios.post('./index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function getAccountApplications(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getAccountApplications' })
    return axios.post('./index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}

export function getAccountsByProvince(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getAccountsByProvince' })
    return axios.post('./index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function getProjectEvents(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getProjectEvents' })
    return axios.post('./index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}


export function getNIMProjectEvents(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getNIMProjectEvents' })
    return axios.post('./index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}

export function getNIMProjectAndAccount(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getNIMProjectAndAccount' })
    return axios.post('./index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}



export function getNIMProjectByEvent(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getNIMProjectByEvent' })
    return axios.post('./index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}




export function addNewApplicationForm(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'addNewApplicationForm' })
    return axios.post('./index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}

export function addPackageApplication(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'addPackageApplication' })
    return axios.post('./index.php', param)
}

export function addNewNIMApplication(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'addNewNIMApplication' })
    return axios.post('./index.php', param)
}


export function getNIMApplicationByEvent(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getNIMApplicationByEvent' })
    return axios.post('./index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}


export function getMaterialList(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getMaterialList' })
    return axios.post('./index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function getApplicationsByAccount(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getApplicationsByAccount' })
    return axios.post('./index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}

export function getApplicationsByPackage(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getApplicationsByPackage' })
    return axios.post('./index.php', param)
}

export function getApplicationsByPackageStudent(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getApplicationsByPackageStudent' })
    return axios.post('./index.php', param)
}


// export function sendMessage(paramInfo) {
//     let param = Object.assign({}, paramInfo, { function: 'sendMessage' })
//     return axios.post('./index.php', param)
//         .then(function(response) {
//             return response.data
//         })
//         .catch(function(error) {
//             console.log(error);
//         })
// }

export function getMyMessages(id, project) {
    return axios.post('./index.php', {
        function: 'getMyMessages',
        id,
        project
    })
}
export function sendMessage(userId, project, content, type, accessory) {
    return axios.post('./index.php', {
        function: 'sendMessage',
        userId,
        project,
        content,
        type,
        accessory
    })
}

// export function getMyMessages(paramInfo) {
//     let param = Object.assign({}, paramInfo, { function: 'getMyMessages' })
//     return axios.post('./index.php', param)
//         .then(function(response) {
//             return response.data
//         })
//         .catch(function(error) {
//             console.log(error);
//         })
// }
export function changePassword(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'changePassword' })
    return axios.post('./index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function changeAdviserPassword(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'changeAdviserPassword' })
    return axios.post('./index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}




export function updateStudentInfo(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'updateStudentInfo' })
    return axios.post('./index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}

export function updateAdviserInfo(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'updateAdviserInfo' })
    return axios.post('./index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}


export function getOrder(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getOrder' })
    return axios.post('./index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function getTeamInfo(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getTeamInfo' })
    return axios.post('./index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function getStudentInfo(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getStudentInfo' })
    return axios.post('./index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function getAdviserInfo(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getAdviserInfo' })
    return axios.post('./index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}




export function getContactsByAccount(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getContactsByAccount' })
    return axios.post('./index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}


export function getStudentByAccount(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getStudentByAccount' })
    return axios.post('./index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function updateAccountApplication(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'updateAccountApplication' })
    return axios.post('./index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function updateContactByAccount(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'updateContactByAccount' })
    return axios.post('./index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function getSchoolInfo(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getSchoolInfo' })
    return axios.post('./index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}

export function getPaypal(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getPaypal' })
    return axios.post('./index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
// export function paypalSucceedBack(paramInfo) {
//   let param = Object.assign({},paramInfo,{function: 'paypalSucceedBack'})
//   return axios.post('./index.php', param)
//     .then(function (response) {
//       return response.data
//     })
//     .catch(function (error) {
//       console.log(error);
//     })
// }

export function getNotice(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getNotice' })
    return axios.post('./index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}

export function getStudentApplications(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'getStudentApplications' })
    return axios.post('./index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function loginByStudent({
    student_email,
    student_pwd,
    project_code,
    login_type
}) {
    return axios.post('./index.php', {
            function: 'loginByStudent',
            student_pwd,
            student_email,
            project_code,
            login_type
        })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}

export function loginByContact({
    contact_email,
    contact_pwd,
    project_code
}) {
    return axios.post('./index.php', {
            function: 'loginByContact',
            contact_pwd,
            contact_email,
            project_code
        })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}

export function sendNewPwd({
    email,
    type
}) {
    return axios.post('./index.php', {
            function: 'sendNewPwd',
            email,
            type,
        })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function loginByAccountAdmin({
    account_email,
    account_pwd
}) {
    return axios.post('./index.php', {
            function: 'loginByAccountAdmin',
            account_email,
            account_pwd,
        })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}

export function getStripeUrl(application_id, content, check_status) {
    return axios.post('./index.php', {
        function: 'getStripeUrl',
        application_id,
        content,
        check_status
    })
}

export function loginByAdmin({
    account_email,
    account_pwd,
    project_code
}) {
    return axios.post('./index.php', {
            function: 'loginByAdmin',
            account_email,
            account_pwd,
            project_code
        })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}

export function updateAccount(upateForm) {
    let param = Object.assign({}, upateForm, { function: 'updateAccount' })
    console.log(param)
    return axios.post('./index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function updateAccountContact(upateForm) {
    let param = Object.assign({}, upateForm, { function: 'updateAccountContact' })
    console.log(param)
    return axios.post('./index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function updateAccountProject(upateForm) {
    let param = Object.assign({}, upateForm, { function: 'updateAccountProject' })
    console.log(param)
    return axios.post('./index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function updateTemplate(upateForm) {
    let param = Object.assign({}, upateForm, { function: 'updateTemplate' })
    return axios.post('./index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function updatetemplateblock(upateForm) {
    let param = Object.assign({}, upateForm, { function: 'updatetemplateblock' })
    return axios.post('./index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function updateAccountStudent(upateForm) {
    let param = Object.assign({}, upateForm, { function: 'updateAccountStudent' })
    return axios.post('./index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function getCollegeProjects(upateForm) {
    let param = Object.assign({}, upateForm, { function: 'getCollegeProjects' })
    return axios.post('./index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function addApplication(upateForm) {
    let param = Object.assign({}, upateForm, { function: 'addApplication' })
    return axios.post('./index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function getNextProject(upateForm) {
    let param = Object.assign({}, upateForm, { function: 'getNextProject' })
    return axios.post('./index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function addNewProjectApplication(upateForm) {
    let param = Object.assign({}, upateForm, { function: 'addNewProjectApplication' })
    return axios.post('./index.php', param)
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function loginByAccount({ account_email, account_pwd }) {
    return axios.post('./index.php', {
            function: 'loginByAccount',
            account_pwd,
            account_email,
        })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function getAccountContact({ id }) {
    return axios.post('./index.php', {
            function: 'getAccountContact',
            id
        })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function getAccountContacts({ account_id }) {
    return axios.post('./index.php', {
            function: 'getAccountContacts',
            account_id,
        })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function getAccount({ account_id }) {
    return axios.post('./index.php', {
            function: 'getAccount',
            account_id
        })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}

export function gettemplates({ account_id }) {
    return axios.post('./index.php', { function: 'gettemplates', account_id: account_id })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function gettemplate({ template_id }) {
    return axios.post('./index.php', {
            function: 'gettemplate',
            template_id
        })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function gettemplateblocks({ id }) {
    return axios.post('./index.php', { function: 'gettemplateblocks', id: id })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function gettemplateblock({ id }) {
    return axios.post('./index.php', {
            function: 'gettemplateblock',
            id
        })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function getAccountStudent({ id }) {
    return axios.post('./index.php', {
            function: 'getAccountStudent',
            id
        })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function getAccounts() {
    return axios.post('./index.php', { function: 'getAccounts' })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function getAccountProjects({ account_id }) {
    return axios.post('./index.php', {
            function: 'getAccountProjects',
            account_id,
        })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function getAccountProject({ id }) {
    return axios.post('./index.php', {
            function: 'getAccountProject',
            id
        })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function getAccountStudents({ account_id }) {
    return axios.post('./index.php', {
            function: 'getAccountStudents',
            account_id,
        })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}


export function getStudentClasses({
    student_id
}) {
    return axios.post('./index.php', {
            function: 'getStudentClasses',
            student_id
        })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}


export function getContents({
    session_id,
    student_id
}) {
    return axios.post('./index.php', {
            function: 'getContents',
            session_id,
            student_id
        })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}

export function getContent({
    content_id,
    student_id
}) {
    return axios.post('./index.php', {
            function: 'getContent',
            content_id,
            student_id

        })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}

export function resetQuiz(paramInfo) {
    let param = Object.assign({}, paramInfo, { function: 'resetQuiz' })
    return axios.post('./index.php', param).then(function(response) {
        return response.data
    }).catch(function(error) {
        console.log(error);
    })
}

export function uploadQuiz({
    quiz_id,
    student_id,
    quiz_answer,
}) {
    return axios.post('./index.php', {
            function: 'uploadQuiz',
            quiz_id,
            student_id,
            quiz_answer,
        })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}

export function getStudentProjects({
    student_id
}) {
    return axios.post('./index.php', {
            function: 'getStudentProjects',

            student_id,

        })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}


export function getTemplate({
    template_id
}) {
    return axios.post('./index.php', {
            function: 'getTemplate',
            template_id
        })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}

export function getStudentBlock({
    team_code,
    project_id,
    student_id,
    block_id
}) {
    return axios.post('./index.php', {
            function: 'getStudentBlock',
            team_code,
            project_id,
            student_id,
            block_id
        })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}


export function updateStudentBlock({
    team_code,
    project_id,
    account_id,
    block_id,
    block_content
}) {
    return axios.post('./index.php', {
            function: 'updateStudentBlock',
            team_code,
            project_id,
            account_id,
            block_id,
            block_content
        })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}

export function getProjectTeamcodes({
    project_id
}) {
    return axios.post('./index.php', {
            function: 'getProjectTeamcodes',
            project_id
        })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}

export function loginByEducator({
    educator_pwd,
    educator_email,
}) {
    return axios.post('./index.php', {
            function: 'loginByEducator',
            educator_pwd,
            educator_email,
        })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}

export function uploadStaticBlockAttach({
    block_id,
    project_id,
    account_id,
    team_code,
    attach_name,
    attach_type
}) {
    return axios.post('./index.php', {
            function: 'uploadStaticBlockAttach',
            block_id,
            project_id,
            account_id,
            team_code,
            attach_name,
            attach_type
        })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}

export function netService({
    functionName,
    ...p
}) {
    return axios.post('./index.php', {
            function: functionName,
            ...p
        })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}

export function upload({
    param,
    config
}) {
    console.log(param)
    return axios.post('./index.php',
            param, config)
        .then(response => {
            console.log(response.data);
            return response.data
        })
}

export function getCertifiTemplatesByProject({ project_id }) {
    return axios.post('./index.php', {
        function: 'getCertifiTemplatesByProject',
        project_id
    }).then(function(response) {
        return response.data
    }).catch(function(error) {
        console.log(error)
    })
}

export function export_csv({ data, title, file_name }) {
    return axios.post('./index.php', {
        function: 'export_csv',
        data,
        title,
        file_name
    }).then(function(response) {
        return response
    }).catch(function(error) {
        console.log(error)
    })
}

export function paypalSucceedBack(application_id, data) {
    return axios.post('./index.php', {
        function: 'paypalSucceedBack',
        application_id,
        data
    }).then(function(response) {
        return response
    }).catch(function(error) {
        console.log(error)
    })
}


export function getLoginProject(project_code, user_id) {
    return axios.post('./index.php', {
        function: 'getLoginProject',
        project_code,
        user_id
    })
}

export function saveImportDataNew(contact_id, project_code, data) {
    return axios.post('./index.php', {
        function: 'saveImportDataNew',
        contact_id,
        project_code,
        data
    })
}

export function saveImportDataPackage(contact_id, project_code, data) {
    return axios.post('./index.php', {
        function: 'saveImportDataPackage',
        contact_id,
        project_code,
        data
    })
}

export function saveNimImportData(contact_id, project_code, data) {
    return axios.post('./index.php', {
        function: 'saveNimImportData',
        contact_id,
        project_code,
        data
    })
}


export function getStudentNIMApplication(student_id, project_code, project_BU) {
    return axios.post('./index.php', {
        function: 'getStudentNIMApplication',
        student_id,
        project_code,
        project_BU
    })
}


export function removeNimApplication(id) {
    return axios.post('./index.php', {
        function: 'removeNimApplication',
        id
    })
}

export function getOxPayQrUrl(application_id) {
    return axios.post('./index.php', {
        function: 'getOxPayQrUrl',
        application_id
    })
}

export function getPayStatus(application_id) {
    return axios.post('./index.php', {
        function: 'getPayStatus',
        application_id
    })
}


export function getApplicationByID(id) {
    return axios.post('./index.php', {
        function: 'getApplicationByID',
        id
    })
}



export function setNimApplicationLeader(id) {
    return axios.post('./index.php', {
        function: 'setNimApplicationLeader',
        id
    })
}

export function setNimApplicationMember(id) {
    return axios.post('./index.php', {
        function: 'setNimApplicationMember',
        id
    })
}



export function updateNimApplicationTeamCode(id, team_code) {
    return axios.post('./index.php', {
        function: 'updateNimApplicationTeamCode',
        id,
        team_code
    })
}


export function createNewTeamCode(id) {
    return axios.post('./index.php', {
        function: 'createNewTeamCode',
        id,
    })
}

export function searchTeamByLeaderEmail(email, project) {
    return axios.post('./index.php', {
        function: 'searchTeamByLeaderEmail',
        email,
        project
    })
}


export function saveTeamCode(applications) {
    return axios.post('./index.php', {
        function: 'saveTeamCode',
        applications
    })
}

export function getProjects() {
    return axios.post('./index.php', {
        function: 'getProjects'
    })
}


export function getProjectDetail(project_code, student_id) {
    return axios.post('./index.php', {
        function: 'getProjectDetail',
        project_code,
        student_id
    })
}

export function applyEcoplore(project_id, student_id) {
    return axios.post('./index.php', {
        function: 'applyEcoplore',
        project_id,
        student_id
    })
}

export function getEducatorProjects(educator_id) {
    return axios.post('./index.php', {
        function: 'getEducatorProjects',
        educator_id
    })
}

export function getEducatorCanProjects(educator_id) {
    return axios.post('./index.php', {
        function: 'getEducatorCanProjects',
        educator_id
    })
}

export function applyProjectByEducator(educator_id, project_id) {
    return axios.post('./index.php', {
        function: 'applyProjectByEducator',
        educator_id,
        project_id
    })
}

export function getProjectsByPackageApplication(package_application_id) {
    return axios.post('./index.php', {
        function: 'getProjectsByPackageApplication',
        package_application_id
    })
}

export function initApplicationInPackage(package_application_id, project_id) {
    return axios.post('./index.php', {
        function: 'initApplicationInPackage',
        package_application_id,
        project_id
    })
}

export function cancelApplicationInPackage(application_id) {
    return axios.post('./index.php', {
        function: 'cancelApplicationInPackage',
        application_id
    })
}

export function getMyFinishedHours(student_id) {
    return axios.post('./index.php', {
        function: 'getMyFinishedHours',
        student_id
    })
}

export function getSessions(class_id) {
    return axios.post('./index.php', {
        function: 'getSessions',
        class_id
    })
}

export function getContentMessages(user_id, content_id) {
    return axios.post('./index.php', {
        function: 'getContentMessages',
        user_id,
        content_id
    })
}

export function updateContentMessage(user_id, content_id, content) {
    return axios.post('./index.php', {
        function: 'updateContentMessage',
        user_id,
        content_id,
        content
    })
}

export function getStudentPackages(student_id, project_code) {
    return axios.post('./index.php', {
        function: 'getStudentPackages',
        student_id,
        project_code
    })
}
export function getPackageApplication(id) {
    return axios.post('./index.php', {
        function: 'getPackageApplication',
        id
    })
}
export function getPackageOrder(application_id, content, check_status) {
    return axios.post('./index.php', {
        function: 'getPackageOrder',
        application_id,
        content,
        check_status
    })
}


export function getOxPayment(application_id, content, check_status) {
    return axios.post('./index.php', {
        function: 'getOxPayment',
        application_id,
        content,
        check_status
    })
}


export function getStudentCommitAttaches(student_id, project_id) {
    return axios.post('./index.php', {
        function: 'getStudentCommitAttaches',
        student_id,
        project_id
    })
}

export function updateCommitAttach(param) {
    return axios.post('./index.php', param)
}

export function removeCommitAttach(id) {
    return axios.post('./index.php', {
        function: 'removeCommitAttach',
        id
    })
}


export function getCertificatesInfo(student_id) {
    return axios.post('./index.php', {
        function: 'getCertificatesInfo',
        student_id
    })
}

export function applyCancelApplication(project_id, student_id, cancel_select, cancel_info) {
    return axios.post('./index.php', {
        function: 'applyCancelApplication',
        project_id,
        student_id,
        cancel_select,
        cancel_info
    })
}

export function getStudentAppList(student_id) {
    return axios.post('./index.php', {
        function: 'getStudentAppList',
        student_id
    })
}

export function getThinkingQuestionsList(student_id, station_id) {
    return axios.post('./index.php', {
        function: 'getThinkingQuestionsList',
        student_id,
        station_id
    })
}


export function getThinkingQuestionsDetail(student_id, question_id, project_code) {
    return axios.post('./index.php', {
        function: 'getThinkingQuestionsDetail',
        student_id,
        question_id,
        project_code
    })
}


export function updateThinkingQuestionsAnswer(student_id, question_id, project_code, answer, imgs) {
    return axios.post('./index.php', {
        function: 'updateThinkingQuestionsAnswer',
        student_id,
        question_id,
        project_code,
        answer,
        imgs
    })
}



export function getAccountScores(user_id, project_code) {
    return axios.post('./index.php', {
        function: 'getAccountScores',
        user_id,
        project_code
    })
}


export function getProjectConversionApps(student_id, project_code) {
    return axios.post('./index.php', {
        function: 'getProjectConversionApps',
        student_id,
        project_code
    })
}



export function getQNToken() {
    return axios.post('./index.php', {
        function: 'getQNToken'
    })
}

export function getStudentScores(user_id, project_code) {
    return axios.post('./index.php', {
        function: 'getStudentScores',
        user_id,
        project_code
    })
}

export function getStudentAwards(student_id, project_code) {
    return axios.post('./index.php', {
        function: 'getStudentAwards',
        student_id,
        project_code
    })
}

export function uploadStudentTuitionTier(student_id, project_code, url) {
    return axios.post('./index.php', {
        function: 'uploadStudentTuitionTier',
        student_id,
        project_code,
        url
    })
}

export function createLoginOPT(student_email) {
    return axios.post('./index.php', {
        function: 'createLoginOPT',
        student_email
    })
}

export function getCountries() {
    return axios.post('./index.php', {
        function: 'getCountries'
    })
}

export function saveAccountForm(form) {
    return axios.post('./index.php', {
        function: 'saveAccountForm',
        form
    })
}

export function submitProject(application_id) {
    return axios.post('./index.php', {
        function: 'submitProject',
        application_id
    })
}

export function getStudentAwardsByApplication(application_id) {
    return axios.post('./index.php', {
        function: 'getStudentAwardsByApplication',
        application_id
    })
}

export function getHistoryProjectTracksByAdvisor(contact_id, project_year) {
    return axios.post('./index.php', {
        function: 'getHistoryProjectTracksByAdvisor',
        contact_id,
        project_year
    })
}

export function getCurrentProjectProjectTracksByAdvisor(contact_id, project_year) {
    return axios.post('./index.php', {
        function: 'getCurrentProjectProjectTracksByAdvisor',
        contact_id,
        project_year
    })
}

export function getCurrentProjects(project_year) {
    return axios.post('./index.php', {
        function: 'getCurrentProjects',
        project_year
    })
}

export function addApplicationByProject(params) {
    return axios.post('./index.php', {
        function: 'addApplicationByProject',
        ...params
    })
}