<template>
  <article>
    <div>
      <h4>题干</h4>
      <div> {{ question.question_title }}</div>

      <el-input style="margin-top:20px" type="textarea" :rows="10" v-model="userAnswer" placeholder="请写下你的答案"></el-input>


      <el-button style="margin-top:20px" type="primary" icon="el-icon-picture" @click="addImg()" >添加图片</el-button>

      <div style="margin-top:20px" v-if="selectImgIds.length > 0"> {{  '已选择'+ selectImgIds.length + '张图片' }}</div>

      <br>

      <el-button style="margin-top:20px" @click="submit()" type="primary" >提交</el-button>

    </div>


    <el-dialog class="dialog" title="选择图片" :visible.sync="showImgs" :close-on-click-modal="false"
      width="60%">

      <div> Tip:此图片库为同组学生在小程序上传,同组共用</div>
      <div class="imgClass">

        <div v-for="(item,index) in question.all_img" :key="item._id.$id" @click="selectImg(index)">
        
          <div>
            {{ item.title }}
          </div>
        
          <img class="imgStyle" :src="item.img_url" oncontextmenu="return false" onselectstart="return false" ondragstart="return false">

          <div v-if="item.select == true">已选择</div>

        </div>

      </div>


      <el-button style="margin-top:20px" @click="submitSlectImg()" type="primary" >确认</el-button>

      
    </el-dialog>

  </article>
</template>
<script>
import { updateThinkingQuestionsAnswer ,getThinkingQuestionsDetail} from "../service.js";

import Cookie from "js-cookie";
export default {
  data() {
    return {
      loadDialog: false,
      pswForm: {},
      formLabelWidth: "140px",
      studentid: Cookie.get("loginId"),
      
      accountCode:this.$route.query.accountCode,
      questionId:this.$route.query.questionId,
      projectCode:this.$route.query.projectCode,
      
      question:{},
      userAnswer:'',
      showImgs:false,
      selectImgIds:[]
    };
  },
  created() {
    this.getStudentApplications()
  },
  mounted() {},
  methods: {

    getStudentApplications(){

      console.log(11111123,this.accountCode,this.questionId,this.projectCode)

      getThinkingQuestionsDetail(this.accountCode,this.questionId,this.projectCode).then(res=>{
        this.question = res.data
            
        if (this.question.is_answer == false){

          var arr = this.question.all_img

          var newArr = []
          for (var i = 0; i < arr.length; i++) {
            var selectItem = arr[i]
            selectItem.select = false
            newArr.push(selectItem)
          }

          this.question.all_img = newArr

        }else{


          var arr = this.question.all_img
          var answerDic = this.question.answer

          this.userAnswer = answerDic.answer


          this.selectImgIds = answerDic.imgs

          var haveSelectImg = answerDic.imgs


          console.log(1111,haveSelectImg)

          var newArr = []
          for (var i = 0; i < arr.length; i++) {
            var selectItem = arr[i]

            if (haveSelectImg.includes(selectItem._id.$id)){
              selectItem.select = true
            }else{
              selectItem.select = false
            }
            newArr.push(selectItem)
          }

          this.question.all_img = newArr

        }

      })
    },
    submit(){

      // student_id,question_id,project_code,answer,imgs

      if (this.userAnswer.length <= 0){

        this.$message.error('请输入内容');
        return
      }



      updateThinkingQuestionsAnswer(this.accountCode,this.questionId,this.projectCode,this.userAnswer,this.selectImgIds).then(res=>{
        
        this.$message.success('提交成功')

      })
    },
    submitSlectImg(){


      var arr = this.question.all_img

      this.selectImgIds = []
      for (var i = 0; i < arr.length; i++) {
        var selectItem = arr[i]
        if (selectItem.select == true){
          this.selectImgIds.push(selectItem._id.$id)
        }
      }

      this.showImgs = false

    },
    selectImg(index){

      var arr = this.question.all_img

      var newArr = []

      for (var i = 0; i < arr.length; i++) {
        var selectItem = arr[i]

        if (index == i){


          if (selectItem.select == false || selectItem.select == undefined){
            selectItem.select = true
          }else{
            selectItem.select = false
          }
        }

        newArr.push(selectItem)

      }

      this.question.all_img = newArr

    },
    addImg(){

      this.showImgs = true
    }
   
  },
};
</script>
<style lang="scss" scoped>
article {
  margin-top: 50px;
  background: #fff;
  padding: 20px;
  // height: calc(100vh - 170px);
  box-sizing: border-box;
  /deep/ .el-form.el-form--inline {
    background-color: #f8f8f8;
    margin-bottom: 10px;
    padding: 8px;
    overflow: hidden;
  }
  /deep/ .el-form.el-form--inline .el-form-item.el-form-item--medium {
    margin-right: 4px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}


.imgClass{
  margin-top: 10px;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 300px;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 10px;
}
.imgStyle{
  margin-top: 10px;
  object-fit: cover;
  object-position: 50% 20%;
  width: 200px;
  height: 200px;
}


.formP {
  margin: 0;
  padding: 0;

  padding-left: 10px;
  // border:1px solid #e9eef3;
  background: #f8f9fa;
  border-radius: 4px;
  width: 100%;
  position: relative;
}
.updateBtn {
  width: 150px;
  margin-left: 100px;
}
.partP {
  margin: 0;
  padding: 0;
  height: 40px;
  line-height: 40px;
  left: 21px;
  font-size: 16px;
  padding-left: 10px;
  position: relative;
  margin-bottom: 20px;
}
</style>