<template>
  <article>
    <div class="homeContainer">
      <div class="leftSide">
        <el-menu style="background-color: #dce3f1;" :default-active="defevent">
          <template v-for="(menu, index) in menuList">
            <el-submenu v-if="menu.trackList" :key="index" :index="'' + (index + 1)">
              <template slot="title">
                <span>{{ menu.title }}</span>
              </template>
              <el-menu-item-group v-for="(track, trackIndex) in menu.trackList" :key="trackIndex">
                <el-menu-item :key="j" :index="(index + 1) + '-' + (trackIndex + 1)" @click="showTrackInfo(track)">{{
                  track.track_name
                  }}</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-menu-item v-else :key="index" :index="'' + (index + 1)" @click="fetchData(index, menu.project_year)">
              {{ item.title }}
            </el-menu-item>
          </template>
        </el-menu>
      </div>
      <div class="right" v-if="selectTrack.showData == true">
        <el-tabs v-model="activeName">
          <el-tab-pane label="Student List" name="first">
            <div style="clear: both;"></div>
            <el-table class="tabStyle" height="70vh" :data="selectTrack.students"
              header-cell-style="color:#333;background-color: #ffb414;" size="medium" border ref="leftTable"
              style="margin-top: 20px;" empty-text="No Data">
              <el-table-column label="Project" align="center">
                <template slot-scope="scope">
                  {{ scope.row.application_project + ' (' + scope.row.application_events[0] + ')' }}
                </template>
              </el-table-column>

              <el-table-column label="Name" align="center">
                <template slot-scope="scope">
                  {{ scope.row.student_info.student_givenName_pinyin + ' ' +
                    scope.row.student_info.student_lastName_pinyin }}
                </template>
              </el-table-column>
              <el-table-column label="Email" align="center">
                <template slot-scope="scope">
                  {{ scope.row.student_info.student_email }}
                </template>
              </el-table-column>
              <el-table-column label="Graduation Year" width="100px" align="center">
                <template slot-scope="scope">
                  {{ scope.row.student_info.student_graduation }}
                </template>
              </el-table-column>
              <el-table-column label="Team" width="100px" align="center">
                <template slot-scope="scope">
                  <el-select :disabled="true" v-model="scope.row.application_team_code" placeholder="Select">
                    <el-option v-for="i in 20" :key="i" :value="i" :label="i"></el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column label="Role" width="100px" align="center">
                <template slot-scope="scope">
                  {{ scope.row.application_team_role ? (scope.row.application_team_role == '队员' ? 'Member' : 'Leader') :
                    '-' }}
                </template>
              </el-table-column>
              <el-table-column label="Operation" width="200px" align="center">
                <template slot-scope="scope">
                  <div style="width: 200px;float: left;">
                    <el-button type="primary" v-if="scope.row.award" class="manageStyle2"
                      @click="gotoAwardView(scope.row)">Certificate</el-button>
                    <el-button type="primary" v-else
                      style="background-color: #F2F2F2;border: none;color: #666;cursor:default">Not Awarded</el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="Project List" name="second">
            <div style="clear: both;"></div>
            <div class="projectList">
              <div class="itemStyle" v-for="(item, index) in selectTrack.projects" :key="index">
                <div class="teamCodeStyle">
                  #{{ item.application_team_code }}
                </div>
                <div class="projectTitleStyle">
                  {{ item.event }}
                </div>

                <div class="leaderStyle">
                  Leader: {{ item.leaderName }}
                </div>

                <div class="memberStyle">
                  Members: {{ item.memberName }}
                </div>

                <div class="infoStyle">
                  <el-button type="primary" size="small" v-if="!item.team_award"
                    style="background-color: #F2F2F2;border: none;color: #666;cursor:default">Not Awarded</el-button>
                  <el-button type="primary" size="small" v-if="item.team_award" style="border: none;cursor:default">{{
                    item.team_award.award_lable }}</el-button>
                  <div style="color:#ffb414;margin-right: 15px;">
                    Score: {{ item.team_score ? item.team_score.total_score : 'No Score' }}
                  </div>
                </div>
                <el-button type="primary" class="manageStyle" @click="gotoSummaryView(item)">View</el-button>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </article>
</template>
<script>
import {
  getHistoryProjectTracksByAdvisor,
  getSchoolInfo,
  getSchoolInfoByAccount
} from "../service.js";

import Cookie from "js-cookie";
export default {
  data() {
    return {
      menuList: [
        {
          title: '2024 Next Idea Matters',
          project_year: '23-24',
          trackList: []
        }
      ],
      selectMenuIndex: 0,
      schoolName: "",
      schoolInfoData: {},
      loginType: Cookie.get("loginType"),
      loginId: Cookie.get("loginId"),
      loading: false,
      activeName: 'first',
      defevent: 0,
      selectTrack: {
        showData: false
      }
    };
  },
  mounted() {
    if (this.loginType == '教师') {
      this.fetchData(0, '23-24'); //获取学校信息
    } else if (this.loginType == '学校管理员') {
      this.getSchoolInfoByAccount();
    }
  },
  methods: {


    gotoSummaryView(item) {
      let routeUrl = this.$router.resolve({
        path: "toPrint",
        query: {
          project_id: item.project_id,
          account_id: item.account_id,
          team_code: item.application_team_code,
          project_product: 'NIM',
          block_id: '',
          template_id: item.template_id
        }
      });
      window.open(routeUrl.href, '_blank');
    },


    gotoAwardView(application) {
      this.$router.push({
        path: '/cert',
        query: {
          id: application._id.$id
        }
      })
    },

    showTrackInfo(track) {
      this.selectTrack = track
      this.selectTrack.showData = true
    },

    fetchData(index, project_year) {

      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      this.selectTrack.showData = false
      this.selectMenuIndex = index
      let contact_id = Cookie.get("loginId")
      getHistoryProjectTracksByAdvisor(contact_id, project_year).then((res) => {
        let result = res.data
        if (result) {
          this.menuList[this.selectMenuIndex].trackList = result
        } else {
          this.menuList[this.selectMenuIndex].trackList = []
        }
        loading.close()
      })
    },

    getSchoolInfo() {
      this.loading = true
      let param = {
        user_id: Cookie.get("loginId"),
      };
      getSchoolInfo(param).then((res) => {
        this.loading = false
        if (res.code == 0) {
          this.schoolInfoData = res.data;
          console.log(this.schoolInfoData)
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getSchoolInfoByAccount() {
      let param = {
        account_id: Cookie.get("loginId"),
      };
      getSchoolInfoByAccount(param).then((res) => {
        if (res.code == 0) {
          this.schoolName = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
article {

  background-color: rebeccapurple;
  // margin-left: 100px;
  // margin-top: 50px;
  background: #fff;
  // padding: 0 10px;


  .infoContent {
    padding: 20px 0;
    height: 100%;
    width: 50%;
  }

  .homeContainer {
    height: calc(100vh - 58px);
    width: 100%;
    display: flex;
    overflow: hidden;
    background: #fff;
    font-family: "DDINAlternateRegular";

    .leftSide {
      height: 100%;
      width: 260px;
      flex-shrink: 0;
      background: #fff;
      box-shadow: 2px 0px 12px 1px rgba(96, 91, 255, 0.07);

      .el-menu {
        border: none;
        background: none;
      }

      .el-menu-item {
        color: #204386 !important;
      }

      .el-menu-item.is-active {
        background: #ffb414;
        color: #204386 !important;
      }

      .el-menu-item:focus,
      .el-menu-item:hover {
        background: #ffb414;
        color: #204386 !important;
      }

      .icon {
        width: 32px;
        height: 32px;
        margin-right: 10px;
      }
    }

    .right {
      margin-left: 40px;
      flex: 1;
      height: 100%;
      background: #fff;
      overflow: hidden;
      position: relative;
    }

    .tabStyle {
      height: 70vh;
      width: 96%;
      // background-color: #204386;
      overflow: hidden;
      overflow-y: auto;

    }
  }

  .adorn {
    background-color: #ffb414;
    text-align: center;
    color: #333;
    font-weight: normal;
    // border-bottom: 2px solid #ebeef5!important;
  }

  .btn-style {
    border: none;
    padding: 0px;
    background-color: white;
    background: white;
    color: #333;
    // background-color: #ffb414;
    float: left;
  }

  .sortSelect {
    background-color: #ffb414;
    border-color: #ffb414;
    color: white;
  }

  .sortNoSelect {
    background-color: white;
    border-color: #333;
    color: #333;
  }

  .projectList {
    margin-top: 20px;
    // background-color: red;
    height: 70vh;
    width: 90%;
    overflow-y: auto;
  }

  .itemStyle {
    // margin-left: 20px;
    // margin-right: 20px;
    margin-top: 15px;
    position: relative;
    height: 150px;
    background-color: oldlace;
    border-radius: 5px;
  }

  .teamCodeStyle {
    padding-top: 10px;
    margin-left: 10px;
    color: #333;
    font-size: 15px;
  }

  .projectTitleStyle {
    margin-left: 10px;
    margin-top: 5px;
    color: #333;
    font-size: 22px;
  }

  .leaderStyle {
    margin-left: 10px;
    margin-top: 25px;
    color: #333;
    font-size: 18px;
  }

  .memberStyle {
    margin-left: 10px;
    margin-top: 5px;
    color: #333;
    font-size: 18px;
  }

  .infoStyle {
    position: absolute;
    right: 10px;
    top: 15px;
    width: 80%;
    text-align: right;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
  }

  .manageStyle {
    position: absolute;
    bottom: 15px;
    right: 10px;
    background-color: #ffb414;
    border: none;
    color: #333;
  }

  .eventLinkStyle {
    margin-left: 10px;
    margin-right: 10px;
    height: 40px;
    margin-top: 10px;
    width: calc(100% - 20px);
  }

  .eventName {
    color: #333;
    padding-right: 10px;
    // width: 230px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    // font-size: 18px;
    background-color: oldlace;
    // float: left;

  }

  .linkStyle {
    height: 30px;
    padding-left: 10px;
    padding-right: 10px;
    line-height: 30px;
    background-color: #f5f5f5;
    overflow: hidden;
    /* 隐藏超出内容的区域 */
    white-space: nowrap;
    /* 不允许文本折行 */
    text-overflow: ellipsis;
    /* 显示省略号代替超出部分的文本 */
  }

  .shareBtn {
    line-height: 30px;
    width: 80px;
    height: 30px;
    text-align: center;
    background-color: #ffb414;
    cursor: pointer;

  }

}
</style>