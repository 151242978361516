<template>
    <article>
        <el-table style="margin-top:10px" :data="projects" v-loading="dataLoading" header-cell-class-name="adorn" size="medium" border ref="leftTable" class="left-table">
            <el-table-column label="项目名称" align="center" prop="project_name"></el-table-column>
            <el-table-column label="项目代码" align="center" prop="project_code"></el-table-column>
            <el-table-column label="监测点" align="center">
                <template slot-scope="scope">
                    {{scope.row.station.district+scope.row.point.name}}
                </template>
            </el-table-column>
            <el-table-column label="开始时间" align="center">
                <template slot-scope="scope">
                    {{formateDate(scope.row.start_time)}}
                </template>
            </el-table-column>
            <el-table-column label="结束时间" align="center">
                <template slot-scope="scope">
                    {{formateDate(scope.row.end_time)}}
                </template>
            </el-table-column>
            <!-- <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-button @click="apply(scope.row._id.$id)" v-if="!scope.row.apply || scope.row.apply.status==='reject'" size="medium" type="primary">报名</el-button>
                    <div v-if="scope.row.apply && scope.row.apply.status!='reject'">{{statusMap[scope.row.apply.status]}}</div>
                </template>
            </el-table-column> -->
        </el-table>
    </article>
</template>

<script>
import {getEducatorCanProjects,applyProjectByEducator} from '../service'
import Cookie from "js-cookie";
export default {
    data(){
        return{
            projects:[],
            dataLoading:false,
            statusMap:{
                pending:'审核中',
                approve:'通过',
                reject:'拒绝'
            }
        }
    },
    mounted(){
        this.getEducatorCanProjects()
    },
    methods:{
        formateDate(date){
            let time = new Date(date*1000)
            return `${time.getFullYear()}-${time.getMonth()+1}-${time.getDate()} ${time.getHours()}:${time.getMinutes()>9?time.getMinutes():'0'+time.getMinutes()}`;
        },
        getEducatorCanProjects(){
            this.dataLoading=true
            getEducatorCanProjects(Cookie.get('loginId')).then(res=>{
                this.dataLoading = false
                if(res.data.code===0){
                    this.projects = res.data.data
                }
            })
        },
        apply(id){
            this.$confirm('是否确认报名?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(()=>{
                    applyProjectByEducator(Cookie.get('loginId'),id).then(res=>{
                        if(res.data.code===0){
                            this.$message.success('报名成功')
                        }
                        this.getEducatorCanProjects()
                    })
                })
        }
    }
}
</script>

<style>

</style>