<template>
    <article>
        <!-- <h4>{{ $t('m.loginProject') }}</h4> -->
        <el-table 
          empty-text="No Messages"
         v-loading="loadPackage" :data="projects" header-cell-class-name="adorn" size="medium" border ref="leftTable" class="left-table">
            <el-table-column :label="$t('m.personInfo.name1')" align="center" prop="student_name"></el-table-column>
            <el-table-column :label="$t('m.kemu')" align="center" prop="application_event"></el-table-column>

            <el-table-column :label="$t('m.chengji')" prop="total_score" align="center"></el-table-column>

            <el-table-column :label="$t('m.jiangxiang')" prop="awardStr" align="center"></el-table-column>
            <el-table-column :label="$t('m.jinji')" prop="riseStr" align="center"></el-table-column>

            <!-- <el-table-column width="200" :label="$t('m.caozuo')" align="center">
                <template slot-scope="scope">
                <el-button class="btn-link" @click="toPackage(scope.row)" type="primary" size="small">{{ $t('m.xiangqing') }}</el-button>
                </template>
            </el-table-column> -->
        </el-table>
        
    </article>
</template>
<script>
  import {
    getAccountScores,
  } from "../service.js";

  import Cookie from "js-cookie";
  export default {
    data() {
      return {
        loadDialog: false,
        loadPackage: false,
        teamForm: {
          name: "测试",
        },
        formLabelWidth: "100px",
        schoolForm: {},
        appTable: [],
        paginationLoading: false,
        loginType: Cookie.get("loginType"),
        loginId: Cookie.get("loginId"),
        projects:[],
        packages:[]
      };
    },
    created() {
      if (this.loginType == '教师') {

        this.getLoginProject()
      } else if (this.loginType == '学校管理员') {
        // this.getAccountApplicationsByAccount();
      }

    },
    mounted() {},
    methods: {
       
        getLoginProject(){
            this.loadDialog = true
            getAccountScores( this.loginId,Cookie.get("loginProject")).then(res=>{
                this.loadDialog = false
                if(res.data.code===0){
                    this.projects = res.data.data
                }
            })
        },
       
        formateDate(date){
            let time = new Date(date*1000)
            return `${time.getFullYear()}-${time.getMonth()+1}-${time.getDate()}`;
        }
    },
  };
</script>
<style lang="scss" scoped>
  article {
    margin-top: 50px;
    background: #fff;
    padding: 10px;

    /deep/ .el-form.el-form--inline {
      background-color: #f8f8f8;
      margin-bottom: 10px;
      padding: 8px;
      overflow: hidden;
    }

    /deep/ .el-form.el-form--inline .el-form-item.el-form-item--medium {
      margin-right: 4px;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

  .el-table .cell.el-tooltip {
    white-space: pre-wrap;
  }
  .formP {
    margin: 0;
    padding: 0;

    padding-left: 10px;
    // border:1px solid #e9eef3;
    background: #f8f9fa;
    border-radius: 4px;
    width: 70%;
    position: relative;
  }
</style>