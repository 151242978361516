<template>
  <article> 
      
     <el-table  v-loading="loadDialog" :data="appTable" header-cell-class-name="adorn"  size="medium" border ref="leftTable"  class="left-table">
        <el-table-column   type="index"  width="50"> </el-table-column>
        <el-table-column label="学校名称" align="center" prop="account_name" >
      </el-table-column>
      <el-table-column label="物料"  align="center" prop="material_name" ></el-table-column>
      <el-table-column label="数量" align="center" prop="material_quantity" >
      </el-table-column>
    </el-table>
  </article>
</template>
<script>
import { getMaterialList,getAccountMaterialList } from "../service.js";

import Cookie from "js-cookie";
export default {
  data() {
    return {
      loadDialog: false,
      teamForm: {
        name: "测试",
      },
      formLabelWidth: "100px",
      schoolForm: {},
      appTable: [],
      paginationLoading: false,
      loginId: Cookie.get("loginId"),
      loginType: Cookie.get("loginType"),
    };
  },
  created() {
           if(this.loginType=='教师'){
 this.getMaterialList(); //获取学校报名表的列表
    }else if(this.loginType=='学校管理员'){
this.getAccountMaterialList();
    }
  },
  mounted() {},
  methods: {
    getMaterialList() {
      this.dataLoading = true;
      getMaterialList({user_id: Cookie.get("loginId")}).then((res) => {
        this.dataLoading = false;
        if (res.code == 0) {
           this.appTable = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getAccountMaterialList() {
      this.dataLoading = true;
      getAccountMaterialList({account_id: Cookie.get("loginId")}).then((res) => {
        this.dataLoading = false;
        if (res.code == 0) {
           this.appTable = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    editItem(row) {
      this.$router.push({ path: "/applicationDetail", query: { 'project_code': row.project_code } });
    },


  

  },
};
</script>
<style lang="scss" scoped>
article {
  margin-top: 50px;
  background: #fff;
  padding: 10px;
  /deep/ .el-form.el-form--inline {
    background-color: #f8f8f8;
    margin-bottom: 10px;
    padding: 8px;
    overflow: hidden;
  }
  /deep/ .el-form.el-form--inline .el-form-item.el-form-item--medium {
    margin-right: 4px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
.formP {
  margin: 0;
  padding: 0;

  padding-left: 10px;
  // border:1px solid #e9eef3;
  background: #f8f9fa;
  border-radius: 4px;
  width: 70%;
  position: relative;
}
</style>