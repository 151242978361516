<template>
    <article>
        <el-table style="margin-top:10px" :data="sessions" v-loading="dataLoading" header-cell-class-name="adorn" size="medium" border ref="leftTable" class="left-table">
            <el-table-column label="课程名称" align="center" prop="session_info.session_name"></el-table-column>
            <el-table-column label="课程简介" align="center" prop="session_info.session_description"></el-table-column>
           
            <el-table-column label="课程列表" align="center">
                <template slot-scope="scope">
                    <el-button type="primary" size="medium" @click="toContent(scope.row._id.$id)">学习</el-button>
                </template>
            </el-table-column>
        </el-table>
    </article>
</template>

<script>
import {getSessions} from '../service'

export default {
    data(){
        return{
            class_id:this.$route.query.class_id,
            sessions:[],
            dataLoading:false
        }
    },
    mounted(){
        this.getSessions()
    },
    methods:{
        formateDate(date){
            let time = new Date(date*1000)
            return `${time.getFullYear()}-${time.getMonth()+1}-${time.getDate()} ${time.getHours()}:${time.getMinutes()>9?time.getMinutes():'0'+time.getMinutes()}`;
        },
        getSessions(){
            this.dataLoading=true
            getSessions(this.class_id).then(res=>{
                this.dataLoading = false
                if(res.data.code===0){
                    this.sessions = res.data.data
                }
            })
        },
        toContent(id){
            this.$router.push({
                path:'/home',
                query:{
                    session_id:id
                }
            })
        }
    }
}
</script>

<style>

</style>