<template>
  <article>
    <el-form
      class="block-form"
      :model="pswForm"
      :rules="rules"
      ref="pswForm"
      status-icon
      :label-width="formLabelWidth"
      size="medium"
    >
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item :label="$t('m.personInfo.initialPassword')" prop="initial_password">
            <el-input
              type="password"
              :placeholder="$t('m.personInfo.initialPlaceholder')"
              v-model="pswForm.initial_password"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item :label="$t('m.personInfo.newPassword')" prop="new_password">
            <el-input
              type="password"
              :placeholder="$t('m.personInfo.newPlaceholder')"
              v-model="pswForm.new_password"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item :label="$t('m.personInfo.enterAgain')" prop="new_password_again">
            <el-input
              type="password"
              :placeholder="$t('m.personInfo.enterAgainPlaceholder')"
              v-model="pswForm.new_password_again"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="updateBtn">
        <el-button class="btn-link" type="primary" @click="changePassword('pswForm')"
          >{{$t('m.personInfo.submit')}}</el-button
        >
      </div>
    </el-form>
  </article>
</template>
<script>
import { changePassword } from "../service.js";

import Cookie from "js-cookie";
export default {
  data() {
    return {
      loadDialog: false,
      pswForm: {},
      formLabelWidth: "140px",
      studentid: Cookie.get("loginId"),
      rules: {
        initial_password: [
          { required: true, message: this.$t('m.pwdTip'), trigger: "change" },
        ],
        new_password: [
          { required: true, message: this.$t('m.pwdTip1'), trigger: "change" },
        ],
        new_password_again: [
          { required: true, message: this.$t('m.pwdTip2'), trigger: "change" },
        ],
      },
    };
  },
  created() {
    // this.getAccounts(); //获取学校列表
  },
  mounted() {},
  methods: {
    changePassword(pswForm) {
      let _this = this;
      this.$refs[pswForm].validate((valid) => {
        if (valid) {
          _this.loadDialog = true;
          if (_this.pswForm.new_password != _this.pswForm.new_password_again) {
            this.$message.warning(this.$t('m.pwdTip3'));
          } else {
            let param = {
              student_id: _this.studentid,
              initial_password: _this.pswForm.initial_password,
              new_password: _this.pswForm.new_password,
              new_password_again: _this.pswForm.new_password_again,
            };
            changePassword(param).then((res) => {
              this.loadDialog = false;
              if (res.code == 0) {
                _this.$message.success(this.$t('m.pwdTip4'));
                  Cookie.set("loginId",'');
                _this.$router.push({ name: "login" });
              } else {
                this.$message.error(res.msg);
              }
            });
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
article {
  margin-top: 50px;
  background: #fff;
  padding: 20px;
  // height: calc(100vh - 170px);
  box-sizing: border-box;
  /deep/ .el-form.el-form--inline {
    background-color: #f8f8f8;
    margin-bottom: 10px;
    padding: 8px;
    overflow: hidden;
  }
  /deep/ .el-form.el-form--inline .el-form-item.el-form-item--medium {
    margin-right: 4px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
.formP {
  margin: 0;
  padding: 0;

  padding-left: 10px;
  // border:1px solid #e9eef3;
  background: #f8f9fa;
  border-radius: 4px;
  width: 100%;
  position: relative;
}
.updateBtn {
  width: 150px;
  margin-left: 100px;
}
.btn-link{
    background:#E0A91E;
  }
  /deep/ .el-button {
    border:none;
  }
.partP {
  margin: 0;
  padding: 0;
  height: 40px;
  line-height: 40px;
  left: 21px;
  font-size: 16px;
  padding-left: 10px;
  position: relative;
  margin-bottom: 20px;
}
</style>