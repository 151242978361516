export const m = {
  ch: "Chinese",
  en: "English",
  cancel:"Cancel",
  confirm:'Confirm',
  languageTile: 'Language',
  loginType: 'Type',
  forgetPsw: 'forget password',
  accountName: 'Account',
  password: 'Password',
  login: 'Login',
  teacherTraining:"Teacher Training",
  pleaseChoose: "Please Choose",
  loginType: 'LoginType',
  loginProject: 'Project',

  mingcheng: 'Name',
  jiage: 'Price',
  caozuo: 'Action',
  xiangqing:"Details",
  suozaisheng:"State/Province:",
  suozaichengshi:"City:",
  xuexiaoaddress:"School Address:",
  xuexiaoyoubian:"School Zip Code:",

  importTip:"To import registration forms, you'll need to provide a CSV-format data file using the specified template. The first row of the template serves as the header and cannot be modified. Column order and position must remain unchanged. Student information starts from the second row.",
  importTip1:"The entry form must use the import template, please click to download the file",
  importTip2:"Please try before the formal import, the data is correct after the formal import; If the data is incorrect after formal import, you need to manually modify it, which is very troublesome.",
  importTip3:"Confirm",


  shikanjiancha:"Trial Import",
  zhengshidaoru:"Formal Import",
  xuanzewenjian:"Please select file",


  loginTip:"Please enter the username",
  loginTip1:"Please enter the password",
  loginTip2:"Please select the identity.",
  loginTip3:"Please select the project.",


  accountNamePlaceholder: "pleast input account",
  passwordPlaceholde: "please input password",
  registrationInfo: "Registration Info",
  registrationChengji: "Score viewing",

  chengji: "Score",
  kemu: "Event",
  jinji:"Promotion Status",
  jiangxiang:"Award",

  messages: "Messages",
  resourceCenter: "Resource Center",
  studentResourceCenter: "Student Resource Center",
  adminText: 'Administrator Resource Center',
  schoolResourceCenter: "School Resource Center",
  registerInfo: "Register Info",
  projectRegistration:"Project Registration",
  languageInfo: "Choose Language",
  logOut: "Log Out",
  tips1: "*Please use Chrome, Safari or IE browsers for system access. Using other browsers, especially secure browsers (such as 360 browser, IE browser, green browser, etc.), will cause incompatible issues, which may affect the viewing of content. ",
  tips2: "*Username is your registered email address. All letters should be lowercase. Non-lowercase letters will be considered as account errors.",
  addApplicationForm: "Add Application Form",
  importApplicationForm: "Import Application Form",
  exportApplicationForm: "export Application Form",
  teamInfo: "Team Info",

  xuhao: "No.",
  duiwu:"Team",
  chengyuanleixing:"Member Type",
  jiaofeixinxi:"Payment information",
  jiaofeixudao:"Payment Method:",
  jiaofeifangshi:"Payment Type:",
  jiaofeiriqi:"Payment Date:",
  jiaofeijine:"Amount of Payment:",
  jiaofeitongzhi:"Agree the Payment Notice:",
  dengluyouxiang:"Email:",
  tongduichengyuan:"Team Members",
  qianwangjiaofei:"Pay Now",
  payShow:"Please use the Oxpay account over 16 years old to scan the QR code. Once the QR code has been scanned, you cannot use other Oxpay accounts for payment. There will be a delay in updating the registration and payment status in the Oxpay background, please check them later.",


  pwdTip:"The initial password cannot be empty",
  pwdTip1:"The new password cannot be empty",
  pwdTip2:"Please enter the new password again",
  pwdTip3:"The two password entries are inconsistent",
  pwdTip4:"Password changed successfully, please log in again",


  xiugaichenggong:"Modified successfully",


  registrationSuccess: "Successful registration",


  // 学生注册信息
  // 个人信息

  personInfo: {
    schoolName: "School Name:",
    name: "Name:",
    name1: "Name",
    personalInformation: "Personal Information",
    lingdui:"Team Leader & Team Members",
    registrationInformation: "Personal registration information",
    base: "Basic Information",
    xing: "Last Name:",
    ming: "Given Name:",
    xing1: "Last Name",
    ming1: "Given Name",

    xingpinyin: "Surname Pinyin:",
    mingpinyin: "Name Pinyin:",
    xingpinyin1: "Surname Pinyin:",
    mingpinyin1: "Name Pinyin:",
    sex: "Gender:",
    sex1: "Gender",

    grade: "Grade:",
    girl: "Female",
    boy: "Male",
    zhengjianleixing:"ID Type",
    zhengjianhaoma:"ID No.",
    tips1: "High School Graduation",
    contactInformation: "Contact Information",
    tel: "Mobile Phone:",
    email: "Email:",
    email1: "Email",
    weChat: "WeChat:",
    identityID: "Identity ID:",
    birthDate: "Birth Date:",
    tips2: "ID card or passport for insurance purchase only",
    tips3: 'YYYY/MM/DD，for insurance only',
    tips4: 'At present, personal information is not allowed to be modified by yourself. If you find that the personal information is wrong, please send us a message in the system to inform us of the specific modification request information.',
    tips5: 'Sending messages in the system is the most convenient and manageable way to contact us. We will reply to you as soon as we receive your message and remind you by email.',
    parentContactInformation: "Guardian Contact Information",
    firstGuardianTel: "First Guardian Tel:",
    firstGuardianEmail: "First Guardian Email:",
    secondGuardianTel: "Second Guardian Tel:",
    secondGuardianEmail: "Second Guardian Email:",
    supplementaryNotes: "Supplementary Notes:",
    initialPassword: 'Initial Password:',
    newPassword: "New Password:",
    enterAgain: "Verify Password:",
    enterPl:"Please enter",
    initialPlaceholder: 'Please enter the initial password',
    newPlaceholder: "Please enter a new password",
    enterAgainPlaceholder: "Please enter the new password again",
    submit: "Submit",
    chooseProject: "Message Type:",
    messageContent: "Message Content:",
    messageContentPlaceholder: 'Please enter the message content',
    modifySubproject: "Modify Subproject",
    competitionSubject: 'Competition subject [optional]:',
    competitionSubjectExtra: 'Competition subjects [extra optional]:',
    registrationProject: "Registration Project",
    registrationProject1: "Event:",
    registrationStatus: "Registration Status:",
    registrationStatus1: "Registration Status",
  },
  //学生报名信息
  studentInfo: {
    newRegistrationInformation: "New Registration Information",
    province: "Province",
    school: "School",
    student:"Student",
    studentSurname: "Student Surname",
    studentName: "Student Name",
    studentSurnamePlaceholder: "Please enter student surname",
    studentNamePlaceholder: "Please enter student name",
    studentSurnamePinyin: "Student Surname Pinyin",
    studentNamePinyin: "Student Name Pinyin",
    studentSurnamePinyinPlaceholder: "Please enter student surname Pinyin",
    studentNamePinyinPlaceholder: "Please enter student name Pinyin",
    yohsg: "Year Of High School Graduation",
    yohsgPlaceholder: 'Please select the expected year of high school graduation',
    sex: 'Gender',
    sexPlaceholder: "Please enter select sex",
    girl: 'Female',
    boy: 'Male',
    phoneNumber:"Student Phone Number",
    phoneNumberPlaceholder:"Please enter student phone number",
    studentEmail:"Student Email",
    studentEmailPlaceholder:"Please enter student email",
    studentGuardianTel:"Phone Of Student Guardian",
    studentGuardianTelPlaceholder:"Please enter phone of student guardian",
    studentGuardianEmail:"Email Of Student Guardian",
    studentGuardianEmailPlaceholder:"Please enter email of student guardian",
    specificProjects: "Specific projects(if any)",
    uploadApplication: "Upload Application Form",
    downloadTemplate: "Download Template",
    click: "Please click here",
    importType: "Import Type",
    import: "Import",
    province: "Province",
  },

  //管理员
  administrators: {

  }

}