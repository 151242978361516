<template>
  <article>
    <div class="btnDiv">
      <el-button v-if="loginId!=='admin'" type="primary" @click="addApplication" icon="el-icon-plus">{{$t('m.addApplicationForm')}}</el-button>
      <el-button v-if="loginId!=='admin'" type="primary" @click="toImport" icon="el-icon-upload">{{$t('m.importApplicationForm')}}
      </el-button>
      <!-- <el-button type="primary" @click="exportCsv" class="exportCsv" icon="el-icon-download">
        {{$t('m.exportApplicationForm')}}</el-button> -->
    </div>
    <el-table height="600" v-loading="loadDialog" :data="appTable" header-cell-class-name="adorn" size="medium" border
      ref="leftTable" class="left-table">
      <el-table-column :label="$t('m.xuhao')" align="center" type="index" width="50"></el-table-column>
      <el-table-column :label="$t('m.studentInfo.school')" align="center" prop="account.account_name"></el-table-column>
      <el-table-column :label="$t('m.personInfo.name1')" align="center">
        <template slot-scope="scope"><span class="studentName"
            @click="goPersonalInfo(scope.row)">{{ scope.row.student.student_lastName+scope.row.student.student_givenName }}</span>
        </template>
      </el-table-column>

      <el-table-column :label="$t('m.personInfo.email1')" align="center" prop="student.student_email"></el-table-column>

      
      <el-table-column :label="$t('m.loginProject')" align="center" prop="application_project" v-if="type==='project'"></el-table-column>
      <!-- <el-table-column label="开始时间" align="center" v-if="type==='project'">
        <template slot-scope="scope">
            {{formateDate(scope.row.project.start_time)}}
        </template>
      </el-table-column>
      <el-table-column label="结束时间" align="center" v-if="type==='project'">
        <template slot-scope="scope">
            {{formateDate(scope.row.project.end_time)}}
        </template>
      </el-table-column> -->
      
      <!-- <el-table-column label="报名渠道" align="center" prop="application_channel"></el-table-column> -->
      <el-table-column :label="$t('m.personInfo.registrationStatus1')" align="center" prop="application_status_show"></el-table-column>
      <!-- <el-table-column label="内部编号" align="center" prop="application_account_uuid"></el-table-column> -->
      <!-- <el-table-column width="100" label="查看成绩" align="center">
        <template slot-scope="scope">
          <el-button class="btn-link" @click="lookeResult(scope.row)" type="primary" size="small">详情</el-button>
        </template>
      </el-table-column> -->
      <el-table-column :label="$t('m.duiwu')" width="120">
          <template slot-scope="scope">
              <el-select v-model="scope.row.application_team_code" :placeholder="$t('m.pleaseChoose')">
                  <el-option v-for="i in 20" :key="i" :value="i" :label="i"></el-option>
              </el-select>
          </template>
      </el-table-column>
      <el-table-column :label="$t('m.chengyuanleixing')" width="120">
          <template slot-scope="scope">
              <el-select v-model="scope.row.application_team_role" :placeholder="$t('m.pleaseChoose')">
                  <el-option value="Leader"></el-option>
                  <el-option value="Member"></el-option>
              </el-select>
          </template>
      </el-table-column>
      <!-- <el-table-column label="类型" width="140">
          <template slot-scope="scope">
              <el-select v-model="scope.row.team_type">
                  <el-option label="Aquatic" value="Aquatic"></el-option>
                  <el-option label="Soils" value="Soils"></el-option>

                  <el-option label="Forestry" value="Forestry"></el-option>
              </el-select>
          </template>
      </el-table-column> -->
      <el-table-column label="Action" align="center">
          <template slot-scope="scope">
            <el-button v-if="scope.row.application_status==='已缴费'" @click="viewProject(scope.row)" type="primary" size="medium">Show Project</el-button>
          </template>
      </el-table-column>
    </el-table>
    <el-button @click="saveTeamCode" type="primary" style="margin-top:20px">{{$t('m.personInfo.submit')}}</el-button>

    <el-dialog class="dialog" :title="dialogInfo.title" :visible.sync="dialogInfo.show" :close-on-click-modal="false"
      :show-close="false" width="80%" @open="openDialog" @close="closeDialog">
      <el-form class="block-form" :model="dialogForm" ref="dialogForm" :rules="rules" status-icon v-loading="loadDialog"
        :label-width="formLabelWidth" size="medium">
        <el-row :gutter="10" v-if="contact_type == 'coordinator'">
          <el-col :span="12">
            <el-form-item :label="$t('m.studentInfo.province')" prop="provice">
              <el-select class="w140" @change="changeProvice" v-model="dialogForm.provice"
                :placeholder="$t('m.pleaseChoose')" clearable>
                <el-option v-for="item in provinceOptions" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('m.studentInfo.school')" prop="account_id">
              <el-select class="w140" v-model="dialogForm.account_id" :placeholder="$t('m.pleaseChoose')" filterable clearable>
                <el-option v-for="item in schoolOptions" :key="item.account_id" :label="item.account_name"
                  :value="item.account_id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item :label="$t('m.studentInfo.studentSurname')" prop="lastName">
              <el-input :placeholder="$t('m.studentInfo.studentSurnamePlaceholder')" type="text" v-model="dialogForm.lastName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('m.studentInfo.studentName')" prop="givenName">
              <el-input :placeholder="$t('m.studentInfo.studentNamePlaceholder')" type="text" v-model="dialogForm.givenName"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item :label="$t('m.studentInfo.studentSurnamePinyin')" prop="lastName_pinyin">
              <el-input :placeholder="$t('m.studentInfo.studentSurnamePinyinPlaceholder')" type="text" v-model="dialogForm.lastName_pinyin"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('m.studentInfo.studentNamePinyin')" prop="givenName_pinyin">
              <el-input :placeholder="$t('m.studentInfo.studentNamePinyinPlaceholder')" type="text"
                v-model="dialogForm.givenName_pinyin"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item :label="$t('m.studentInfo.yohsg')" prop="graduation">
              <el-select class="w140" v-model="dialogForm.graduation"
                :placeholder="$t('m.studentInfo.yohsgPlaceholder')" clearable>
                <el-option v-for="item in graduationOption" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('m.studentInfo.sex')" prop="gender">
              <el-select class="w140" v-model="dialogForm.gender" :placeholder="$t('m.studentInfo.sexPlaceholder')"
                clearable>
                <el-option v-for="item in genderption" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item :label="$t('m.studentInfo.phoneNumber')" prop="mobile">
              <el-input :placeholder="$t('m.studentInfo.phoneNumberPlaceholder')" type="text"
                v-model="dialogForm.mobile"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('m.studentInfo.studentEmail')" prop="email">
              <el-input :placeholder="$t('m.studentInfo.studentEmailPlaceholder')" type="text"
                v-model="dialogForm.email"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item :label="$t('m.studentInfo.studentGuardianTel')" prop="guardian_mobile">
              <el-input :placeholder="$t('m.studentInfo.studentGuardianTelPlaceholder')" type="text"
                v-model="dialogForm.guardian_mobile"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('m.studentInfo.studentGuardianEmail')" prop="guardian_email">
              <el-input :placeholder="$t('m.studentInfo.studentGuardianEmailPlaceholder')" type="text"
                v-model="dialogForm.guardian_email"></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item :label="$t('m.studentInfo.specificProjects')" prop="event">
              <el-select class="w140" v-model="dialogForm.event" :placeholder="$t('m.pleaseChoose')" clearable>
                <el-option v-for="item in eventOption" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">{{$t('m.cancel')}}</el-button>
        <el-button :loading="loadDialog" element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(255, 255, 255, 0.2)" type="primary" @click="submitTeam('dialogForm')">
          {{$t('m.confirm')}}</el-button>
      </div>
    </el-dialog>
    <el-dialog
    class="dialog"
    title="学生成绩"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false" 
    width="60%"
  >
    <el-table 
      :data="resultData"
      header-cell-class-name="adorn"
      size="medium"
      border
      ref="leftTable"
      class="left-table"
    >
      <el-table-column
        label="项目/科目"
        align="center"
        prop="application_event"
      ></el-table-column>
      <el-table-column
        label="得分/成绩"
        align="center"
        prop="test_score"
      ></el-table-column>
      <el-table-column label="奖项/等级" align="center" prop="test_grade">
      </el-table-column>
      <el-table-column label="是否晋级" align="center" prop="china_final_status">
      </el-table-column>
      <el-table-column label="证书" align="center">
        <template slot-scope="scope">
          <a :href="scope.row.certificate">{{
            scope.row.certificate ? "获取证书" : ""
          }}</a>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
  </article>
</template>
<script>
  import {
    getApplicationsInfoByAccount,
    getApplicationsByPackage,
    saveImportData,
    getApplicationsByAccount,
    getProjectEvents,
    getAccountsByProvince,
    addNewApplicationForm,
    addPackageApplication,
    saveTeamCode
  } from "../service.js";

  import Cookie from "js-cookie";
  export default {
    data() {
      return {
        type:this.$route.query.type,
        resultData:false,
        csvTable: [],
        loadDialog: false,
        dialogVisible: false,
        isUpload: false,
        formLabelWidth: "250px",
        formLabelWidth1: "150px",
        csvWidth: "40%",
        detailForm: {},
        csvForm: {},
        appTable: [],
        project_code: Cookie.get("loginProject"),
        package_id:this.$route.query.package_id,
        paginationLoading: false,
        loginId: Cookie.get("loginId"),
        fileName: "",
        contact_type: Cookie.get("contact_type"),
        loginType: Cookie.get("loginType"),
        dialogInfo: {
          title: '',
          show: false,
        },
        importOptions: [{
            label: "试看检查",
            value: "preview",
          },
          {
            label: "正式导入",
            value: "final",
          },
        ],
        dialogFormCSV: {
          title: "上传报名表",
          show: false,
        },
        dialogForm: {},
        rules: {
          provice: [{
            required: true,
            message: "请选择省份",
            trigger: "blur"
          }],
          account_id: [{
            required: true,
            message: "请选择学校",
            trigger: "blur"
          }, ],
          lastName: [{
            required: true,
            message: "请填写学生的姓",
            trigger: "blur"
          }, ],
          givenName: [{
            required: true,
            message: "请填写学生的名",
            trigger: "blur"
          }, ],
          lastName_pinyin: [{
            required: true,
            message: "请输入学生的姓拼音",
            trigger: "blur"
          }, ],
          givenName_pinyin: [{
            required: true,
            message: "请输入学生的名拼音",
            trigger: "blur"
          }, ],
          graduation: [{
            required: true,
            message: "请选择预计高中毕业年份",
            trigger: "blur",
          }, ],
          gender: [{
            required: true,
            message: "请选择性别",
            trigger: "blur"
          }],
          mobile: [{
            required: true,
            message: "请输入学生手机号",
            trigger: "blur"
          }, ],
          email: [{
            required: true,
            message: "请输入学生邮箱",
            trigger: "blur"
          }],
          guardian_mobile: [{
            required: true,
            message: "请输入学生监护人手机",
            trigger: "blur"
          }, ],
          guardian_email: [{
            required: true,
            message: "请输入学生监护人邮箱",
            trigger: "blur"
          }, ],
        },
        genderption: [{
          value: this.$t('m.studentInfo.boy'),
          label: this.$t('m.studentInfo.boy')
        }, {
          value: this.$t('m.studentInfo.girl'),
          label: this.$t('m.studentInfo.girl')
        }],
        graduationOption: [
          "2020",
          "2021",
          "2022",
          "2023",
          "2024",
          "2025",
          "2026",
        ],
        resultData: [],
        eventOption: [],
        schoolOptions: [],
        provinceOptions: [
          "安徽",
          "北京",

          "重庆",

          "福建",

          "甘肃",

          "广东",

          "广西",

          "贵州",

          "海南",

          "河北",

          "河南",

          "黑龙江",

          "湖北",

          "湖南",

          "吉林",

          "江苏",

          "江西",

          "辽宁",

          "内蒙古",

          "宁夏",

          "青海",

          "山东",

          "山西",

          "陕西",

          "上海",

          "四川",

          "天津",

          "西藏",

          "新疆",

          "云南",

          "浙江",

          "香港",

          "澳门",

          "台湾",

          "美国",

          "英国",

          "加拿大",

          "新加坡",

          "其他",
        ],
      };
    },
    created() {
        if (this.loginType == "教师") {
            this.getApplicationsByPackage();
        } 
    },
    mounted() {
    //   this.getProjectEvents();
    },
    methods: {
        lookeResult(info) {
            this.dialogVisible = true;
            this.resultData = info.test_results;
        },
        changeProvice() {
            getAccountsByProvince({
                province: this.dialogForm.provice
            }).then((res) => {
                if (res.code == 0) {
                    this.schoolOptions = res.data;
                } else {
                    self.$message.error(res.msg);
                }
            });
        },
        fileChange(event) {
            if (this.csvForm.importType == "preview") {
            this.csvWidth = "80%";
            this.isUpload = true;
            }
            let param = {
            type: this.csvForm.importType,
            contact_id: this.loginId,
            project_code: this.project_code,
            };
            let csvFile = event.target.files[0];
            let formData = new FormData();
            formData.append("type", this.csvForm.importType);
            formData.append("file", csvFile);
            formData.append("contact_id", this.loginId);
            formData.append("project_code", this.project_code);
            formData.append("function", "saveImportData");
            let self = this;
            saveImportData(formData).then((res) => {
            if (res.code == 0) {
                self.csvTable = res.data;
                self.isUpload = true;
                if (res.msg == "导入成功") {
                self.$message.success("导入成功");
                self.dialogFormCSV.show = false;
                self.csvForm = {};
                self.getApplicationsByAccount();
                }
            } else {
                self.$message.error(res.msg);
            }
            });
        },
        getProjectEvents() {
            //获取其他项目
            let param = {
            project_code: this.project_code,
            };
            getProjectEvents(param).then((res) => {
            if (res.code == 0) {
                this.eventOption = res.data;
            } else {
                this.$message.error(res.msg);
            }
            });
        },
        submitTeam(dialogForm) {
            let _this = this;
            this.$refs[dialogForm].validate((valid) => {
            if (valid) {
                this.loadDialog = true;
                this.dialogForm.contact_id = this.loginId;

                
                this.dialogForm.package_id = this.package_id;
                this.dialogForm.project_code = Cookie.get("loginProject");

                addPackageApplication(this.dialogForm).then(res=>{
                    this.loadDialog = false;
                    this.dialogInfo.show = false
                    this.dialogForm = {}
                    if (res.data.code == 0) {
                        this.getApplicationsByPackage()
                    } else {
                        this.$message.error(res.msg);
                    }
                })
            
                
            } else {
                return false;
            }
            });
        },
        addApplication() {
            //新增报名信息

            this.dialogInfo.show = true;
            this.dialogInfo.title = this.$t('m.studentInfo.newRegistrationInformation')
        },
        openDialog() {
            this.$nextTick(() => {
            if (this.$refs.dialogForm) {
                this.$refs.dialogForm.clearValidate();
            }
            });
        },
        closeDialog() {
            this.dialogInfo.show = false;
        },
        exportCsv(title, data, fileName) {
            let today = new Date();
            let tadayDate =
            today.getFullYear() +
            "-" +
            (today.getMonth() + 1) +
            "-" +
            today.getDate();
            fileName = "学校报名列表-" + tadayDate;

            var CSV = "";
            var row = "";

            let temp = [];
            data = this.appTable;

            title = [{
                title: "学校",
                field: "account_name"
            },
            {
                title: "姓名",
                field: "student_name"
            },
            {
                title: "项目",
                field: "application_project"
            },
            {
                title: "子项目",
                field: "application_events"
            },
            {
                title: "报名渠道",
                field: "application_channel"
            },
            {
                title: "报名状态",
                field: "application_status"
            },
            {
                title: "内部编号",
                field: "application_account_uuid"
            },
            ];

            for (var i = 0; i < title.length; i++) {
            if (title[i].title) {
                row += title[i].title + ",";
            }
            }
            row = row.slice(0, -1);
            CSV += row + "\r\n";

            for (var i = 0; i < data.length; i++) {
            var row = "";
            for (var j = 0; j < title.length; j++) {
                if (title[j].title) {
                row += '"' + data[i][title[j].field] + '"\t,';
                }
            }
            row.slice(0, row.length - 1);
            CSV += row + "\r\n";
            }

            if (CSV == "") {
            alert("Invalid data");
            return;
            }

            var fileName = fileName;
            var uri = new Blob(["\ufeff" + CSV], {
            type: "text/csv"
            });

            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            // for IE
            window.navigator.msSaveOrOpenBlob(CSV, fileName + ".csv");
            } else {
            // for Non-IE（chrome、firefox etc.）
            var link = document.createElement("a");
            link.href = URL.createObjectURL(uri);

            link.style = "visibility:hidden";
            link.download = fileName + ".csv";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            }
        },
        goPersonalInfo(row) { //进入个人信息页面
            this.$router.push({
            name: 'personalInfoEdit',
            query: {
                student_id: row.student._id.$id
            }
            })

        },
        importApplication() {
            this.dialogFormCSV.show = true;
            this.dialogFormCSV.title = this.$t('m.studentInfo.uploadApplication')
        },
        getApplicationsByAccount() {
            this.loadDialog = true;
            let param = {
                user_id: this.loginId,
                project_code: this.project_code,
            };
            getApplicationsByAccount(param).then((res) => {
                this.loadDialog = false;
                if (res.code == 0) {
                    this.appTable = res.data;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        getApplicationsByPackage() {
            this.loadDialog = true;
            let param = {
                user_id: this.loginId,
                app_id: this.package_id,
                project_code:this.project_code
            };
            getApplicationsByPackage(param).then((res) => {
                this.loadDialog = false;
                if (res.data.code == 0) {
                    this.appTable = res.data.data;
                } else {
                    this.$message.error(res.data.msg);
                }
            });
        },
        getApplicationsInfoByAccount() {
            this.loadDialog = true;
            let param = {
                account_id: this.loginId,
                project_code: this.project_code,
            };
            getApplicationsInfoByAccount(param).then((res) => {
                this.loadDialog = false;
                if (res.code == 0) {
                    this.appTable = res.data;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        formateDate(date){
                let time = new Date(date*1000)
                return `${time.getFullYear()}-${time.getMonth()+1}-${time.getDate()}`;
            },
        editItem(row) {
            this.$router.push({
            path: "/applicationDetail",
            query: {
                project_code: row.application_project
            },
            });
        },
        toImport(){
            if(this.type==='project'){
                this.$router.push({
                    path:'/importApplications',
                    query:{
                        type:'project',
                        project_code:this.project_code
                    }
                })
            }else if(this.type==='package'){
                this.$router.push({
                    path:'/importApplications',
                    query:{
                        type:'package',
                        package_id:this.package_id
                    }
                })
            }
            
        },
        saveTeamCode(){
            //   console.log(this.appTable)
            let applications = this.appTable.map(item=>{
                let data = {}
                data.id = item._id.$id
                data.application_team_code = item.application_team_code
                data.application_team_role = item.application_team_role
                data.team_type = item.team_type
                return data
            })
            
            saveTeamCode(applications).then(res=>{
                if(res.data.code===0){
                    this.$message({
                        message:"提交成功",
                        type:"success"
                    })
                }else{
                    this.$message.warning(res.data.msg)
                }
            })
        },
        viewProject(item){

          console.log(123,item)
            
          if (item.coordinator_id && item.coordinator_id != ''){
            const routeData = this.$router.resolve({ path: 'pghome', query: { template_id: item.template_id, 
              project_id: item.project_id, 
              application_team_code: item.application_team_code, 
              accountid: item.coordinator_id ,
              student_id:item.student_id
            } })
            window.open(routeData.href, '_blank')
          }else{
            const routeData = this.$router.resolve({ path: 'pghome', query: { template_id: item.template_id, 
              project_id: item.project_id, 
              application_team_code: item.application_team_code, 
              accountid: item.account_id ,
              student_id:item.student_id

            } })
            window.open(routeData.href, '_blank')

          }

        }
    },
  };
</script>
<style lang="scss" scoped>
  article {
    margin-top: 50px;
    background: #fff;
    padding: 10px;

    /deep/ .el-form.el-form--inline {
      background-color: #f8f8f8;
      margin-bottom: 10px;
      padding: 8px;
      overflow: hidden;
    }

    /deep/ .el-form.el-form--inline .el-form-item.el-form-item--medium {
      margin-right: 4px;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

  .formP {
    margin: 0;
    padding: 0;

    padding-left: 10px;
    // border:1px solid #e9eef3;
    background: #f8f9fa;
    border-radius: 4px;
    width: 70%;
    position: relative;
  }

  .studentName {
    color: #409eff;
    cursor: pointer;
  }

  .btnDiv {
    margin-bottom: 20px;
    position: relative;
  }

  .downExcel {
    display: inline-block;
    margin-left: 20px;
  }

  /deep/ .el-select {
    width: 100%;
  }

  .uploadExcel {}

  .exportCsv {
    margin-left: 10px;
  }

  .importBtn {}
</style>