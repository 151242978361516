<template>
    <article>

        <el-table :data="teamTable" v-loading="dataLoading" header-cell-class-name="adorn" size="medium" border
            ref="leftTable" class="left-table">
            <el-table-column label="项目名称" align="center" prop="project_name" width="440">
            </el-table-column>
            <el-table-column label="项目平台" align="center" prop="project_platform">
            </el-table-column>
            <el-table-column label="报名费用" align="center" prop="project_price">

            </el-table-column>
            <el-table-column label="项目年份" align="center" prop="project_year">
            </el-table-column>
            <el-table-column width="140" label="操作" align="center">
                <template slot-scope="scope">
                    <el-button class="btn-link" @click="signUp(scope.row)" type="primary" size="mini">报名</el-button>

                </template>
            </el-table-column>
        </el-table>
        <el-dialog title="报名" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
            <span style="font-size: 16px;">您是否确定报名该项目</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false" size="small">取 消</el-button>
                <el-button type="primary" @click="sureBtn" size="small">确 定</el-button>
            </span>
        </el-dialog>

    </article>
</template>
<script>
    import {
        getCollegeProjects,
        addApplication
    } from "../service.js";

    import Cookie from "js-cookie";
    export default {
        data() {
            return {
                

               
                dialogVisible:false,
                teamTable: [],

                account_id: Cookie.get("studentid"),
                dataLoading: false,


            };
        },
        created() {
            this.getCollegeProjects(); //获取项目表
        },
        mounted() {},
        methods: {
            signUp(info) {
              this.project_id =  info._id.$id;
              this.dialogVisible = true;
            },

            sureBtn(){
                addApplication({
                    project_id:  this.project_id,
                    user_id: Cookie.get("loginId")
                }).then((res) => {
                    this.dialogVisible = false;
                    if (res.code == 0) {
                       
                        this.$message.success('该项目已报名成功！')

                    } else if(res.code ==-1) {
                        this.$message.warning('该项目已报名！');
                    }
                });
            },



            getCollegeProjects() {
                this.dataLoading = true;
                getCollegeProjects({
                    user_id: Cookie.get("loginId")
                }).then((res) => {
                    this.dataLoading = false;
                    this.teamTable = res;
                    console.log(res)

                });
            },
        },
    };
</script>
<style lang="scss" scoped>
    article {
        margin-top: 50px;
        background: #fff;
        padding: 10px;

        /deep/ .el-form.el-form--inline {
            background-color: #f8f8f8;
            margin-bottom: 10px;
            padding: 8px;
            overflow: hidden;
        }

        /deep/ .el-form.el-form--inline .el-form-item.el-form-item--medium {
            margin-right: 4px;
            margin-top: 5px;
            margin-bottom: 5px;
        }
    }

    .formP {
        margin: 0;
        padding: 0;

        padding-left: 10px;
        // border:1px solid #e9eef3;
        background: #f8f9fa;
        border-radius: 4px;
        width: 70%;
        position: relative;
    }
</style>