<template>
    <div class="container">
      <div class="content">
        <div class="title">
          Payment Confirmation
        </div>
        <div class="intro">
          <div class="item">
            <div class="item1">Project Name</div>
            <div class="item2">{{application.application_project}}</div>
          </div>
          <div class="item">
            <div class="item1">Event Name</div>
            <div class="item2">{{application.event}}</div>
          </div>

          <div class="item">
            <div class="item1">Registration Fee</div>
            <div class="item3">SGD ${{application.total_price}}</div>
          </div>

          <div class="item">
            <div class="item1">Fee Reduction*</div>
            <div class="item3">SGD ${{application.total_price}}</div>
          </div>

          <div class="item">
            <div class="item1">Fee After Reduction</div>
            <div class="item3">SGD ${{application.total_price}}</div>
          </div>

          <div class="item" >
            <div class="item1">Payment Processing Fee</div>
            <div class="item3">SGD ${{application.oxpay_charge}}</div>
          </div>

          <div style="background-color: #0E4890;margin-top: -15px;width: 100%;height: 1px;"></div>

          <div class="item" style="margin-bottom:0;margin-top: 10px;">
            <div class="item1">Total Order Amount</div>
            <div class="item3">SGD ${{application.amount}}</div>
          </div>

          
        </div>


        <div style="margin-top: 10px;color: red;word-wrap: normal;">
          *NIM supports students' PBL endeavors with fee reductions. The extent of reduction is determined by factors such as regional economy, income levels, tuition costs, culture, etc. Learn more. <span style="color: #5A9CF8;text-decoration: underline" @click="showLearnMore()">Learn more.</span>
        </div>

        <div class="title1">
          Terms & Conditions
        </div>
        <div class="intro1">
          {{application.project_refund_policy}}
        </div>
        <div class="tip">
          <img v-if="check" src="../assets/select2.svg" @click="check=false">
          <img v-else src="../assets/select1.svg"  @click="check=true">
          I have read and agree on the terms and conditions above.
        </div>
        <div class="btnGroup">
          <div class="cancel" @click="$router.back(-1)">Cancel</div>
          <div class="pay" @click="toPay">Pay</div>
        </div>
      </div>
      <el-dialog  :visible.sync="showPayList"
        width="813px"
        height="831px"
        center
        custom-class="dialog_class"
        :close-on-click-modal="false"
        close-on-press-escape
        title="Payment Method List">
        <div style="text-align:center">
          <!-- <PayPal v-if="showPay" :amount="orderData.amount" :currency="orderData.currency_code" :client="credentials"
            :button-style="buttonStyle" @payment-authorized="paymentAuthorized"
            @payment-completed="paymentCompleted" @payment-cancelled="paymentCancelled">
          </PayPal> -->

          <!-- <el-button @click="goAlipay"  class="alipayStyle" :loading="btnLoading">Alipay</el-button> -->
          <el-button @click="goStripe"  class="alipayStyle" :loading="btnLoading">Stripe</el-button>

          <!-- <button>Alipay</button> -->
        </div>

      </el-dialog>
      <el-dialog custom-class="myDialog" :visible.sync="showOxPay" :close-on-click-modal="false" :show-close="false" width="600px">
        <div style="text-align:center">
          <div class="aliTitle">Pay Amount<span style="color:#F65D4D;font-weight:900;font-size:24px;margin-left:10px">SGD${{application.amount}}</span></div>
          <div style="margin-top:30px;margin-bottom:22px;font-size:16px">Please scan with Alipay</div>
          <vue-qr
          :size="191"
          :margin="0"
          :auto-color="true"
          logoSrc="https://mainbucket.learningfirst.cn/%E6%94%AF%E4%BB%98-%E6%94%AF%E4%BB%98%E5%AE%9D.png"
          :logoScale="2"
          :dot-scale="1"
          :text="address" />
          <div style="margin-top:20px;margin-bottom:50px;font-size:16px">Scan to Pay</div>
        </div>
      </el-dialog>



    <el-dialog class="dialog" title="Fee Reduction Policy" :visible.sync="dialogVisible" :close-on-click-modal="false" width="60%">


      <!-- <div>The Tuition Tier is stratified according to the annual tuition fees levied by the
          school or educational institution.
      </div> -->

      <el-row>
        <el-col :span="16">

          <!-- <el-table :data="tierData" header-cell-class-name="adorn" size="medium" border ref="leftTable" class="left-table">
            <el-table-column label="Tuition Tier" align="center" prop="level"></el-table-column>
            <el-table-column label="Annual Tuition" align="center" prop="price"></el-table-column>
          </el-table> -->

          <div style="width: 100%;height: 500px;overflow-y: auto;">
            <pdf :src="pdfSrc" width="100%"  
                v-for="i in 4"
                :key="i"
                :page="i"
                ref="pdf"
                style="width: 100%;"
            />

          </div>

        

        </el-col>
        <el-col :span="8">

        
          <div class="levelTitle">Your Reduction Level</div>
          <el-table :row-class-name="tableRowClassName" :data="tierData1" header-cell-class-name="adorn" size="medium" border ref="leftTable" class="left-table hide-table-header" >
            <el-table-column label="" align="center" prop="key" ></el-table-column>
            <el-table-column label="" align="center" prop="value" width="50px"></el-table-column>
          </el-table>

          <!-- <div class="levelTitle1">Country/Region Class</div>

          <div class="levelTitle2">Current Tuition Tier</div>

          <div class="levelTitle1">Reduction Level</div> -->

          <div class="tierTip">
            If you've received financial aid, scholarships, or subsidies that have cut your tuition fees below the standard amount, you have the opportunity to apply for reclassification into a lower Tuition Tier for greater fee reduction.
          </div>

          <!-- <div class="levelClass">Tuition Tier: {{ price_level }}</div> -->


          <div class="applyClass" style="position: relative;">

            <input class="myFileUpload"  name="file" type="file"  accept=".pdf" @change="getFile" />

            <span class="addInput2">
              Apply for lower tier
            </span>

          
          </div>



          <div style="margin-top: 20px;margin-left: 20px;" v-if="file"> {{ file.name }}</div>

          <!-- <el-button type="primary" class="applyClass">Apply for lower tier</el-button> -->


          <div class="tierTip1">Compile documentation of your actual tuition fee
              payment into one PDF titled with your full name.</div>



          <div class="dialog-footer">
            <el-button type="primary" @click="dialogVisible=false">Cancel</el-button>
            <el-button
              :loading="loadDialog"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(255, 255, 255, 0.2)"
              type="primary"
              @click="submitTier()"
              >Submit</el-button
            >
          </div>

        


        </el-col>
      </el-row>



      </el-dialog>

    </div>
  </template>

<script>
import { getApplicationByID, getPaypal, paypalSucceedBack, getOxPayQrUrl, getPayStatus ,getStripeUrl,
  uploadStudentTuitionTier,getProjectDetail,getQNToken
} from '../service'
import '../assets/common/font.css'
import PayPal from 'vue-paypal-checkout'
import VueQr from 'vue-qr'

import * as qiniu from 'qiniu-js'
import pdf from 'vue-pdf'
import Cookie from "js-cookie";
import { showLoading, hideLoading } from '../util.js'

export default {
  components: {
    PayPal, VueQr,pdf
  },
  data () {
    return {
      credentials: {
        sandbox: '',
        production: ''
      },
      showPay: false,
      showPayList: false,
      orderData: {},
      buttonStyle: {
        label: 'pay',
        size: 'small',
        shape: 'rect',
        color: 'blue'
      },
      id: this.$route.query.id,
      application: {},
      check: false,
      btnLoading: false,
      address: '',
      showOxPay: false,
      timer: '',
      dialogVisible:false,
      loadDialog: false,
      tierData:[ {'level':'1','price':'>15000 SGD'},
                 {'level':'2','price':'>8000 SGD'},
                 {'level':'3','price':'>4000 SGD'},
                 {'level':'4','price':'>2000 SGD'},
                 {'level':'5','price':'>1500 SGD'},
                 {'level':'6','price':'>1000 SGD'},
                 {'level':'7','price':'>500 SGD'},
                 {'level':'8','price':'<500 SGD and >0'},
                 {'level':'9','price':'FREE'},

                ],
      tierData1:[],
      file:null,
      pdfSrc: 'https://ap.learningfirst.tech/NIM%20Fee%20Reduction%20Policy%20240611.pdf',
      numPages:0,
      price_level:'1'
    }
  },
  mounted () {
    this.startWatchStatus()
    this.fetchData()
    this.getProjectDetail()
  },
  beforeDestroy () {
    window.clearInterval(this.timer)
  },
  methods: {


    getProjectDetail(){

      getProjectDetail(Cookie.get("loginProject") ,Cookie.get("loginId")).then((res) => {


        this.project_code_arr = res.data.data.project_convert_projects

        this.price_level = res.data.data.price_level
        console.log(1231,res)

        this.tierData1 = res.data.data.level_arr

      });


    },
    tableRowClassName({row, rowIndex}) {
        if (rowIndex === 1) {
          return 'success-row';
        } else {
          return 'warning-row';
        }
    },
    fetchData () {
      getApplicationByID(this.id).then((res) => {
        if (res.data.code === 0) {
          this.application = res.data.data
        }
      })
      // getPaypal(this.id).then((res) => {
      //   res.data.data.amount = String(res.data.data.amount)
      //   this.orderData = res.data.data
      //   this.credentials.sandbox = res.data.data.client_id
      //   this.credentials.production = res.data.data.live_client_id
      //   console.log(222, this.orderData)

      //   this.showPay = true
      // })
    },
    goStripe(){
        

      getStripeUrl(this.id,this.application.project_refund_policy,this.check).then(res=>{
        if(res.data.code===0){
          window.open(res.data.data,'_blank')
        }else{
          this.$message.error(res.data.msg)
        }
      })
    },
    paymentAuthorized (data) {
      // 授权完成的回调，可以拿到订单id
      console.log('Authorization Complete', data)
    },

    paymentCompleted (data) {
      // 用户支付完成的回调，可以拿到订单id
      console.log('Payment Complete', data)
      this.paypalSucceedBack(data)
    },

    paymentCancelled (data) {
      // 用户取消交易的回调
      console.log('Payment Cancelled', data)
    },

    paypalSucceedBack (data) {
      paypalSucceedBack(this.id, data).then(res => {
        if (res.data.code === 0) {
          this.$message({
            message: 'Payment Success!',
            type: 'success'
          })

          getApplicationByID(this.id).then((res) => {
            if (res.data.code === 0) {
              this.application = res.data.data
            }
          })
        }
      })
    },
    toPay () {
      if (!this.check) {
        this.$message.error('Please check to agree on the terms and conditions first')
        return
      }
      this.showPayList = true
    },
    goAlipay () {
      this.btnLoading = true
      this.address = ''

      getOxPayQrUrl(this.id).then(res => {
        // this.showOxPay = true
        if (res.data.code === 0) {
          this.address = res.data.data.data.qrUrl
          this.showPayList = false
          this.showOxPay = true
        } else {
          this.address = ''
          this.$message.error('Failed to request')
        }
      }).finally(() => {
        this.btnLoading = false
      })
    },
    startWatchStatus () {
      this.timer = setInterval(() => {
        getPayStatus(this.id).then(res => {


          if (res.data.application_status === '已缴费') {
            this.$message.success('Paid Success')
            window.clearInterval(this.timer)
            this.$router.push({
              path: '/studentProjects',
            })
          }
        })
      }, 2000)
    },
    showLearnMore(){
      this.dialogVisible = true
    },
    getFile (e) {
      const file = e.target.files[0]

      console.log(file)
      if (file.size / 1024 / 1024 > 20) {
        this.$message({
          message: 'The file size cannot exceed 20 MB. Please upload again.',
          type: 'error'
        })
        this.file = null
        return false
      } else {
        this.file = file
      }

      console.log(123,this.file)

      // this.videoName = this.file.name
      // this.uploadFile()
    },
    async getQiniuToken () {
      return new Promise((resolve) => {
        getQNToken().then((res) => {
          resolve(res)
        })
      })
    },
    submitTier(){


      showLoading()

      this.uploadVideoFile()


    },
    async uploadVideoFile () {
      console.log(1232131213)

      const result = await this.getQiniuToken()

      console.log(8888, result)

      if (result.data.code === 0) {
        console.log(1222)

        this.proVisible = true
        this.process = 1

        const token = result.data.data
        this.observable = qiniu.upload(
          this.file,
          new Date().getTime() + this.file.name,
          token
        )
        const observer = {
          complete: (res) => {
            var url = 'https://ap.learningfirst.tech/' + res.key

            
            // console.log(111111222,url)

            // resolve(url)
            this.submitTierInfo(url)

          },
          next: (res) => {
            // this.process = parseFloat(res.total.percent.toFixed(1))
          }
        }
        this.observable.subscribe(observer)
      } else {
      }
    },
    
    submitTierInfo(url){

      uploadStudentTuitionTier( Cookie.get("loginId"),Cookie.get("loginProject"),url ).then((res) => {

        hideLoading()

          if (res.data.code == 0) {
            this.$message.success('Submit Successfully')
          } else {
            this.$message.error(res.msg);
          }
        });
    },

  }
}
</script>

  <style lang="scss" scoped>
  .container {
    width: 100%;
    height: 100vh;
    background: url('../assets/applyBack.png');
    background-size: 110% 120%;
    background-position: 10% 10%;
      font-family: "DDINAlternateRegular";
    .content {
      width: 1200px;
      margin: 0 auto;
      height: 100%;
      background: #fff;
      padding: 80px;
      box-sizing: border-box;
      overflow-x: scroll;
      .title{
        font-size: 24px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #F65D4D;
        text-decoration:underline
      }
      .title1{
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #333333;
        font-size: 20px;
        margin-top: 30px;
      }
      .intro{
        background: #F5F9FF;
        width: 100%;
        padding: 40px 60px;
        box-sizing: border-box;
        margin-top: 30px;
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }
      .intro1{
        background: #F5F9FF;
        width: 100%;
        padding: 40px 60px;
        box-sizing: border-box;
        margin-top: 30px;
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 34px;
        white-space: pre-line;
      }
      .item{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
        .item4{
          margin-top: 20px;
        }
        .item1{
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #333333;
          font-size: 18px;
        }
        .item2{
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #0E4890;
          font-size: 20px;
        }
        .item3{
          font-size: 24px;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #F65D4D;
        }
      }
      .tip{
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #333333;
          margin-top: 20px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          img{
            margin-right: 6px;
            cursor: pointer;
          }
      }

      .btnGroup{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 50px;
        .cancel{
          width: 180px;
          height: 40px;
          background: #0E4890;
          border-radius: 25px;
          color: #fff;
          line-height: 40px;
          text-align: center;
          cursor: pointer;
        }
        .pay{
          width: 180px;
          height: 40px;
          background: #FFB414;
          border-radius: 25px;
          color: #fff;
          line-height: 40px;
          text-align: center;
          cursor: pointer;
          margin-left: 30px;
        }
      }

    }
    .alipayStyle{
      background-color: rgba(17, 136, 215,0.9);
      margin-top: 20px;
      width: 150px;
      // height: 20px;
      min-width: 150px;
      // min-height: 20px;
      // line-height: 20px;
      align-items: center;
      text-align: center;
      color: white;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
  .aliTitle{
    color: #333333;
    font-size: 16px;
  }
  .tierTip{
    word-break: normal;
    margin-left: 20px;
    margin-top: 20px;
    line-height: 20px;
    // width: 40%;
  }
  .levelClass{
    float: left;
    width: 160px;
    background-color: gray;
    color: white;
    font-size: 13px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    margin-left: 20px;
    margin-top: 30px;

  }
  .applyClass{
    // float: left;
    margin-left: 20px;
    margin-top: 30px;
    width: 160px;
    height: 36px;
    // line-height: 36px;
    font-size: 13px;
    text-align: center;
    color: white;
    // background-color: red;
  }
  .tierTip1{
    color: red;
    word-break: normal;
    margin-left: 20px;
    margin-top: 20px;
    line-height: 20px;
  }
  .addInput2{
    font-family: "DDINAlternateRegular";
    font-weight: 600;
    color: white;
    background-color: #5A9CF8;
    // border: 1.5px solid #0E4890;
    height: 36px;
    width: 160px;
    line-height: 36px;
    text-align: center;
    // background: white;
    // border-radius: 5px;
    // padding: 4px 12px;
    cursor: pointer;
    margin-top: 0px;
    display: inline-block;
  }
  .myFileUpload
  {
      position: absolute;
      display: block;
      width: 100px;
      height: 40px;
      opacity: 0;
      // top: 10px;
  }
  .levelTitle{
    background-color: #5A9CF8;
    margin-left: 20px;
    margin-right: 20px;
    height: 30px;
    color: white;
    line-height: 30px;
    font-size: 15px;
    padding-left: 10px;
    // margin-bottom: 5px;
  }
  .levelTitle1{
    float: left;
    background-color: #D0D4E8;
    margin-left: 20px;
    margin-right: 70px;
    height: 30px;
    color: #333;
    line-height: 30px;
    font-size: 15px;
    padding-left: 10px;
    margin-top: 1px;
  }
  .levelTitle2{
    float: left;

    background-color: #E9EBF4;
    margin-left: 20px;
    margin-right: 70px;
    height: 30px;
    color: #333;
    line-height: 30px;
    font-size: 15px;
    padding-left: 10px;
    margin-top: 1px;
  }
  .dialog-footer{
    // display: flex;
    margin-top: 10px;
    margin-left: 20px;
    width: 100%;
    align-items: center; /* 垂直居中 */
    justify-content: center; /* 水平居中，如需要 */
    height: 80px; /* 容器高度 */
    // background-color: red;
  }
  .left-table{
    margin-top: 2px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100% - 40px);
    // width: 50%;
    word-break: normal;

  }

  .el-table /deep/ .warning-row {
    background: #D0D4E8;
  }

  .el-table /deep/ .success-row {
    background: #E9EBF4;
  }
  .hide-table-header /deep/ .el-table__header-wrapper {
    display: none;
  }
  </style>
<style lang="scss">
.myDialog{
  border-radius: 16px;
}
</style>
