<template>
  <article>
    <div class="infoContent" v-if="this.loginType == '教师'" v-loading="loading">
      <el-form  class="block-form" :model="schoolInfoData" status-icon label-width="160px">
        <el-row :gutter="24">
          <!-- <el-col :span="13">
            <el-form-item label="学校名称（中文）" prop="caption">
              <el-input clearable type="text" disabled v-model="schoolInfoData.account_name" @input="$forceUpdate()"></el-input>
            </el-form-item>
          </el-col> -->
          <el-col :span="13">
            <el-form-item :label="$t('m.personInfo.schoolName')" prop="caption">
              <el-input clearable type="text" disabled v-model="schoolInfoData.account_nameEN" @input="$forceUpdate()"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="13">
            <el-form-item :label="$t('m.suozaisheng')" prop="caption">
              <el-input clearable type="text" disabled p v-model="schoolInfoData.account_province" @input="$forceUpdate()">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="13">
            <el-form-item :label="$t('m.suozaichengshi')" prop="caption">
              <el-input clearable type="text" disabled p v-model="schoolInfoData.account_city" @input="$forceUpdate()">
              </el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="13">
            <el-form-item label="学校地址（中文）" prop="caption">
              <el-input clearable type="text" disabled p v-model="schoolInfoData.account_address" @input="$forceUpdate()">
              </el-input>
            </el-form-item>
          </el-col> -->
          <el-col :span="13">
            <el-form-item :label="$t('m.xuexiaoaddress')" prop="caption">
              <el-input clearable type="text" disabled p v-model="schoolInfoData.account_addressEN" @input="$forceUpdate()">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="13">
            
            <el-form-item :label="$t('m.xuexiaoyoubian')" prop="caption">
              <el-input clearable type="text" disabled p v-model="schoolInfoData.account_postCode" @input="$forceUpdate()">
              </el-input>
            </el-form-item>
          </el-col>

        </el-row>
      </el-form>
    </div>
    <span v-if="this.loginType == '学校管理员'">{{$t('m.personInfo.schoolName')}}</span><span class="">{{schoolName  }}</span>


  </article>
</template>
<script>
  import {
    getSchoolInfo,
    getSchoolInfoByAccount
  } from "../service.js";

  import Cookie from "js-cookie";
  export default {
    data() {
      return {
        schoolName:"",
        schoolInfoData: {},
        loginType: Cookie.get("loginType"),
        loginId: Cookie.get("loginId"),
        loading:false
      };
    },
    created() {
      if (this.loginType == '教师') {
        this.getSchoolInfo(); //获取学校信息
      } else if (this.loginType == '学校管理员') {
        this.getSchoolInfoByAccount();
      }

    },
    mounted() {},
    methods: {
      getSchoolInfo() {
          this.loading = true
        let param = {
          user_id: Cookie.get("loginId"),
        };
        getSchoolInfo(param).then((res) => {
            this.loading = false
          if (res.code == 0) {
            this.schoolInfoData = res.data;
            console.log( this.schoolInfoData)
          } else {
            this.$message.error(res.msg);
          }
        });
      },
      getSchoolInfoByAccount() {
        let param = {
          account_id: Cookie.get("loginId"),
        };
        getSchoolInfoByAccount(param).then((res) => {
          if (res.code == 0) {
            this.schoolName = res.data;
          } else {
            this.$message.error(res.msg);
          }
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  article {

    margin-left: 100px;
    margin-top: 50px;
    background: #fff;
    padding: 0 10px;
   

    .infoContent {
      padding: 20px 0;
      height: 90%;
    }




  }
</style>