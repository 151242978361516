<template>
  <article>
    <el-table :data="messageTable" v-loading="loadDialogTable" header-cell-class-name="adorn" size="medium" border
      ref="leftTable" class="left-table">
      <el-table-column label="项目" align="center" prop="application_project"></el-table-column>
      <el-table-column label="状态" align="center" prop="application_status"></el-table-column>
      <el-table-column label="创建时间" align="center" prop="create_time">
        <template slot-scope="scope">{{
          scope.row.create_time | timeFormat
        }}</template>
      </el-table-column>
      <el-table-column width="140" label="操作" align="center">
        <template slot-scope="scope">
          <el-button class="btn-link" @click="checkItem(scope.row)" type="primary" size="small">查看成绩</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog class="dialog" :title="dialogInfo.title" :visible.sync="dialogInfo.show" :close-on-click-modal="false"
      width="60%">
      <el-table v-loading="loadDialog" :data="dialogTable" header-cell-class-name="adorn" size="medium" border
        ref="leftTable" class="left-table">
        <el-table-column label="项目/科目" align="center" prop="application_event"></el-table-column>
        <el-table-column label="得分/成绩" align="center" prop="test_score"></el-table-column>
        <el-table-column label="奖项/等级" align="center" prop="test_grade">
        </el-table-column>
        <el-table-column label="是否晋级" align="center" prop="china_final_status">
        </el-table-column>
        <el-table-column label="证书" align="center">
          <template slot-scope="scope">
            <a :href="scope.row.certificate">{{
              scope.row.certificate ? "获取证书" : ""
            }}</a>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </article>
</template>
<script>
  import {
    getStudentScoreApplications,
    getTestResults,
    getCertificate,
  } from "../service.js";

  import Cookie from "js-cookie";
  export default {
    data() {
      return {
        loadDialog: false,
        templateType: [],
        messageTable: [],
        loadDialogTable: false,
        userId: Cookie.get("loginId"),
        dialogTable: {},
        dialogInfo: {
          title: "查看成绩",
          show: false,
        },
      };
    },
    created() {
      this.getStudentScoreApplications();
    },
    mounted() {},
    methods: {
      checkItem(row) {
        //查看成绩  this.$t('m.studentResourceCenter'),
        this.dialogInfo.title = "查看成绩";
        let param = {
          student_id: Cookie.get("loginId"),
          project_code: row.application_project,
        };
        this.loadDialog = true;
        getTestResults(param).then((res) => {
          this.loadDialog = false;
          if (res.code == 0) {
            this.dialogTable = res.data;
            //请求获取证书接口
            if (this.dialogTable && this.dialogTable.length > 0) {

              this.dialogTable.forEach((f) => {
                let newParam = {
                  id: f.certificate_id,
                };
                this.loadDialog = true;
                getCertificate(newParam).then((dataRes) => {
                  this.loadDialog = false;
                  if (dataRes.code == 0) {
                    let certificateData = dataRes.data;
                    f.certificate = certificateData.certificate_info.uuid ?
                      "http://view.certifi.tech/#/" +
                      dataRes.data.certificate_info.uuid :
                      "";
                  } else {
                    this.$message.error(dataRes.msg);
                  }
                });
              });
            }
            this.dialogInfo.show = true;
          } else {
            this.$message.error(res.msg);
          }
        });
      },
      getStudentScoreApplications() {
        let param = {
          student_id: this.userId,
        };
        this.loadDialogTable = true;
        getStudentScoreApplications(param).then((res) => {
          this.loadDialogTable = false;
          if (res.code == 0) {
            this.messageTable = res.data;
          } else {
            this.$message.error(res.msg);
          }
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  article {
    margin-top: 50px;
    background: #fff;
    padding: 20px 10px;

    /deep/ .el-form.el-form--inline {
      background-color: #f8f8f8;
      margin-bottom: 10px;
      padding: 8px;
      overflow: hidden;
    }

    /deep/ .el-form.el-form--inline .el-form-item.el-form-item--medium {
      margin-right: 4px;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
</style>