<template>
    <article>
        <el-table style="margin-top:10px" :data="projects" v-loading="dataLoading" header-cell-class-name="adorn" size="medium" border ref="leftTable" class="left-table">
            <el-table-column label="项目名称" align="center" prop="project_name"></el-table-column>
            <el-table-column label="项目代码" align="center" prop="project_code"></el-table-column>
            <!-- <el-table-column label="监测点" align="center">
                <template slot-scope="scope">
                    {{scope.row.station.district+scope.row.point.name}}
                </template>
            </el-table-column> -->
            <el-table-column label="开始时间" align="center">
                <template slot-scope="scope">
                    {{formateDate(scope.row.start_time)}}
                </template>
            </el-table-column>
            <el-table-column label="结束时间" align="center">
                <template slot-scope="scope">
                    {{formateDate(scope.row.end_time)}}
                </template>
            </el-table-column>
            <el-table-column label="课程列表" align="center" width="380">
                <template slot-scope="scope">
                    <div v-for="item in scope.row.classes" :key="item._id.$id" @click="toClass(item)" style="margin:10px auto">
                        <el-tag type="primary" style="cursor:pointer" effect="dark">{{item.class_info.class_name}}</el-tag>
                    </div>
                </template>
            </el-table-column>
        </el-table>
    </article>
</template>

<script>
import {getEducatorProjects} from '../service'
import Cookie from "js-cookie";
export default {
    data(){
        return{
            projects:[],
            dataLoading:false
        }
    },
    mounted(){
        this.getEducatorProjects()
    },
    methods:{
        formateDate(date){
            let time = new Date(date*1000)
            return `${time.getFullYear()}-${time.getMonth()+1}-${time.getDate()} ${time.getHours()}:${time.getMinutes()>9?time.getMinutes():'0'+time.getMinutes()}`;
        },
        getEducatorProjects(){
            this.dataLoading=true
            getEducatorProjects(Cookie.get("loginId")).then(res=>{
                this.dataLoading = false
                if(res.data.code===0){
                    this.projects = res.data.data
                }
            })
        },
        toClass(row){
            this.$router.push({
                path:'/sessionList',
                query:{
                    class_id:row._id.$id
                }
            })
        }
    }
}
</script>

<style>

</style>