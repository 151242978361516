<template>
  <article>
    <p class="schoolP">
      <span>{{$t('m.personInfo.schoolName')}}</span><span>{{ basicForm.account_name }}</span>
    </p>
    <p class="noticeP">
      {{$t('m.personInfo.tips4')}}
    </p>
    <el-tabs
      class="elTabs"
      type="card"
      v-model="activeName"
      @tab-click="handleClick"
    >
      <el-tab-pane :label="$t('m.personInfo.personalInformation')" name="first">
        <el-form v-loading="dataLoading"
          class="block-form"
          :model="basicForm"
          ref="basicForm"
          status-icon
          :label-width="formLabelWidth"
          size="medium"
        >
          <el-row :gutter="10">
            <p class="partP">{{$t('m.personInfo.base')}}</p>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item :label="$t('m.personInfo.xing')" prop="student_lastName">
                <p class="formP">{{ basicForm.student_lastName }}</p>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('m.personInfo.ming')" prop="student_givenName">
                <p class="formP">{{ basicForm.student_givenName }}</p>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('m.personInfo.xingpinyin')" prop="student_lastName_pinyin">
                <p class="formP">{{ basicForm.student_lastName_pinyin }}</p>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('m.personInfo.mingpinyin')" prop="student_givenName_pinyin">
                <p class="formP">{{ basicForm.student_givenName_pinyin }}</p>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('m.personInfo.sex')" prop="gender">
                <el-radio-group v-model="basicForm.student_gender">
                  <el-radio label="女">{{$t('m.personInfo.girl')}}</el-radio>
                  <el-radio label="男">{{$t('m.personInfo.boy')}}</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('m.personInfo.grade')" prop="student_graduation">
                <em class="noticeTxt">{{$t('m.personInfo.tips1')}}</em>
                <el-select
                  v-model="basicForm.student_graduation"
                  :placeholder="$t('m.pleaseChoose')"
                >
                  <el-option
                    v-for="item in graduationOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <p class="partP">{{$t('m.personInfo.contactInformation')}}</p>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item :label="$t('m.personInfo.tel')" prop="student_mobile">
                <p class="formP">{{ basicForm.student_mobile }}</p>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('m.personInfo.email')" prop="student_email">
                <p class="formP">{{ basicForm.student_email }}</p>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="QQ：" prop="student_qqnumber">
                <p class="formP">{{ basicForm.student_qqnumber }}</p>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('m.personInfo.weChat')" prop="student_wechat">
                <p class="formP">{{ basicForm.student_wechat }}</p>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('m.personInfo.identityID')" prop="student_identity_id">
                <em class="noticeTxt1">{{$t('m.personInfo.tips2')}}</em>
                <p class="formP">{{ basicForm.student_identity_id }}</p>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('m.personInfo.birthDate')" prop="student_birth_date">
                <em class="noticeTxt2">{{$t('m.personInfo.tips3')}}</em>
                <p class="formP">{{ basicForm.student_birth_date }}</p>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <p class="partP">{{$t('m.personInfo.parentContactInformation')}}</p>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item
                :label="$t('m.personInfo.firstGuardianTel')"
                prop="student_guardian_first_mobile"
              >
                <p class="formP">
                  {{ basicForm.student_guardian_first_mobile }}
                </p>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
              :label="$t('m.personInfo.firstGuardianEmail')"
                prop="student_guardian_first_mobile"
              >
                <p class="formP">
                  {{ basicForm.student_guardian_first_email }}
                </p>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
              :label="$t('m.personInfo.secondGuardianTel')"
                prop="student_guardian_second_mobile"
              >
                <p class="formP">
                  {{ basicForm.student_guardian_second_mobile }}
                </p>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                :label="$t('m.personInfo.secondGuardianEmail')"
                prop="student_guardian_second_email"
              >
                <p class="formP">
                  {{ basicForm.student_guardian_second_email }}
                </p>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="24">
              <el-form-item  :label="$t('m.personInfo.supplementaryNotes')" prop="student_supplement">
                <p class="formP2">{{ basicForm.student_supplement }}</p>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-tab-pane>
      <!-- <el-tab-pane :label="$t('m.personInfo.registrationInformation')" name="second">
        <el-table
          :data="signUpTable"
          header-cell-class-name="adorn"
          size="small"
          border
          ref="leftTable"
          class="left-table"
        >
          <el-table-column
            label="项目"
            align="center"
            prop="application_project"
          >
          </el-table-column>
          <el-table-column
            label="提交渠道"
            align="center"
            prop="application_channel"
          >
          </el-table-column>
          <el-table-column
            label="报名子项目"
            align="center"
            prop="application_project_BU"
          >
          </el-table-column>
          <el-table-column
            label="状态"
            align="center"
            prop="application_status"
          >
          </el-table-column>

          <el-table-column width="100" label="操作" align="center">
            <template slot-scope="scope">
              <el-button
                class="btn-link"
                @click="editItem(scope.row)"
                type="primary"
                size="small"
                >修改子项目</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane> -->
    </el-tabs>
    <el-dialog
      class="dialog"
      :title="dialogInfo.title"
      :visible.sync="dialogInfo.show"
      :close-on-click-modal="false"
      :show-close="false"
      width="60%"
      @open="openDialog"
      @close="closeDialog"
    >
      <el-form
        class="block-form"
        :model="dialogForm"
        ref="dialogForm"
        :rules="rules"
        status-icon
        label-width="280px"
        size="medium"
      >
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item :label="$t('m.personInfo.registrationProject')" prop="project_name">
              <span class="project_name">{{ project_name }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item  :label="$t('m.personInfo.competitionSubject')" prop="application_events">
              <el-select
                multiple
                v-model="dialogForm.application_events"
                :placeholder="$t('m.pleaseChoose')"
              >
                <el-option
                  v-for="item in project_events"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="$t('m.personInfo.competitionSubjectExtra')"
              prop="application_extra_events"
            >
              <el-select
                multiple
                v-model="dialogForm.application_extra_events"
                :placeholder="$t('m.pleaseChoose')"
              >
                <el-option
                  v-for="item in project_extra_events"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button
          :loading="loadDialog"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(255, 255, 255, 0.2)"
          type="primary"
          @click="submitTeam('dialogForm')"
          >报名</el-button
        >
      </div>
    </el-dialog>
  </article>
</template>
<script>
import {
  getStudentInfo,
  changeEvents,
  getAccountContacts,
  updateApplicationEvents,
  getEventProjects,
} from "../service.js";

import Cookie from "js-cookie";
export default {

  data() {
    return {
      signUpTable: [],
      submitTypeOptions: [
        {
          label: "学校",
          value: "0",
        },
      ],
      formLabelWidth: "180px",
      loadDialog: false,
      dataLoading:false,
      activeName: "first",
      basicForm: {
        name: "carey",
      },
      graduationOptions: [
          {
          label: "2023年",
          value: "2023",
        },
        {
          label: "2022年",
          value: "2022",
        },
        {
          label: "2021年",
          value: "2021",
        },
        {
          label: "2020年",
          value: "2020",
        },
        {
          label: "2019年",
          value: "2019",
        },
        {
          label: "2018年",
          value: "2018",
        },
      ],
      dialogForm: {},
      teachTable: [],
      paginationLoading: false,
      dataLoading: false,
      rules: {
        contact_lastName: [
          { required: true, message: "姓不能为空", trigger: "change" },
        ],
        contact_givenName: [
          { required: true, message: "名不能为空", trigger: "change" },
        ],
        contact_enName: [
          { required: true, message: "英文名不能为空", trigger: "change" },
        ],
        contact_department: [
          { required: true, message: "部门不能为空", trigger: "change" },
        ],
        contact_position: [
          { required: true, message: "职位不能为空", trigger: "change" },
        ],
        contact_prefix: [
          { required: true, message: "请选择性别", trigger: "change" },
        ],
        contact_email: [
          { required: true, message: "邮箱不能为空", trigger: "change" },
        ],
        contact_discipline: [
          { required: true, message: "学科不能为空", trigger: "change" },
        ],
        contact_work_status: [
          { required: true, message: "请选择是否在职", trigger: "change" },
        ],
      },
      isShowDia: false,
      dialogInfo: {
        title: "新增教师信息",
        show: false,
        isNew: true,
      },
      teacherId: "",
      project_events: [],
      project_name: "",
      project_extra_events: [],
    };
  },
  created() {
    this.getStudentInfo(); //获取学生信息
  },
  mounted() {},
  methods: {
    getStudentInfo() {
      this.dataLoading=true;
      let param = {
        student_id: Cookie.get("loginId"),
      };
      getStudentInfo(param).then((res) => {
        this.dataLoading=false;
        if (res.code == 0) {
          //登录成功
          this.basicForm = res.data.student_info;
          this.signUpTable = res.data.app_info;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    editItem(row) {
      this.dialogForm.id = row._id.$id;
      getEventProjects({ id: row._id.$id }).then((res) => {
        if (res.code == 0) {
          console.log(res.data);
          this.project_events = res.data[0].project_events;
          this.project_name = res.data[0].project_name;
          this.project_extra_events = res.data[0].project_extra_events;
        } else {
          this.$message.error(res.msg);
        }
      });
      this.dialogInfo.title = this.$t('m.personInfo.modifySubproject');
      this.dialogInfo.show = true;
    },
    closeDialog() {
      //关闭弹框
      this.dialogInfo.show = false;
       this.getStudentInfo(); //获取学生信息
      this.$nextTick(() => {
        if (this.$refs.dialogForm) {
          this.$refs.dialogForm.clearValidate();
        }
      });
      // this.getAccountStudents();
    },
    handleClick() {},

    openDialog() {
      //打开弹框
      this.$nextTick(() => {
        if (this.$refs.dialogForm) {
          this.$refs.dialogForm.clearValidate();
        }
      });
    },
    submitTeam(dialogForm) {
      let _this = this;
      this.$refs[dialogForm].validate((valid) => {
        if (valid) {
          this.loadDialog = true;
          changeEvents(_this.dialogForm).then((res) => {
            this.loadDialog = false;
            if (res.code == 0) {
              this.$message.success("修改成功！");
              this.closeDialog();
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
    addTeacher() {
      this.dialogInfo.show = true;
      this.dialogInfo.isNew = true;
      this.dialogInfo.title = this.dialogInfo.isNew
        ? "新增教师信息"
        : "编辑教师信息";
      this.basicForm = {
        contact_lastName: "",
        contact_givenName: "",
        contact_enName: "",
        contact_department: "",
        contact_position: "",
        contact_prefix: "",
        contact_email: "",
        contact_discipline: "",
        contact_work_status: "",
      };
    },
    searchHandle() {},
    getAccountContacts() {
      this.dataLoading = true;
      getAccountContacts({ account_id: this.account_id }).then((res) => {
        this.dataLoading = false;
        if (res.code == 0) {
          this.teachTable = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
article {
  margin-top: 50px;
  background: #fff;
  padding: 0 10px;
  .noticeP {
    margin: 0;
    padding: 0;
    height: 30px;
    line-height: 30px;
    color: #f56c6c;
    font-size: 12px;
    text-align: center;
  }
  .schoolP {
    margin: 0;
    padding: 0;
    height: 40px;
    line-height: 40px;
    margin: 0 auto;
    width: 300px;
    font-weight: bold;
  }
  .elTabs {
    margin-top: 10px;
    // border-top:1px solid #e9eef3 ;
  }
}
.formP {
  margin: 0;
  padding: 0;

  padding-left: 10px;
  // border:1px solid #e9eef3;
  background: #f8f9fa;
  border-radius: 4px;
  width: 70%;
  position: relative;
  height: 36px;
  line-height: 36px;
}
.formP2 {
  margin: 0;
  padding: 0;

  padding-left: 10px;
  // border:1px solid #e9eef3;
  background: #f8f9fa;
  border-radius: 4px;
  width: 88%;
  position: relative;
  min-height: 36px;
}
.partP {
  margin: 0;
  padding: 0;
  height: 40px;
  line-height: 40px;
  left: 21px;
  font-size: 16px;
  padding-left: 10px;
  position: relative;
  margin-bottom: 20px;
  &:before {
    content: "";
    display: block;
    position: absolute;
    background: #409eff;
    height: 30px;
    width: 3px;
    left: -6px;
    top: 5px;
  }
}
.noticeTxt {
  color: #f56c6c;
  position: absolute;
  font-size: 8px;
  left: -56px;
  bottom: -25px;
}
.noticeTxt1 {
  color: #f56c6c;
  position: absolute;
  font-size: 8px;
  left: -140px;
  bottom: -15px;
  line-height: 10px;
}
.noticeTxt2 {
  color: #f56c6c;
  position: absolute;
  font-size: 8px;
  left: -148px;
  bottom: -15px;
  line-height: 10px;
}
.project_name {
  font-weight: bold;
}
</style>