import VueRouter from "vue-router";
import Vue from "vue";

import Cookie from "js-cookie";

import Login from "./components/Login.vue";
import forgetPsw from "./components/forgetPsw.vue";
import learningMaterials from "./components/learningMaterials.vue";
import learningMaterialsUSA from "./components/learningMaterialsUSA.vue";
import learningMaterialsUK from "./components/learningMaterialsUK.vue";
import teamInfo from "./components/teamInfo.vue";
import teamDetail from "./components/teamDetail.vue";
import personalInfo from "./components/personalInfo.vue";
import menuList from "./components/menuList.vue";
import messageContent from "./components/messageContent.vue";
import checkMessage from "./components/checkMessage.vue";
import changePassword from "./components/changePassword.vue";
import applicationDetail from "./components/applicationDetail.vue";
import applicationInfo from "./components/applicationInfo.vue";
import schoolInfo from "./components/schoolInfo.vue";
import viewScore from "./components/viewScore.vue";
import studentQestion from "./components/studentQestion.vue";
import sourceList from "./components/sourceList.vue";
import personalInfoEdit from "./components/personalInfoEdit.vue";
import studentList from "./components/studentList.vue";
import schoolApplication from "./components/schoolApplication.vue";
import projectRegistration from "./components/projectRegistration.vue";
import teacherTraining from "./components/teacherTraining.vue";
import importApplications from './components/importApplications.vue'
import Projects from './components/projects.vue'
import educatorProjects from './components/educatorProjects.vue'
import educatorCanProjects from './components/educatorCanProjects.vue'
import packageProjects from './components/packageProjects.vue'
import SessionList from './components/SessionList.vue'
import Home from './components/Home.vue'
import EducatorInfo from './components/EducatorInfo.vue'
import PackageInfo from './components/packageInfo.vue'

import thinkingQuestions from './components/thinkingQuestions.vue'
import thinkingQueList from './components/thinkingQueList.vue'
import thinkingQuDetail from './components/thinkingQuDetail.vue'

import scores from './components/scores.vue'

import currentProjects from './components/currentProjects.vue'
import historyPeojects from './components/historyPeojects.vue'

import studentProjects from './components/studentProjects.vue'

import pay from './components/pay.vue'

import projectPlaza from './components/projectPlaza.vue'
import accountInfo from './components/accountInfo.vue'
import teamCodeList from './components/TeamCodeList.vue'

import Cert from './components/cert.vue'

// import accountForm from './components/form/AccountForm.vue'


Vue.use(VueRouter);

const userAgentInfo = navigator.userAgent;
const Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPod"];
const getArr = Agents.filter((i) => userAgentInfo.includes(i));
const isMobile = !!getArr.length;


const routes = [{
        path: "",
        redirect: "/login",
    },
    // { name: "accountForm", path: "/accountForm", component: accountForm },
    { name: "login", path: "/login", component: Login },
    { name: "cert", path: "/cert", component: Cert },
    { name: "forgetPsw", path: "/forgetPsw", component: forgetPsw },

    {
        path: '/pghome',
        name: 'pghome',
        component: () =>
            import ('./components/pg/PGHome.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/toPrint',
        name: 'toPrint',
        component: () =>
            import ('./components/pg/ToPrint.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/viewAward',
        name: 'viewAward',
        component: () =>
            import ('./components/viewAward.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/pghead',
        name: 'pghead',
        component: () =>
            import ('./components/pg/Head.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/pgquiz',
        name: 'pgquiz',
        component: () =>
            import ('./components/pg/Quiz.vue'),
        meta: {
            requiresAuth: true
        }
    },

    {
        name: "menuList",
        path: "/menuList",
        component: menuList,
        meta: {
            requiresAuth: true,
        },
        children: [{
                name: "studentQestion",
                path: "/studentQestion",
                component: studentQestion,
                meta: {
                    title: "学生答疑",
                    engTitle: "Student Questions",
                    requiresAuth: true,
                },
            },
            {
                name: "sourceList",
                path: "/sourceList",
                component: sourceList,
                meta: {
                    title: "物料列表",
                    engTitle: "Package Delivery",
                    requiresAuth: true,
                },
            },
            {
                name: "studentList",
                path: "/studentList",
                component: studentList,
                meta: {
                    title: "学生列表",
                    engTitle: "Student List",
                    requiresAuth: true,
                },
            },
            {
                name: "schoolApplication",
                path: "/schoolApplication",
                component: schoolApplication,
                meta: {
                    title: "学校报名",
                    engTitle: "School Application",
                    requiresAuth: true,
                },
            },
            {
                name: "learningMaterials",
                path: "/learningMaterials",
                component: learningMaterials,
                meta: {
                    title: "学习材料",
                    engTitle: "Learning Materials",
                    requiresAuth: true,
                },
            },
            {
                name: "learningMaterialsUSA",
                path: "/learningMaterialsUSA",
                component: learningMaterialsUSA,
                meta: {
                    title: "参考资料",
                    engTitle: "Reference material",
                    requiresAuth: true,
                },
            },
            {
                name: "learningMaterialsUK",
                path: "/learningMaterialsUK",
                component: learningMaterialsUK,
                meta: {
                    title: "英国方向",
                    engTitle: "UK Direction",
                    requiresAuth: true,
                },
            },
            {
                name: "personalInfo",
                path: "/personalInfo",
                meta: {
                    title: "个人信息",
                    engTitle: "Personal Info",
                    requiresAuth: true,
                },
                component: personalInfo,
            },
            {
                name: "personalInfoEdit",
                path: "/personalInfoEdit",
                meta: {
                    title: "个人信息",
                    engTitle: "Personal Info",
                    requiresAuth: true,
                },
                component: personalInfoEdit,
            },
            {
                name: "teamInfo",
                path: "/teamInfo",
                meta: {
                    title: "报名信息",
                    engTitle: "Team Info",
                    requiresAuth: true,
                },
                component: teamInfo,
            },
            {
                name: "teamDetail",
                path: "/teamDetail",
                meta: {
                    title: "报名详情",
                    requiresAuth: true,
                },
                component: teamDetail,
            },
            {
                name: "teacherTraining",
                path: "/teacherTraining",
                meta: {
                    title: "教师培训",
                    engTitle: "Teacher Training",
                    requiresAuth: true,
                },
                component: teacherTraining,
            },

            {
                name: "currentProjects",
                path: "/currentProjects",
                meta: {
                    title: "currentProjects",
                    engTitle: "currentProjects",
                    requiresAuth: true,
                },
                component: currentProjects,
            },

            {
                name: "historyPeojects",
                path: "/historyPeojects",
                meta: {
                    title: "historyPeojects",
                    engTitle: "historyPeojects",
                    requiresAuth: true,
                },
                component: historyPeojects,
            },

            {
                name: "messageContent",
                path: "/messageContent",
                meta: {
                    // title: "消息内容",
                    title: "新的消息",
                    engTitle: "New Messages",
                    requiresAuth: true,
                },
                component: messageContent,
            },
            {
                name: "checkMessage",
                path: "/checkMessage",
                meta: {
                    title: "查看消息",
                    engTitle: "Look Messages",
                    requiresAuth: true,
                },
                component: checkMessage,
            },
            {
                name: "viewScore",
                path: "/viewScore",
                meta: {
                    title: "查看成绩",
                    engTitle: "View Score",
                    requiresAuth: true,
                },
                component: viewScore,
            },
            {
                name: "changePassword",
                path: "/changePassword",
                meta: {
                    title: "修改密码",
                    engTitle: "Change Passward",
                    requiresAuth: true,
                },
                component: changePassword,
            },


            {
                name: "thinkingQuestions",
                path: "/thinkingQuestions",
                meta: {
                    title: "思考题",
                    engTitle: "Thinking Questions",
                    requiresAuth: true,
                },
                component: thinkingQuestions,
            },
            {
                name: "thinkingQueList",
                path: "/thinkingQueList",
                meta: {
                    title: "思考题",
                    engTitle: "Thinking Questions List",
                    requiresAuth: true,
                },
                component: thinkingQueList,
            },
            {
                name: "thinkingQuDetail",
                path: "/thinkingQuDetail",
                meta: {
                    title: "思考题",
                    engTitle: "Thinking Questions Detail",
                    requiresAuth: true,
                },
                component: thinkingQuDetail,
            },
            {
                name: "applicationInfo",
                path: "/applicationInfo",
                meta: {
                    title: "项目列表",
                    engTitle: "Application Info",
                    requiresAuth: true,
                },
                component: applicationInfo,
            },
            {
                name: "applicationDetail",
                path: "/applicationDetail",
                meta: {
                    title: "报名详情",
                    engTitle: "Application Detail",
                    requiresAuth: true,
                },
                component: applicationDetail,
            },
            {
                name: "schoolInfo",
                path: "/schoolInfo",
                meta: {
                    engTitle: "School Info",
                    title: "学校信息",
                    requiresAuth: true
                },
                component: schoolInfo,
            },
            {
                name: "projectRegistration",
                path: "/projectRegistration",
                meta: {
                    engTitle: "Project Registration",
                    title: "项目报名",
                    requiresAuth: true
                },
                component: projectRegistration,
            },
            {
                name: "importApplications",
                path: "/importApplications",
                meta: {
                    engTitle: "Import Applications",
                    title: "导入项目报名表",
                },
                component: importApplications,
            },
            {
                name: "projects",
                path: "/projects",
                meta: {
                    engTitle: "Projects",
                    title: "可报项目",
                    requiresAuth: true

                },
                component: Projects,
            },
            {
                name: "educatorProjects",
                path: "/educatorProjects",
                meta: {
                    engTitle: "educatorProjects",
                    title: "负责项目",
                    requiresAuth: true

                },
                component: educatorProjects
            },
            {
                name: "educatorCanProjects",
                path: "/educatorCanProjects",
                meta: {
                    engTitle: "educatorCanProjects",
                    title: "可报项目"
                },
                component: educatorCanProjects
            },
            {
                name: "packageProjects",
                path: "/packageProjects",
                meta: {
                    engTitle: "packageProjects",
                    title: "套餐项目"
                },
                component: packageProjects
            },
            {
                name: "SessionList",
                path: "/sessionList",
                meta: {
                    engTitle: "SessionList",
                    title: "课时列表"
                },
                component: SessionList
            },
            {
                name: "Home",
                path: "/home",
                meta: {
                    engTitle: "Home",
                    title: "课程"
                },
                component: Home
            },
            {
                name: "EducatorInfo",
                path: "/educatorinfo",
                meta: {
                    engTitle: "EducatorInfo",
                    title: "个人信息"
                },
                component: EducatorInfo
            },
            {
                name: "packageInfo",
                path: "/packageInfo",
                meta: {
                    engTitle: "PackageInfo",
                    title: "项目详情"
                },
                component: PackageInfo
            },
            {
                name: "scores",
                path: "/scores",
                meta: {
                    engTitle: "scores",
                    title: "项目详情"
                },
                component: scores
            },

            {
                name: "studentProjects",
                path: "/studentProjects",
                meta: {
                    engTitle: "studentProjects",
                    title: "项目详情",
                    requiresAuth: true

                },
                component: studentProjects
            },


            {
                name: "projectPlaza",
                path: "/projectPlaza",
                meta: {
                    engTitle: "projectPlaza",
                    title: "projectPlaza",
                    requiresAuth: true

                },
                component: projectPlaza
            },

            {
                name: "accountInfo",
                path: "/accountInfo",
                meta: {
                    engTitle: "accountInfo",
                    title: "accountInfo",
                    requiresAuth: true

                },
                component: accountInfo
            },


            {
                name: "teamCodeList",
                path: "/teamCodeList",
                meta: {
                    engTitle: "teamCodeList",
                    title: "teamCodeList",
                    requiresAuth: true

                },
                component: teamCodeList
            },


            {
                name: "pay",
                path: "/pay",
                meta: {
                    engTitle: "pay",
                    title: "pay",
                    requiresAuth: true

                },
                component: pay
            },
            {
                path: "/messages",
                name: "messages",
                component: () =>
                    import ("./components/Messages/index.vue"),
                meta: {
                    requiresAuth: true,
                    title: "Messages",
                },
            }


        ]
    }
];

if (isMobile) {
    routes.push({
        path: "/registration",
        name: "registration",
        component: () =>
            import ("./components/Registration/mobile.vue"),
        meta: {
            requiresAuth: false,
            title: "Registration",
        },
    })
} else {

    routes.push({
        path: "/registration",
        name: "registration",
        component: () =>
            import ("./components/Registration/pc.vue"),
        meta: {
            requiresAuth: false,
            title: "Registration",
        },
    })

}

// 3. 创建 router 实例，然后传 `routes` 配置
// 你还可以传别的配置参数, 不过先这么简单着吧。
const router = new VueRouter({
    linkActiveClass: "active",
    routes, // （缩写）相当于 routes: routes
});
router.beforeEach((to, from, next) => {
    //to即将进入的目标路由对象，from当前导航正要离开的路由， next  :  下一步执行的函数钩子

    console.log(123441, to.path)
    if (to.path === "/login" || to.path === "/registration") {
        next();
    } // 如果即将进入登录路由，则直接放行
    else {
        //进入的不是登录路由

        console.log(123441, to.meta.requiresAuth)
        console.log(123441, Cookie.get("loginId"))

        if (to.meta.requiresAuth && !Cookie.get("loginId")) {
            next({
                path: "/login",
            });
        }
        //下一跳路由需要登录验证，并且还未登录，则路由定向到  登录路由
        else {
            next();
        }
    } //如果不需要登录验证，或者已经登录成功，则直接放行
});
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
export default router;