<template>
  <article>
    <el-form
      class="block-form"
      :model="messageForm"
      ref="messageForm"
      status-icon
      :rules="rules"
      :label-width="formLabelWidth"
      size="medium"
    >
      <el-row :gutter="2">
        <el-col :span="12">
          <el-form-item :label="$t('m.personInfo.chooseProject')" prop="project_code">
            <!-- <el-select v-model="messageForm.project_code" :placeholder="$t('m.pleaseChoose')" v-if="loginType=='学生'">
              <el-option
                v-for="item in projectOptions"
                :key="item.application_project"
                :label="item.application_project"
                :value="item.application_project"
              >
              </el-option>
            </el-select> -->
            <el-select v-model="messageForm.type" :placeholder="$t('m.pleaseChoose')">
              <el-option  v-for="item in messageTpyes"  :key="item" :label="item"  :value="item">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="15">
          <el-form-item :label="$t('m.personInfo.messageContent')" prop="content">
            <el-input
              type="textarea"
              :rows="4"
              :placeholder="$t('m.personInfo.messageContentPlaceholder')"
              v-model="messageForm.content"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="24">
          <span class="noticeTxt"
            >{{$t('m.personInfo.tips5')}}</span
          >
        </el-col>
      </el-row>
      <div class="updateBtn">
        <el-button type="primary" @click="sendMessage('messageForm')"
          >{{$t('m.personInfo.submit')}}</el-button
        >
      </div>
    </el-form>
  </article>
</template>
<script>
import { getStudentApplications,getApplicationsProjectCodeByAccount,getAccountApplicationsProjectCode, sendMessage } from "../service.js";

import Cookie from "js-cookie";
export default {
  data() {
    return {
      messageForm: {},
      formLabelWidth: "145px",
      paginationLoading: false,
      account_id: Cookie.get("studentid"),
      dataLoading: false,
      messageTpyes: ['About Academic','About Process','About System Operation','About Score','About Award and Certificate','Others'],
      rules: {
        // project_code: [
        //   { required: true, message: "请选择项目", trigger: "change" },
        // ],
        type:[{ required: true, message: "Please Select Type", trigger: "change" }],
        content: [{ required: true, message: "Please In Content", trigger: "change" }],
      },
      isShowDia: false,
      projectOptions: [],
      loginType: Cookie.get("loginType"),
      
    };
  },
  created() {
    if(this.loginType=='学生'){
        // this.getStudentApplications();
    }else if(this.loginType=='教师'){
        this.getAccountApplicationsProjectCode();
    }else{
      this.getApplicationsProjectCodeByAccount()
    }
   
  },
  mounted() {},
  methods: {
    getStudentApplications() {
      let param = {
        student_id: Cookie.get("loginId"),
      };
      getStudentApplications(param).then((res) => {
        this.loadDialog = false;
        if (res.code == 0) {
          this.projectOptions = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getApplicationsProjectCodeByAccount() {
      let param = {
        account_id: Cookie.get("loginId"),
      };
      getApplicationsProjectCodeByAccount(param).then((res) => {
        this.loadDialog = false;
        if (res.code == 0) {
          this.projectOptions = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getAccountApplicationsProjectCode() {
      let param = {
        contact_id: Cookie.get("loginId"),
      };
      getAccountApplicationsProjectCode(param).then((res) => {
        this.loadDialog = false;
        if (res.code == 0) {
          this.projectOptions = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    sendMessage(messageForm) {
      let _this = this;
      this.$refs[messageForm].validate((valid) => {
        if (valid) {
          let param = {
            userId: Cookie.get("loginId"),
            type: this.messageForm.type,
            content: this.messageForm.content,
            project: Cookie.get("loginProject"),
          };
          sendMessage(param).then((res) => {
            this.loadDialog = false;
            if (res.code == 0) {
              this.$message.success("发送消息成功，我们将尽快给你回复。");
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
article {
  margin-top: 50px;
  background: #fff;
  padding: 20px;
  box-sizing: border-box;
  /deep/ .el-form.el-form--inline {
    background-color: #f8f8f8;
    margin-bottom: 10px;
    padding: 8px;
    overflow: hidden;
  }
  /deep/ .el-form.el-form--inline .el-form-item.el-form-item--medium {
    margin-right: 4px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
.pull-right {
  float: right;
}
.updateBtn {
  width: 150px;
  margin-left: 100px;
  margin-top: 40px;
}
.noticeTxt {
  color: #f56c6c;
  font-size: 12px;
  text-align: center;
  position: relative;
  left: 145px;
}
</style>