<template>
  <article>
    <el-tabs class="elTabs" type="card" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="个人报名信息" name="first">
        <el-form v-loading="loadDialog" class="block-form" :model="teamForm" ref="teamForm" status-icon
          :label-width="formLabelWidth" size="medium">
          <el-row :gutter="10">
            <p class="partP">报名信息</p>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item label="报名项目：" prop="application_project">
                <p class="formP">{{ teamForm.application_project }}</p>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="报名状态：" prop="application_status">
                <p class="formP">{{ teamForm.application_status }}</p>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="报名渠道：" prop="application_channel">
                <p class="formP">{{ teamForm.application_channel }}</p>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="测试点：" prop="application_channel">
                <p class="formP">{{ teamForm.station.di_city+teamForm.station.name }}</p>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <p class="partP">缴费信息</p>
          </el-row>
          <el-row :gutter="10" v-if="teamDetailInfo.fund_status == 'YES'">
            <el-col :span="12">
              <el-form-item label="缴费渠道：" prop="application_payment_channel">
                <p class="formP">{{ teamForm.application_payment_channel }}</p>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="缴费方式：" prop="application_payment_group">
                <p class="formP">{{ teamForm.application_payment_group }}</p>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="缴费日期：" prop="application_payment_date">
                <p class="formP">{{ teamForm.application_payment_date }}</p>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="缴费金额：" prop="application_payment_amount">
                <p class="formP">{{ teamForm.application_payment_amount }}</p>
              </el-form-item>
            </el-col>
          </el-row>
          <div v-if="teamDetailInfo.fund_status == 'YES'">
            <el-row :gutter="10" v-if="teamDetailInfo.fund_button_status == 'YES'">
              <el-col :span="24">
                <el-checkbox v-model="teamDetailInfo.check_status">
                  {{
                    teamDetailInfo.project_info.project_refund_policy
                  }}
                </el-checkbox>
              </el-col>
              <el-col :span="24">
                  <p class="noticeCheck">注：所有报名费均不含支付手续费，6‰（千分之六）的支付宝手续费由报名者自行承担；支付手续费将在支付宝支付页面中由支付宝自动加总。</p>
                <p class="noticeCheck">
                  请使用本人16周岁以上的支付宝账户扫码，一旦扫码后不可使用其他支付宝账户进行付款；支付宝后台确认支付成功并修改报名状态会有延迟，支付完请间隔一段时间后再查看报名状态是否有更新
                </p>
              </el-col>
              <el-col :span="24">
                <div class="payMoney">
                  <el-button size="medium" :disabled="!teamDetailInfo.check_status" @click="getOrder" type="primary">
                    前往缴费</el-button>

                  <!-- <PayPal amount="0.01" currency="USD" :client="credentials" :button-style="buttonStyle"
                    @payment-authorized="paymentAuthorized" @payment-completed="paymentCompleted"
                    @payment-cancelled="paymentCancelled">
                  </PayPal> -->
                  <!-- <PayPal :amount="orderData.amount" :currency="orderData.currency_code" :client="credentials" env="sandbox"
                    :button-style="buttonStyle" @payment-authorized="paymentAuthorized"
                    @payment-completed="paymentCompleted" @payment-cancelled="paymentCancelled">
                  </PayPal> -->
                </div>

              </el-col>
            </el-row>
        
          </div>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="同队成员" name="second" v-if="teamDetailInfo.team_show_status == 'YES'">
        <div class="tableWidth">
          <el-table :data="teamDetailInfo.teammates" header-cell-class-name="adorn" size="small" border ref="leftTable"
            class="left-table">
            <el-table-column label="姓名" align="center" prop="student_name">
            </el-table-column>
            <el-table-column label="报名渠道" align="center" prop="application_channel">
            </el-table-column>
            <el-table-column label="报名状态" align="center" prop="application_status">
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
      <el-tab-pane label="证书下载" name="third">
          <el-row :gutter="10">
            <p class="partP">单次证书下载</p>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="12">
                <el-button type="primary" style="margin-left:10px">单次证书下载</el-button>
            </el-col>
          </el-row>
          <el-row :gutter="10" style="margin-top:20px">
            <p class="partP">综合证书下载</p>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="12">
                <el-button type="primary" style="margin-left:10px">综合证书下载</el-button>
            </el-col>
          </el-row>
      </el-tab-pane>
    </el-tabs>
  </article>
</template>
<script>
  import {
    getTeamInfo,
    getOrder,
    getAccount,
    getPaypal,
    paypalSucceedBack
  } from "../service.js";
  import PayPal from 'vue-paypal-checkout'
  import Cookie from "js-cookie";
  export default {
    data() {
      return {
        credentials: {
          sandbox: 'AW1eciO47ioD5V6efCH_stHBFdhMCvOU6vN2sx7VnqtsE6bpSbx90GyXAprm6-nEFjBoYhOf3gL8NY_E',
          production: 'EL7lfk2rZAezw7UT6Thl57_4_ahdmThoyCSw-iDxhOGNjL68LkXUKNWDykz-L9uMZwzxnCa7KvKKXJ2z'
        },
        orderData: {},

        buttonStyle: {
          label: 'pay',
          size: 'small',
          shape: 'rect',
          color: 'blue'
        },
        activeName: "first",
        loadDialog: false,
        teamForm: {},
        formLabelWidth: "100px",
        studentid: Cookie.get("loginId"),
        teamDetailInfo: {
          check_status: false
        },
        priceTotal: "0",
        rules: {
          account_lastName: [{
            required: true,
            message: "姓不能为空",
            trigger: "change"
          }, ],
          account_givenName: [{
            required: true,
            message: "名不能为空",
            trigger: "change"
          }, ],
          account_enName: [{
            required: true,
            message: "英文名不能为空",
            trigger: "change"
          }, ],
          account_department: [{
            required: true,
            message: "部门不能为空",
            trigger: "change"
          }, ],
          account_position: [{
            required: true,
            message: "职位不能为空",
            trigger: "change"
          }, ],
          account_prefix: [{
            required: true,
            message: "请选择性别",
            trigger: "change"
          }, ],
          account_email: [{
            required: true,
            message: "邮箱不能为空",
            trigger: "change"
          }, ],
          account_discipline: [{
            required: true,
            message: "学科不能为空",
            trigger: "change"
          }, ],
          account_work_status: [{
            required: true,
            message: "请选择是否在职",
            trigger: "change"
          }, ],
        },
        isShowDia: false,

      };
    },
    created() {
      this.getTeamInfo();

    },
    components: {
      PayPal
    },
    mounted() {},
    methods: {
      handleClick() {},
      paymentAuthorized(data) {
        // 授权完成的回调，可以拿到订单id
        console.log('授权完成', data);
      },

      paymentCompleted(data) {
        // 用户支付完成的回调，可以拿到订单id
        console.log('支付完成', data);
        this.paypalSucceedBack(data);
      },

      paymentCancelled(data) {
        // 用户取消交易的回调
        console.log('取消交易', data);
      },


      getPaypal() {
        //前往缴费
        let param = {
          application_id: this.teamForm._id.$id,

        };
        getPaypal(param).then((res) => {

          this.orderData = res.data;
          this.credentials.sandbox = res.data.client_id;
          this.credentials.production = res.data.live_client_id;
          console.log(this.credentials)
          console.log(this.orderData)
        });
      },
      getOrder() {
        //前往缴费
        let param = {
          application_id: this.teamForm._id.$id,
          content: this.teamDetailInfo.project_info.project_refund_policy,
          check_status: this.teamDetailInfo.check_status,
          project_code: this.$route.query.project_code,
        };
        console.log(param)
        getOrder(param).then((res) => {
            const div = document.createElement('div');
            div.innerHTML = res; // html code
            document.body.appendChild(div);
            document.forms.alipaysubmit.submit();
        });
      },
      getTeamInfo() {
        this.loadDialog = true;
        let param = {
          student_id: this.studentid,
          project_code: this.$route.query.project_code,
        };
        getTeamInfo(param).then((res) => {
          this.loadDialog = false;
          if (res.code == 0) {
            this.teamForm = res.data.app_info;
            // this.getPaypal();
            this.teamDetailInfo = res.data;
            if (this.teamDetailInfo.poject_info) {
              if (this.teamDetailInfo.poject_info.project_price) {
                this.priceTotal =
                  this.teamDetailInfo.poject_info.project_price / 100;
              }
            }
          } else {
            this.$message.error(res.msg);
          }
        });
      },
      paypalSucceedBack(data){
        paypalSucceedBack(this.teamForm._id.$id,data).then(res=>{
          if(res.data.code===0){
            this.$message({
              message:'付款成功！',
              type:'success'
            });
          }
        })
      }
    },
  };
</script>
<style lang="scss" scoped>
  article {
    margin-top: 50px;
    background: #fff;
    padding: 10px;

    /deep/ .el-form.el-form--inline {
      background-color: #f8f8f8;
      margin-bottom: 10px;
      padding: 8px;
      overflow: hidden;
    }

    /deep/ .el-form.el-form--inline .el-form-item.el-form-item--medium {
      margin-right: 4px;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

  .formP {
    margin: 0;
    padding: 0;

    padding-left: 10px;
    // border:1px solid #e9eef3;
    background: #f8f9fa;
    border-radius: 4px;
    width: 70%;
    position: relative;
  }

  .noticeCheck {
    font-size: 12px;
    color: #F56C6C;
    line-height: 15px;
    margin-top: 5px;
  }

  .tableWidth {
    padding: 20px;
  }

  .goMoney {
    height: 36px;
    line-height: 36px;
    font-size: 13px;
  }

  .payMoney {
    width: 150px;
    text-align: center;
    margin: 10px auto;
  }

  .partP {
    margin: 0;
    padding: 0;
    height: 40px;
    line-height: 40px;
    left: 21px;
    font-size: 16px;
    padding-left: 10px;
    position: relative;
    margin-bottom: 20px;

    &:before {
      content: '';
      display: block;
      position: absolute;
      background: #409EFF;
      height: 30px;
      width: 3px;
      left: -6px;
      top: 5px;

    }
  }

  .el-checkbox,
  .el-checkbox__input {
    white-space: normal;
    word-break: break-all;
    display: flex;
  }
</style>