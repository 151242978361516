<template>
    <router-view></router-view>
</template>

<script>


export default {
  name: 'app'
}
</script>

<style lang="scss">

body {
  font-family: "GravityBookItalic-zpra";
}
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  font-family: "GravityBookItalic-zpra";
}
.el-container {
  min-height:calc(100vh - 60px) ;
}
.el-table thead {
    color: #909399;
    font-weight: 500;
}
.app-nav::-webkit-scrollbar {
  display: none;
  /* Chrome Safari */
}

::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 8px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 8px;
}

::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 8px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #eaeaea;
}

::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  background: #fff;
}
.el-table{
  .adorn{
    background-color: #f8f8f8;
    text-align: center;
    color:#333;
    font-weight: normal;
    // border-bottom: 2px solid #ebeef5!important;
  }
  &.el-table--medium{
    td,th{
      border-bottom:1px solid #ddd;
      padding: 8px 0;
    }
  }
  .caret-wrapper{
    height: 23px!important;
    .sort-caret{
      &.ascending{
        top: 0;
      }
      &.descending{
        bottom: 1px;
      }
    }
  }
}
.el-table td, .el-table th.is-leaf {
    border-bottom: 1px solid #ebeef5;
}
a{ 
text-decoration:none; 
color:#409EFF;
}
p{
  padding: 0;
  margin: 0;
}

body .el-table th.gutter{
    display: table-cell!important;
}
 
body .el-table colgroup.gutter{
    display: table-cell!important;
}
</style>
