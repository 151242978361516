<template>
  <article>

    <el-tabs class="elTabs" type="card" v-model="activeName">
      <el-tab-pane :label="$t('m.personInfo.registrationInformation')" name="first">
        <el-form v-loading="loadDialog" class="block-form" ref="teamForm" status-icon
          :label-width="formLabelWidth" size="medium">
          <el-row :gutter="10">
            <p class="partP">{{ $t('m.personInfo.personalInformation') }}</p>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item label-width="150px" :label="$t('m.personInfo.registrationProject1')" prop="application_project">
                <p class="formP">{{ teamForm.project_info.project_name }}</p>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label-width="150px" :label="$t('m.personInfo.registrationStatus')" prop="application_status_show">
                <p class="formP">{{ teamForm.application_status_show }}</p>
              </el-form-item>
            </el-col>

            <!-- <el-col :span="12">   
              <el-form-item v-if="teamForm.application_status != '已取消'" label="取消报名：" prop="application_status">
                  <el-button size="medium" @click="cancelApplication" type="primary">申请取消报名</el-button>
              </el-form-item>
            </el-col> -->
          
          </el-row>
          <el-row :gutter="10" v-if="teamForm.application_status == '已缴费'">
            <p class="partP">{{ $t('m.jiaofeixinxi') }}</p>
          </el-row>
          <el-row :gutter="10" v-if="teamForm.application_status == '已缴费'">
            <el-col :span="12">
              <el-form-item label-width="150px" :label="$t('m.jiaofeixudao')" prop="application_payment_channel">
                <p class="formP">{{ teamForm.application_payment_channel }}</p>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label-width="150px" :label="$t('m.jiaofeifangshi')" prop="application_payment_group">
                <p class="formP">{{ teamForm.application_payment_group }}</p>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label-width="150px" :label="$t('m.jiaofeiriqi')" prop="application_payment_date">
                <p class="formP">{{ teamForm.application_payment_date }}</p>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label-width="150px" :label="$t('m.jiaofeijine')" prop="application_payment_amount">
                <p class="formP">{{ teamForm.application_payment_amount }}</p>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label-width="150px" :label="$t('m.jiaofeitongzhi')"  >
                <p class="formP">{{ formatDateTime(teamForm.refund_policy_check.create_time) }}</p>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label-width="150px" :label="$t('m.dengluyouxiang')" >
                <p class="formP">{{ teamForm.student_email }}</p>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10" v-if="teamForm.application_status == '已缴费'">
            <p class="partP">{{ $t('m.tongduichengyuan') }}</p>
          </el-row>
           <el-row :gutter="10" v-if="teamForm.application_status == '已缴费'">
            <el-col style="padding-left:40px;padding-right:40px">
              <el-table :data="teammates" border empty-text="No Data">

                
                  <el-table-column :label="$t('m.studentInfo.school')" align="center">
                      <template slot-scope="scope">
                        <div>
                          <div>
                            {{scope.row.account.account_name}}
                            <!-- {{scope.row.account.account_name}}  {{scope.row.account.account_type}} -->

                          </div>
                          <div v-if="scope.row.coordinator_id">
                            {{scope.row.coordinator_account_name}} 
                            <!-- {{scope.row.coordinator_account_name}}  {{scope.row.coordinator_account_type}} -->
                          </div>
                        </div>
                      </template>
                  </el-table-column>
                  <el-table-column :label="$t('m.studentInfo.student')" align="center">
                      <template slot-scope="scope">
                          {{scope.row.student.student_lastName+scope.row.student.student_givenName}}
                      </template>
                  </el-table-column>
                  <el-table-column :label="$t('m.personInfo.contactInformation')" align="center">
                      <template slot-scope="scope">
                          {{scope.row.student.student_mobile}}
                      </template>
                  </el-table-column>
                  <el-table-column :label="$t('m.studentInfo.studentEmail')" align="center">
                      <template slot-scope="scope">
                          {{scope.row.student.student_email}}
                      </template>
                  </el-table-column>
              </el-table>
            </el-col>
           </el-row>
         
          <div v-if="teamForm.application_status == '待缴费'">
            <el-row :gutter="10">
              <el-col :span="24">
                <el-checkbox v-model="check_status">
                  {{
                    teamForm.project_info.project_refund_policy
                  }}
                </el-checkbox>
              </el-col>
              <el-col :span="24">
                <p class="noticeCheck">
                  {{ $t('m.payShow') }}
                </p>
              </el-col>
              <el-col :span="24">
                <div class="payMoney">
                  <el-button size="medium" :disabled="!check_status" @click="getOrder" type="primary">{{ $t('m.qianwangjiaofei') }}</el-button>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-form>

        <div class="homeBtn" @click="goHome">Manage My Project</div>

        <!-- <el-row :gutter="10" v-if="teamForm.application_status == '已缴费'" style="margin-top:20px">
            <p class="partP">可选项目</p>
        </el-row>
        <div style="padding-left:20px;padding-right:20px" v-if="teamForm.application_status == '已缴费'">
            <h3>剩余可报名次数:{{rest_count}}</h3>
            <el-table height="600" :data="projects" style="margin-top:10px" border>
                <el-table-column label="项目名称" align="center" prop="project_name"></el-table-column>
                <el-table-column label="项目代码" align="center" prop="project_code"></el-table-column>
                <el-table-column label="额度" align="center" prop="remain_count" width="100"></el-table-column>
                <el-table-column label="报名情况" align="center">
                    <template slot-scope="scope">
                        {{scope.row.application?'已报名':'尚未报名'}}
                    </template>
                </el-table-column>
                <el-table-column width="300" label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button class="btn-link" @click="apply(scope.row)" type="primary" size="small" v-if="!scope.row.application">参加该项目</el-button>
                        <el-button class="btn-link" @click="cancel(scope.row)" type="primary" size="small" v-if="scope.row.application && scope.row.is_over==false">取消参加申请</el-button>
                        <el-button class="btn-link" type="primary" size="small" v-if="scope.row.application" disabled>已参加</el-button>
                        <el-button @click="initUpload(scope.row)" type="warning" size="small">上传承诺书</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div> -->
      </el-tab-pane>
      <el-tab-pane name="second" :label="$t('m.personInfo.lingdui')">
          <el-table height="600" v-loading="loadDialog" :data="appTable" header-cell-class-name="adorn" size="medium" border
            ref="leftTable" class="left-table" empty-text="No Data">
            
            <el-table-column :label="$t('m.xuhao')" align="center" type="index" width="90"></el-table-column>
              <el-table-column :label="$t('m.studentInfo.school')" align="center">
                <template slot-scope="scope">
                  <div>
                    <div>
                      {{scope.row.account.account_nameEN}} 
                      <!-- {{scope.row.account.account_name}}  {{scope.row.account.account_type}} -->

                    </div>
                    <div v-if="scope.row.coordinator_id">
                      {{scope.row.coordinator_account_name}} 
                      <!-- {{scope.row.coordinator_account_name}}  {{scope.row.coordinator_account_type}} -->
                    </div>
                  </div>
                </template>
              </el-table-column>

            <el-table-column :label="$t('m.personInfo.name')" align="center">
                <template slot-scope="scope">
                    {{ scope.row.student.student_lastName_pinyin+scope.row.student.student_givenName_pinyin }}
                </template>
            </el-table-column>
            <el-table-column :label="$t('m.personInfo.email')" align="center">
                <template slot-scope="scope">
                    {{ scope.row.student.student_email }}
                </template>
            </el-table-column>
            
            <!-- <el-table-column label="指导老师" align="center">
                <template slot-scope="scope">
                    {{scope.row.contact.contact_lastName+scope.row.contact.contact_givenName}}
                </template>
            </el-table-column> -->
            <!-- <el-table-column label="报名渠道" align="center" prop="application_channel"></el-table-column> -->
            
            <el-table-column :label="$t('m.personInfo.registrationStatus1')" align="center" prop="application_status_show"></el-table-column>
            <el-table-column :label="$t('m.duiwu')" width="120">
                <template slot-scope="scope">
                    <el-select v-model="scope.row.application_team_code" :disabled="loginType != '教师'" placeholder="Select">
                        <el-option v-for="i in 20" :key="i" :value="i" :label="i"></el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column :label="$t('m.chengyuanleixing')" width="120">
                <template slot-scope="scope">
                    <el-select v-model="scope.row.application_team_role" placeholder="Select">
                        <el-option value="Leader"></el-option>
                        <el-option value="Member"></el-option>
                    </el-select>
                </template>
            </el-table-column>
            </el-table>
            <el-button  @click="saveTeamCode" class="btn-link" type="primary" style="margin-top:20px">{{$t('m.personInfo.submit')}}</el-button>
      </el-tab-pane>
    </el-tabs>
    <el-dialog :visible.sync="showUpload">
        <div v-for="item in attaches" :key="item._id.$id" style="margin-bottom:20px">
            <el-link :href="item.url" type="primary" target="_blank">{{item.name}}</el-link>
            <span style="margin-left:20px;color:#F56C6C;font-size:14px;cursor:pointer" @click="removeCommitAttach(item._id.$id)">删除</span>
        </div>
        <input type="file" ref="file" />
        <el-button type="primary" @click="updateCommitAttach">上传</el-button>
    </el-dialog>

    <el-dialog :visible.sync="cancel_view">
        
        <!--  -->
        <div style="margin-top:30px;">
          <span>请选择取消原因</span>
        </div>

        <el-select v-model="cancel_select" style="margin-top:30px;float:left;display:inline">
            <el-option
              v-for="item in cancel_cause"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        <el-input style="margin-top:20px" type="text" placeholder="请输入申请取消原因" v-model="cancel_info"></el-input>
        <el-button style="margin-top:20px" type="primary" @click="applyCancel">申请</el-button>
    </el-dialog>

    <el-dialog custom-class="myDialog" :visible.sync="showOxPay" :close-on-click-modal="false" :show-close="false"
               width="600px">
      <div style="text-align:center">
        <!--        <div class="aliTitle">Pay Amount<span style="color:#F65D4D;font-weight:900;font-size:24px;margin-left:10px">SGD$200</span>-->
        <!--        </div>-->
        <div style="margin-top:30px;margin-bottom:22px;font-size:16px">Please scan with Alipay</div>
        <vue-qr
            :size="191"
            :margin="0"
            :auto-color="true"
            logoSrc="https://mainbucket.learningfirst.cn/%E6%94%AF%E4%BB%98-%E6%94%AF%E4%BB%98%E5%AE%9D.png"
            :logoScale="2"
            :dot-scale="1"
            :text="payLink"/>
        <div style="margin-top:20px;margin-bottom:50px;font-size:16px">Scan to Pay</div>
      </div>
    </el-dialog>

  </article>
</template>
<script>
import {
  getApplicationsByPackageStudent,
  saveTeamCode,
  getPackageApplication,
  getPackageOrder,
  initApplicationInPackage,
  getStudentCommitAttaches,
  updateCommitAttach,
  removeCommitAttach,
  applyCancelApplication,
  getAccount
} from "../service.js";
  import Cookie from "js-cookie";
  import VueQr from 'vue-qr'

  export default {
    components: {
      VueQr
    },
    data() {
      return {
        orderData: {},
        buttonStyle: {
          label: 'pay',
          size: 'small',
          shape: 'rect',
          color: 'blue'
        },
        showOxPay: false,
        payLink: '',
        payTimer: undefined,
        loginType: Cookie.get("loginType"),
        activeName: "first",
        loadDialog: false,
        teamForm: {project_info:{}},
        formLabelWidth: "130px",
        studentid: Cookie.get("loginId"),
        teamDetailInfo: {
          check_status: false
        },
        priceTotal: "0",
        isShowDia: false,
        package_application_id:this.$route.query.package_application_id,
        check_status:false,
        projects:[],
        rest_count:0,
        teammates:[],
        tempTeammates:[],
        showUpload:false,
        attaches:[],
        project_id:'',
        cancel_view:false,
        cancel_app_id:'',
        cancel_info:'',
        cancel_select:'',
        cancel_cause:['个人原因','时间冲突'],
        appTable:[],
        tempAppTable:[],
        teammatesIndex:-1,
        appTableIndex:-1
      };
    },
    created() {
      this.getTeamInfo();
      this.getApplicationsByPackageStudent()
    },
    mounted() {},
    methods: {
        getOrder() {
            //前往缴费
            getPackageOrder(this.teamForm._id.$id,this.teamForm.project_info.project_refund_policy,this.check_status).then((res) => {
              
              if (res.data && res.data.data.qrUrl) {
                this.payLink = res.data.data.qrUrl
                this.showOxPay = true
                this.startInternalTimer()
              }
            })  
        },
        goHome () {
          if (this.teamForm.application_status !== '已缴费') {
            this.$message.error("You haven't paid yet")
            return
          }
          // mr 模版id  631169b9a4640b60318b4602
          // poe 模版id  63116846a4640b19318b4567
          // pie 模版id  63116951a4640b60318b4567

          // let pathTemplate_id = '631169b9a4640b60318b4602'

          // if (this.application.program==='PIE'){
          //   pathTemplate_id = '63116846a4640b19318b4567'

          // }else if (this.application.program==='POE'){
          //   pathTemplate_id = '63116951a4640b60318b4567'

          // }

          // if (this.from == 'student') {
          if (this.teamForm.coordinator_id && this.teamForm.coordinator_id != ''){
            const routeData = this.$router.resolve({ path: 'pghome', query: { template_id: this.teamForm.template_id, project_id: this.teamForm.project_id, application_team_code: this.teamForm.application_team_code, accountid: this.teamForm.coordinator_id } })
            window.open(routeData.href, '_blank')
          }else{
            const routeData = this.$router.resolve({ path: 'pghome', query: { template_id: this.teamForm.template_id, project_id: this.teamForm.project_id, application_team_code: this.teamForm.application_team_code, accountid: this.teamForm.account_id } })
            window.open(routeData.href, '_blank')

          }
          // } else {
          //   routeData= this.$router.resolve({ path: 'Home', query: { template_id: this.project_template_id,project_id: this.educator_project_id,application_team_code:this.tableData2[index].code, accountid: this.tableData2[index].id }})
          // }
        },


        startInternalTimer() {
          this.payTimer = setInterval(() => {
            getPackageApplication(this.package_application_id).then((res) => {
              if (res.data.code == 0) {
                let result = res.data.data;
                if (result.application_status == '已缴费') {
                  this.teamForm = res.data.data;
                  this.tempTeammates = res.data.data.teammates
                  this.teammates = []
                  this.teammatesIndex = -1
                  this.formatTeammates()
                  this.showOxPay = false;
                  clearInterval(this.payTimer);
                  this.payTimer = null;
                  this.$message.success('Pay Success');
                }
              }
            })
          }, 1 * 1000 * 2);
        },
        getTeamInfo() {
            this.loadDialog = true;
            getPackageApplication(this.package_application_id).then((res) => {
                this.loadDialog = false;
                if (res.data.code == 0) {
                    this.teamForm = res.data.data;
                    this.tempTeammates = res.data.data.teammates
                    this.teammates = []
                    this.teammatesIndex=-1
                    this.formatTeammates()
                } else {
                    this.$message.error(res.data.msg);
                }
            })
        },
      formatTeammates(){
        this.teammatesIndex++
        if(this.teammatesIndex>=this.tempTeammates.length){
          return
        }
        //coordinator_id
        var temp = this.tempTeammates[this.teammatesIndex]
        if(temp.coordinator_id){
          this.getTeammateAccountInfo(temp.coordinator_id)
        }else{
          this.teammates.push(this.tempTeammates[this.teammatesIndex])
          this.formatTeammates()
        }
      },
      getTeammateAccountInfo(account_id) {
        let params = {
          account_id:account_id
        }
        getAccount(params).then((res) => {
          this.tempTeammates[this.teammatesIndex]['coordinator_account_name'] = res.data.account_nameEN
          this.tempTeammates[this.teammatesIndex]['coordinator_account_type'] = res.data.account_type
          this.teammates.push(this.tempTeammates[this.teammatesIndex])
          this.formatTeammates()
        });
      },
        
        getApplicationsByPackageStudent() {
            let param = {
                package_application_id: this.package_application_id
            };
            getApplicationsByPackageStudent(param).then((res) => {
                if (res.data.code == 0) {
                  this.tempAppTable = res.data.data;
                  this.appTableIndex = -1
                  this.appTable = []
                  this.formatAppTable()
                } else {
                    this.$message.error(res.data.msg);
                }
            });
        },
      formatAppTable(){
        this.appTableIndex++
        if(this.appTableIndex>=this.tempAppTable.length){
          return
        }
        //coordinator_id
        var temp = this.tempAppTable[this.appTableIndex]
        if(temp.coordinator_id){
          this.getAppTableAccountInfo(temp.coordinator_id)
        }else{
          this.appTable.push(this.tempAppTable[this.appTableIndex])
          this.formatAppTable()
        }
      },
      getAppTableAccountInfo(account_id) {
        let params = {
          account_id:account_id
        }
        getAccount(params).then((res) => {
          this.tempAppTable[this.appTableIndex]['coordinator_account_name'] = res.data.account_nameEN
          this.tempAppTable[this.appTableIndex]['coordinator_account_type'] = res.data.account_type
          this.appTable.push(this.tempAppTable[this.appTableIndex])
          this.formatAppTable()
        });
      },
      
        saveTeamCode(){
            //   console.log(this.appTable)
            let applications = this.appTable.map(item=>{
                let data = {}
                data.id = item._id.$id
                data.application_team_code = item.application_team_code
                data.application_team_role = item.application_team_role
                return data
            })
            
            saveTeamCode(applications).then(res=>{
                if(res.data.code===0){
                    this.$message({
                        message:"提交成功",
                        type:"success"
                    })
                }else{
                    this.$message.warning(res.data.msg)
                }
            })
        },
        formatDateTime(value) { // 时间戳转换日期格式方法
          if (value == null) {
            return ''
          } else {
            const date = new Date(value *1000)
            const y = date.getFullYear() // 年
            let MM = date.getMonth() + 1 // 月
            MM = MM < 10 ? ('0' + MM) : MM
            let d = date.getDate() // 日
            d = d < 10 ? ('0' + d) : d
            let h = date.getHours() // 时
            h = h < 10 ? ('0' + h) : h
            let m = date.getMinutes()// 分
            m = m < 10 ? ('0' + m) : m
            let s = date.getSeconds()// 秒
            s = s < 10 ? ('0' + s) : s
            return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s
            }
          }
    }
  }
</script>
<style lang="scss" scoped>
  article {
    margin-top: 50px;
    background: #fff;
    padding: 10px;

    /deep/ .el-form.el-form--inline {
      background-color: #f8f8f8;
      margin-bottom: 10px;
      padding: 8px;
      overflow: hidden;
    }

    /deep/ .el-form.el-form--inline .el-form-item.el-form-item--medium {
      margin-right: 4px;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

  .formP {
    margin: 0;
    padding: 0;

    padding-left: 10px;
    // border:1px solid #e9eef3;
    background: #f8f9fa;
    border-radius: 4px;
    width: 70%;
    position: relative;
  }

  .formP1 {
    margin: 0;
    padding: 0;

    padding-left: 10px;
    // border:1px solid #e9eef3;
    background: #f8f9fa;
    border-radius: 4px;
    width: 60%;
    position: relative;
  }

  .noticeCheck {
    font-size: 12px;
    color: #F56C6C;
    line-height: 15px;
    margin-top: 5px;
  }

  .tableWidth {
    padding: 20px;
  }

  .goMoney {
    height: 36px;
    line-height: 36px;
    font-size: 13px;
  }

  .payMoney {
    width: 150px;
    text-align: center;
    margin: 10px auto;
  }

  .partP {
    margin: 0;
    padding: 0;
    height: 40px;
    line-height: 40px;
    left: 21px;
    font-size: 16px;
    padding-left: 10px;
    position: relative;
    margin-bottom: 20px;

    &:before {
      content: '';
      display: block;
      position: absolute;
      background: #409EFF;
      height: 30px;
      width: 3px;
      left: -6px;
      top: 5px;

    }
  }
  .homeBtn{
      background: #0e4890;
      width: 186px;
      height: 34px;
      align-self: center;
      border-radius: 20px;
      line-height: 34px;
      text-align: center;
      color: #fff;
      margin-top: 20px;
      margin-bottom: 30px;
      margin-left: calc((100% - 186px)/2);
      // position: absolute;
      // top: 40px;
      // right: 180px;
      cursor: pointer;
    }

  .btn-link{
    background:#E0A91E;
  }
  /deep/ .el-button {
    border:none;
  }
  .el-checkbox,
  .el-checkbox__input {
    white-space: normal;
    word-break: break-all;
    display: flex;
  }
</style>